import useEvent from './use-event';

/** Executes the provided callback when a click event occurs outside of the provided ref */
const useClickOutside = (ref, callback = () => { }, additionalRefs = []) => {
  useEvent('mousedown', e => {
    if (additionalRefs) {
      let isClickOutside =
        ref.current &&
        e.target !== ref.current &&
        !ref.current.contains(e.target) &&
        e.clientX < document.body.scrollWidth;

      let clickedAdditional = false;
      additionalRefs.forEach(addRef => {
        if (
          addRef.current &&
          e.target !== addRef.current &&
          !addRef.current.contains(e.target) &&
          e.clientX < document.body.scrollWidth
        ) {
          clickedAdditional = clickedAdditional || false;
        } else clickedAdditional = clickedAdditional || true;
      });

      if (isClickOutside && !clickedAdditional) {
        callback();
      }
    } else {
      if (
        ref.current &&
        e.target !== ref.current &&
        !ref.current.contains(e.target) &&
        e.clientX < document.body.scrollWidth
      ) {
        callback();
      }
    }
  });
};

export default useClickOutside;
