import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Image from 'components/image/image';
import Button from 'components/button/button';
import { ReactComponent as PlayIcon } from '../../assets/icons/icon-play-hex.svg';
import videoHelper from 'js/video-helper';

const videoPlayerTypes = {
  youtube: 'youtube',
  vimeo: 'vimeo'
};

const Video = ({
  id,
  className,
  title,
  src,
  thumbnail,
  playButtonText,
  videoType,
  onPlayerLoad,
  isLargeVideo
}) => {
  const [showThumbnail, setShowThumbnail] = useState(
    !!thumbnail && !!playButtonText
  );

  var player = undefined;

  const onPlay = () => {
    setShowThumbnail(false);
    if (videoType === videoPlayerTypes.youtube) videoHelper.playYoutube(player);
    else if (videoType === videoPlayerTypes.vimeo)
      videoHelper.playVimeo(player);
    document.getElementById(id).focus();
  };

  useEffect(() => {
    if (videoType === videoPlayerTypes.youtube) {
      player = videoHelper.setUpYoutube(id);
      onPlayerLoad && onPlayerLoad(player);
      player.on('stateChange', () => {
        setShowThumbnail(false);
        player.off('stateChange');
      });
    } else if (videoType === videoPlayerTypes.vimeo) {
      player = videoHelper.setUpVimeo(id);
      onPlayerLoad && onPlayerLoad(player);
      player.on('seeked', () => {
        setShowThumbnail(false);
        player.off('seeked');
      });
    }
  }, []);

  return (
    <div className={cn('video', { 'video--large': isLargeVideo }, className)}>
      {showThumbnail && (
        <div className="video__thumbnail-wrapper">
          <Image className="video__thumbnail" {...thumbnail} />
          <Button
            className="video__play-button"
            text={playButtonText}
            textIsHidden={true}
            onClick={onPlay}
          >
            <div className="video__play-icon">
              <div className="video__play-icon-arrow" />
            </div>
          </Button>
        </div>
      )}
      <iframe
        hidden={showThumbnail}
        id={id}
        className="video__video"
        width="100%"
        title={title}
        src={src}
        allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>
    </div>
  );
};

Video.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  thumbnail: PropTypes.exact(Image.propTypes),
  playButtonText: PropTypes.string,
  videoType: PropTypes.oneOf(Object.values(videoPlayerTypes)).isRequired,
  isLargeVideo: PropTypes.bool,
  onPlayerLoad: PropTypes.func
};

Video.propTypesMeta = {
  className: 'exclude',
  isLargeVideo: 'exclude'
};

Video.videoPlayerTypes = videoPlayerTypes;

export default Video;
