import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/link';

import ids from 'js/global-ids';

const TabMenu = ({ linkText }) => (
  <Link
    lang="nb"
    className="tab-menu"
    themes={[Link.themes.dark]}
    href={`#${ids.mainContent}`}
    text={linkText}
  />
);

TabMenu.propTypes = {
  linkText: PropTypes.string.isRequired
};

export default TabMenu;
