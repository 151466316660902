import React from "react";
import PropTypes from "prop-types";
import FluidImage from "components/fluid-image/fluid-image";
import Heading from "components/heading/heading";
import ContentContainer from "components/content-container/content-container";
import RichText from "components/rich-text/rich-text";

const MagazineArticleHeader = ({ heading, introAsHtml, image }) => {
  return (
    <div className="magazine-article-header">
      <ContentContainer
        themes={[ContentContainer.themes.article]}
        className="magazine-article-header__content"
      >
        <div className="magazine-article-header__heading-wrapper">
          <div className="magazine-article-header__heading-position-wrapper">
            <Heading className="magazine-article-header__heading" level={1}>
              {heading}
            </Heading>
          </div>
        </div>
        <div className="magazine-article-header__intro-box">
          <RichText
            className="magazine-article-header__intro"
            html={introAsHtml}
          />
        </div>
      </ContentContainer>
      <div className="magazine-article-header__image-wrapper">
        <FluidImage
          className="magazine-article-header__image"
          fixed={true}
          {...image}
        />
      </div>
    </div>
  );
};

MagazineArticleHeader.propTypes = {
  heading: PropTypes.string,
  introAsHtml: PropTypes.string,
  image: PropTypes.exact({
    src: PropTypes.string,
    alt: PropTypes.string,
    focusPoint: PropTypes.exact({
      x: PropTypes.number,
      y: PropTypes.number
    })
  })
};

export default MagazineArticleHeader;
