import React from 'react';
import PropTypes from 'prop-types';
import ComponentList from 'components/component-list/component-list';
import RichText from 'components/rich-text/rich-text';
import MagazineArticleHeader from 'components/magazine-article-header/magazine-article-header';
import Layout from 'components/layout/layout';
import ResourceBlock from 'components/resource-block/resource-block';
import CampaignBlock from 'components/campaign-block/campaign-block';
import ArticleHeader from 'components/article-header/article-header';
import BylineBlock from 'components/byline-block/byline-block';
import ContentContainer from 'components/content-container/content-container';
import CampaignItemGroupBlock from 'components/campaign-item-group-block/campaign-item-group-block';
import EmployeeListBlock from 'components/employee-list-block/employee-list-block';

const Article = ({
  body,
  layout,
  header,
  magazineHeader,
  byline,
  afterContent
}) => {
  return (
    <Layout {...layout}>
      <div className="article">
        {magazineHeader && <MagazineArticleHeader {...magazineHeader} />}
        {header && <ArticleHeader {...header} />}
        {byline && <BylineBlock {...byline} />}
        {body && (
          <div className="article__body">
            <ComponentList
              additionalComponentProps={{
                RichText: { themes: [RichText.themes.article] },
                BylineBlock: { magazineTheme: !!magazineHeader },
                ResourceBlock: {
                  containerTheme: ResourceBlock.containerThemes.article
                },
                CampaignBlock: {
                  containerTheme: CampaignBlock.containerThemes.article
                },
                CampaignItemGroupBlock: {
                  containerTheme: CampaignItemGroupBlock.containerThemes.article
                },
                EmployeeListBlock: {
                  containerTheme: EmployeeListBlock.containerThemes.article
                }
              }}
              {...body}
            />
            <div className="article__after">
              {afterContent && (
                <div className="article__after-content">
                  <ComponentList
                    themes={[ComponentList.themes.margins]}
                    additionalComponentProps={{
                      RichText: { themes: [RichText.themes.article] },
                      ResourceBlock: {
                        containerTheme: ContentContainer.themes.footer
                      },
                      CampaignBlock: {
                        containerTheme: ContentContainer.themes.footer
                      }
                    }}
                    {...afterContent}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

Article.propTypes = {
  header: PropTypes.exact(ArticleHeader.propTypes),
  magazineHeader: PropTypes.exact(MagazineArticleHeader.propTypes),
  byline: PropTypes.exact(BylineBlock.propTypes),
  body: PropTypes.exact(ComponentList.propTypes),
  layout: PropTypes.exact(Layout.propTypes),
  afterContent: PropTypes.exact(ComponentList.propTypes)
};

export default Article;
