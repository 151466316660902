import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Collapse from 'react-tiny-collapse';

import Heading from 'components/heading';
import useToggle from 'hooks/use-toggle';

import { ReactComponent as Chevron } from '../../assets/icons/icon-chevron-left.svg';

const themes = {
  erher: 'accordion--erher'
};

const Accordion = ({
  children,
  isInitiallyOpen,
  headingLevel,
  triggerText,
  theme
}) => {
  const contentRef = useRef();
  const triggerRef = useRef();

  const [isOpen, toggleIsOpen] = useToggle(!!isInitiallyOpen);

  const contentId = triggerText.replace(/\s+/g, '');

  return (
    <div
      className={cn('accordion', theme, {
        'accordion--open': isOpen
      })}
    >
      <Heading
        level={headingLevel}
        className="accordion__title"
        themes={[Heading.themes.noMargins]}
      >
        <button
          className={cn('accordion__trigger', {
            'accordion__trigger--active': isOpen
          })}
          aria-expanded={isOpen}
          aria-controls={contentId}
          onClick={toggleIsOpen}
          ref={triggerRef}
        >
          <span className="accordion__trigger-content">
            {triggerText && (
              <span className="accordion__trigger-text">{triggerText}</span>
            )}
          </span>
          <Chevron className="accordion__icon" />
        </button>
      </Heading>
      <div
        className={cn('accordion__content-wrapper', {
          'accordion__content-wrapper--open': isOpen
        })}
        ref={contentRef}
        tabIndex="-1"
      >
        <Collapse
          id={contentId}
          className="accordion__collapse"
          isOpen={isOpen}
        >
          <div className="accordion__content">{children}</div>
        </Collapse>
      </div>
    </div>
  );
};

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  isInitiallyOpen: PropTypes.bool,
  headingLevel: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  triggerText: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(themes)
};

Accordion.propTypesMeta = 'exclude';

Accordion.themes = themes;

export default Accordion;
