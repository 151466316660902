import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image/image';
import ErherIconFromKey from 'components/erher-icon-from-key/erher-icon-from-key';
import cn from 'classnames';

const ResourceCard = ({
  className,
  image,
  name,
  url,
  targetGroups,
  metaData
}) => {
  return (
    <a className={cn('resource-card', className)} href={url}>
      <div className="resource-card__image-wrapper">
        <Image className="resource-card__image" {...image} />
      </div>
      <div className="resource-card__content">
        <div className="resource-card__text">{name}</div>
        <div className="resource-card__info-wrapper">
          <span className="resource-card__meta-data">
            {metaData &&
              metaData.map((data, i) => (i === 0 ? data : ' | ' + data))}
          </span>
          <span className="resource-card__categories">
            {targetGroups &&
              targetGroups.map(({ key, iconAlt }) => (
                <ErherIconFromKey
                  key={key}
                  className="resource-card__category-icon"
                  iconKey={key}
                  role="img"
                  aria-label={iconAlt}
                />
              ))}
          </span>
        </div>
      </div>
    </a>
  );
};

ResourceCard.propTypes = {
  className: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  name: PropTypes.string,
  url: PropTypes.string,
  id: PropTypes.string,
  targetGroups: PropTypes.arrayOf(
    PropTypes.exact({
      key: PropTypes.oneOf([
        'barn',
        'trinn14',
        'trinn57',
        'trinn810',
        'vgs',
        'voksen'
      ]),
      iconAlt: PropTypes.string
    })
  ),
  metaData: PropTypes.arrayOf(PropTypes.string)
};

ResourceCard.propTypesMeta = {
  className: 'exclude'
};

export default ResourceCard;
