import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image/image';
import Button from 'components/button/button';
import DownloadLinksDropdown from 'components/download-links-dropdown/download-links-dropdown';
import cn from 'classnames';

const TeachingAidItem = ({
  id,
  title,
  titleForOrder,
  image,
  mainInfo,
  otherInfo,
  additionalInformation,
  orderButtonText,
  removeOrderButtonText,
  downloadMenu,
  additionalMaterial,
  additionalOrderButtonText,
  additionalRemoveOrderButtonText,
  addTeachingAid,
  removeTeachingAid,
  selected,
  additionalSelected
}) => {
  const {
    additionalLabel,
    additionalData,
    additionalId,
    additionalTitleForOrder,
    additionalDownloadMenu
  } = additionalMaterial ? additionalMaterial : {};

  return (
    <li
      className={cn('teaching-aid-item', {
        'teaching-aid-item--additional-material': additionalMaterial
      })}
    >
      <div className="teaching-aid-item__content">
        <div className="teaching-aid-item__main-content">
          <div className="teaching-aid-item__info-wrapper">
            <h2 className="teaching-aid-item__heading">{title}</h2>
            <p className="teaching-aid-item__info-list">
              {mainInfo &&
                mainInfo.map(({ label, data }, index) => (
                  <span key={label}>
                    {index > 0 && ' | '}
                    <span className="teaching-aid-item__info-label">
                      {label + ': '}
                    </span>
                    {data}
                  </span>
                ))}
            </p>
            <p className="teaching-aid-item__info-list">
              {otherInfo &&
                otherInfo.map(({ label, data }, index) => (
                  <span key={label}>
                    {index > 0 && ' | '}
                    <span className="teaching-aid-item__info-label">
                      {label + ': '}
                    </span>
                    {data}
                  </span>
                ))}
            </p>
            {additionalInformation && (
              <p className="teaching-aid-item__additional-info">
                {additionalInformation}
              </p>
            )}
          </div>
          <div className="teaching-aid-item__actions">
            {orderButtonText && (
              <Button
                className="teaching-aid-item__order-button"
                themes={[Button.themes.red]}
                onClick={() =>
                  selected
                    ? removeTeachingAid(id)
                    : addTeachingAid(id, titleForOrder)
                }
              >
                {selected ? removeOrderButtonText : orderButtonText}
              </Button>
            )}

            {downloadMenu && (
              <span className="teaching-aid-item__download-button">
                <DownloadLinksDropdown {...downloadMenu} />
              </span>
            )}
          </div>
        </div>
        {additionalMaterial && (
          <div className="teaching-aid-item__alternative-actions">
            <span className="teaching-aid-item__info-label">
              {additionalLabel + ': '}
            </span>
            {additionalData}
            {additionalId && additionalTitleForOrder && (
              <Button
                className="teaching-aid-item__alternative-order-button"
                themes={[Button.themes.red]}
                onClick={() =>
                  additionalSelected
                    ? removeTeachingAid(additionalId)
                    : addTeachingAid(additionalId, additionalTitleForOrder)
                }
              >
                {additionalSelected
                  ? additionalRemoveOrderButtonText
                  : additionalOrderButtonText}
              </Button>
            )}
            {additionalDownloadMenu && (
              <span className="teaching-aid-item__alternative-order-button">
                <DownloadLinksDropdown {...additionalDownloadMenu} />
              </span>
            )}
          </div>
        )}
      </div>

      {image && <Image className="teaching-aid-item__image" {...image} />}
    </li>
  );
};

TeachingAidItem.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  titleForOrder: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  mainInfo: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      data: PropTypes.string
    })
  ),
  otherInfo: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      data: PropTypes.string
    })
  ),
  additionalInformation: PropTypes.string,
  orderButtonText: PropTypes.string,
  removeOrderButtonText: PropTypes.string,
  additionalMaterial: PropTypes.exact({
    additionalLabel: PropTypes.string,
    additionalData: PropTypes.string,
    additionalId: PropTypes.string,
    additionalTitleForOrder: PropTypes.string,
    additionalDownloadMenu: PropTypes.exact(DownloadLinksDropdown.propTypes)
  }),
  additionalOrderButtonText: PropTypes.string,
  additionalRemoveOrderButtonText: PropTypes.string,
  downloadMenu: PropTypes.exact(DownloadLinksDropdown.propTypes),
  selected: PropTypes.bool,
  additionalSelected: PropTypes.bool,
  addTeachingAid: PropTypes.func,
  removeTeachingAid: PropTypes.func
};

TeachingAidItem.propTypesMeta = {
  selected: 'exclude',
  additionalSelected: 'exclude'
};

export default TeachingAidItem;
