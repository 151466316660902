import React from "react";
import PropTypes from "prop-types";
import Heading from "components/heading/heading";
import Image from "components/image/image";
import Link from "components/link/link";
import ContentContainer from "components/content-container/content-container";
import cn from "classnames";

const themes = {
  beige: "beige",
  beigelight: "beigelight",
  beigelighter: "beigelighter"
};

const containerThemes = {
  content: "content",
  article: "article"
};

const CampaignBlock = ({
  headingLink,
  text,
  link,
  image,
  theme,
  containerTheme,
  leftImage
}) => {
  return (
    <div
      className={cn("campaign-block", {
        [`campaign-block--${themes[theme]}`]: themes[theme],
        [`campaign-block--${containerThemes[containerTheme]}`]: containerThemes[
          containerTheme
        ],
        "campaign-block--image-left": leftImage
      })}
    >
      <ContentContainer
        themes={
          containerTheme === containerThemes.article
            ? [ContentContainer.themes.article]
            : [ContentContainer.themes.content]
        }
        className="campaign-block__wrapper"
      >
        <div className="campaign-block__content">
          <Heading level={2} className="campaign-block__heading">
            <Link themes={[Link.themes.title]} {...headingLink} />
          </Heading>
          <p className="campaign-block__text">{text}</p>
          {link && (
            <Link
              className="campaign-block__link"
              themes={[Link.themes.borderedButton]}
              {...link}
            />
          )}
        </div>
        {image && (
          <div className="campaign-block__image-part">
            <Image className="campaign-block__image" {...image} />
          </div>
        )}
      </ContentContainer>
    </div>
  );
};

CampaignBlock.propTypes = {
  headingLink: PropTypes.exact(Link.propTypes),
  text: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  theme: PropTypes.oneOf(Object.keys(themes)),
  link: PropTypes.exact(Link.propTypes),
  containerTheme: PropTypes.string,
  leftImage: PropTypes.bool
};

CampaignBlock.propTypesMeta = {
  containerTheme: "exclude"
};

CampaignBlock.defaultProps = {
  theme: themes.beige
};

CampaignBlock.containerThemes = containerThemes;

export default CampaignBlock;
