import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import arrayOfObjectValues from 'js/prop-types-helpers/array-of-object-values';

const themes = {
  article: 'article',
  content: 'content',
  document: 'document',
  narrow: 'narrow',
  megaMenu: 'mega-menu',
  articleOverflow: 'article-overflow',
  articleLimitedOverflow: 'article-limited-overflow',
  wide: 'wide',
  searchWidth: 'search-width'
};

const ContentContainer = ({
  children,
  className,
  component,
  themes,
  ...restProps
}) =>
  React.createElement(
    component,
    {
      ...restProps,
      className: cn(
        'content-container',
        themes.map(theme => `content-container--${theme}`),
        className
      )
    },
    children
  );

ContentContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  component: PropTypes.elementType,
  themes: arrayOfObjectValues(themes)
};

ContentContainer.defaultProps = {
  component: 'div',
  themes: []
};

ContentContainer.propTypesMeta = 'exclude';

ContentContainer.themes = themes;

export default ContentContainer;
