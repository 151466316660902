import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import arrayOfObjectValues from 'js/prop-types-helpers/array-of-object-values';

const themes = {
  button: 'button',
  default: 'default',
  darkInput: 'dark-input',
  hiddenInput: 'hidden-input',
  grayBackground: 'gray-background'
};

const Radio = ({
  className,
  isInitiallySelected,
  label,
  name,
  namePostfix,
  onChange,
  value,
  themes,
  isControlledInput
}) => {
  const themeClasses = themes.map(theme => `radio--${theme}`);
  const id = `radio-${name}-${value}${namePostfix ? namePostfix : ''}`;
  const inputName = namePostfix ? `${name}-${namePostfix}` : name;

  return (
    <div className={cn('radio', themeClasses)}>
      {isControlledInput ? (
        <input
          type="radio"
          id={id}
          name={inputName}
          onChange={onChange}
          value={value}
          checked={isInitiallySelected}
          className="radio__input"
        />
      ) : (
        <input
          type="radio"
          id={id}
          name={inputName}
          onChange={onChange}
          value={value}
          defaultChecked={isInitiallySelected}
          className="radio__input"
        />
      )}
      <label className={cn('radio__label', { className })} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

Radio.propTypes = {
  className: PropTypes.string,
  isInitiallySelected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  namePostfix: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
  themes: arrayOfObjectValues(themes),
  isControlledInput: PropTypes.bool
};

Radio.defaultProps = {
  themes: [],
  onChange: () => { }
};

Radio.propTypesMeta = {
  className: 'exclude',
  namePostfix: 'exclude',
  themes: 'exclude',
  isControlledInput: 'exclude'
};

Radio.getKey = props => props.value;

Radio.themes = themes;

export default Radio;
