import React from 'react';
import PropTypes from 'prop-types';
import ResourceAccordion from 'components/resource-accordion/resource-accordion';
import ContentContainer from 'components/content-container/content-container';
import ComponentList from 'components/component-list/component-list';

const ResourceAccordionBlock = ({ resourceAccordionList }) => (
  <ContentContainer className="resource-accordion-block">
    {resourceAccordionList &&
      resourceAccordionList.length > 0 &&
      resourceAccordionList.map(({ id, triggerText, contentList }) => (
        <ResourceAccordion
          id={id}
          key={triggerText}
          headingLevel={2}
          triggerText={triggerText}
        >
          <ComponentList {...contentList} />
        </ResourceAccordion>
      ))}
  </ContentContainer>
);

ResourceAccordionBlock.propTypes = {
  resourceAccordionList: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      contentList: PropTypes.exact(ComponentList.propTypes),
      triggerText: PropTypes.string
    })
  )
};

export default ResourceAccordionBlock;
