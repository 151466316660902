import React from 'react';
import PropTypes from 'prop-types';
import RichText from 'components/rich-text';
import Link from 'components/link';

const SearchResultItem = ({
  title,
  titleHref,
  metadata,
  metadataList,
  textAsXhtml
}) => {
  return (
    <li className="search-result-item">
      {titleHref ? (
        <h2 className="search-result-item__heading">
          <Link href={titleHref} text={title} />
        </h2>
      ) : (
        <h2 className="search-result-item__heading">{title}</h2>
      )}
      <p className="search-result-item__meta">{metadata}</p>
      {metadataList && metadataList.length > 0 && (
        <ul className="search-result-item__meta-list">
          {metadataList.map(
            ({
              title,
              data,
              href,
              secondTitle,
              secondData,
              secondHref,
              dataList
            }) => (
              <li key={title}>
                <strong>{title + ': '}</strong>
                {data && <Link href={href}>{data}</Link>}
                {secondTitle && secondData && (
                  <>
                    <span className="search-result-item__meta-divider">
                      {' | '}
                    </span>
                    <strong>{secondTitle + ': '}</strong>
                    <Link href={secondHref} text={secondData} />
                  </>
                )}
                {dataList && dataList.length > 0 && (
                  <ul>
                    {dataList.map((data, index) => (
                      <li key={index}>{data}</li>
                    ))}
                  </ul>
                )}
              </li>
            )
          )}
        </ul>
      )}
      <RichText html={textAsXhtml} />
    </li>
  );
};

SearchResultItem.propTypes = {
  title: PropTypes.string,
  titleHref: PropTypes.string,
  metadata: PropTypes.string,
  metadataList: PropTypes.arrayOf(
    PropTypes.exact({
      title: PropTypes.string,
      data: PropTypes.string,
      href: PropTypes.string,
      secondTitle: PropTypes.string,
      secondData: PropTypes.string,
      secondHref: PropTypes.string,
      dataList: PropTypes.arrayOf(PropTypes.string)
    })
  ),
  textAsXhtml: PropTypes.string
};

export default SearchResultItem;
