import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

const Image = ({ alt, className, src }) => {
  return <img className={cn('image', className)} alt={alt} src={src} />;
};

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  src: PropTypes.string.isRequired
};

Image.propTypesMeta = {
  className: 'exclude'
};

export default Image;
