import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import cn from "classnames";

import ids from "js/global-ids";
//import useEscape from 'hooks/use-escape';
import useToggle from "hooks/use-toggle";

import Breadcrumbs from "components/breadcrumbs";

import Link from "components/link";
import TabMenu from "components/tab-menu";

import useIsMounted from "hooks/use-is-mounted";
import { ReactComponent as HamburgerIcon } from "../../assets/icons/icon-hamburger-heavy.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/icon-close-heavy.svg";
import { ReactComponent as StatpedLogo } from "../../assets/icons/statped-logo.svg";
import GlobalSearch from "components/global-search/global-search";

const Header = ({
  breadcrumbs,
  linkList,
  byline,
  linkToHomeUrl,
  shouldBeHeading,
  megaMenuButtonText,
  search,
  tabMenu
}) => {
  const isMounted = useIsMounted();

  const [
    searchDrawerVisible,
    toggleSearchDrawer,
    setSearchDrawerVisible
  ] = useToggle(false);

  const [narrowMenuVisible, toggleNarrowMenu, setNarrowMenuVisible] = useToggle(
    false
  );

  const hideDrawers = () => {
    setNarrowMenuVisible(false);
    setSearchDrawerVisible(false);
  };

  let HeadingOrDiv = shouldBeHeading ? "h1" : "div";

  /* In order to not appear in UDIR's search noindex is added as a class, see: https://jira.udir.no/browse/UDIRSOK-239 */
  return (
    <React.Fragment>
      {tabMenu && <TabMenu {...tabMenu} />}
      <header className="header" id={ids.goToTopTarget} tabIndex="-1" lang="nb">
        <div className="header__content noindex">
          {linkToHomeUrl && byline && (
            <HeadingOrDiv className="header__heading">
              <a className="header__logo-link" href={linkToHomeUrl}>
                <StatpedLogo className="header__logo" />
                <span className="header__hidden-title">
                  {shouldBeHeading ? "Statped" : "Gå til forside - Statped"}
                </span>
                <span className="header__byline">{byline}</span>
              </a>
            </HeadingOrDiv>
          )}
          <div className="header__action-wrapper">
            {megaMenuButtonText && (
              <div
                className="header__nav-menu"
                onBlur={e =>
                  !e.currentTarget.contains(e.relatedTarget) &&
                  setNarrowMenuVisible(false)
                }
              >
                <button
                  aria-expanded={narrowMenuVisible}
                  className={cn("header__toggle", {
                    "header__toggle--active": narrowMenuVisible
                  })}
                  onClick={() => {
                    setSearchDrawerVisible(false);
                    toggleNarrowMenu();
                  }}
                >
                  <span className="header__toggle-content">
                    {narrowMenuVisible ? (
                      <span className="header__toggle-icon">
                        <CloseIcon />
                      </span>
                    ) : (
                      <span className="header__toggle-icon">
                        <HamburgerIcon />
                      </span>
                    )}
                    <span className="header__toggle-text">
                      {megaMenuButtonText}
                    </span>
                  </span>
                </button>
                <div
                  className={cn("header__menu-dropdown", {
                    "header__menu-dropdown--open": narrowMenuVisible
                  })}
                >
                  {linkList && (
                    <nav aria-label="Hovedmeny">
                      <ul className="header__link-list">
                        {linkList.map(link => (
                          <li className="header__list-item" key={link.text}>
                            <Link
                              className="header__nav-link"
                              themes={[Link.themes.navBar]}
                              {...link}
                            />
                          </li>
                        ))}
                      </ul>
                    </nav>
                  )}
                </div>
              </div>
            )}

            {search && (
              <GlobalSearch {...search} isMenuOpen={setSearchDrawerVisible} />
            )}
          </div>
        </div>
      </header>

      {breadcrumbs && (
        <div className="header__breadcrumbs noindex" lang="nb">
          <Breadcrumbs {...breadcrumbs} />
        </div>
      )}
      {isMounted &&
        ReactDOM.createPortal(
          <div
            className={cn("header__overlay", {
              "header__overlay--visible":
                narrowMenuVisible || searchDrawerVisible
            })}
            onClick={hideDrawers}
          />,
          document.body
        )}
    </React.Fragment>
  );
};

Header.propTypes = {
  byline: PropTypes.string,
  search: PropTypes.exact(GlobalSearch.propTypes),
  breadcrumbs: PropTypes.exact(Breadcrumbs.propTypes),
  linkList: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  linkToHomeUrl: PropTypes.string.isRequired,
  shouldBeHeading: PropTypes.bool,
  megaMenuButtonText: PropTypes.string,
  tabMenu: PropTypes.exact(TabMenu.propTypes)
};

export default Header;
