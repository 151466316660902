import React from 'react';
import PropTypes from 'prop-types';
import Heading from 'components/heading/heading';
import ContentContainer from 'components/content-container/content-container';
import Button from 'components/button/button';
import CheckBox from 'components/checkbox/checkbox';

const SubscribeNewsletter = ({
  heading,
  newsletters,
  emailInputLabel,
  actionPlaceholder,
  actionButtonText
}) => {
  return (
    <div className="subscribe-newsletter">
      <ContentContainer themes={[ContentContainer.themes.content]}>
        <form
          aria-labelledby="subscribe-form-heading"
          className="subscribe-newsletter__content"
        >
          <Heading
            id="subscribe-form-heading"
            className="subscribe-newsletter__heading"
            level={2}
          >
            {heading}
          </Heading>
          {newsletters && (
            <ul className="subscribe-newsletter__option-list">
              {newsletters.map(newsletter => (
                <li
                  key={newsletter.id}
                  className="subscribe-newsletter__option"
                >
                  <CheckBox {...newsletter} />
                </li>
              ))}
            </ul>
          )}
          <div className="subscribe-newsletter__action">
            <label
              className="subscribe-newsletter__field-label"
              htmlFor="email"
            >
              {emailInputLabel}
            </label>
            <input
              id={emailInputLabel}
              name="email"
              className="subscribe-newsletter__field"
              type="email"
              required="required"
              placeholder={actionPlaceholder}
            />
            <Button
              type="submit"
              value="Send"
              className="subscribe-newsletter__button"
              text={actionButtonText}
              themes={[Button.themes.red]}
            />
          </div>
        </form>
      </ContentContainer>
    </div>
  );
};

SubscribeNewsletter.propTypes = {
  heading: PropTypes.string,
  newsletters: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      id: PropTypes.string
    })
  ),
  emailInputLabel: PropTypes.string,
  actionPlaceholder: PropTypes.string,
  actionButtonText: PropTypes.string
};

export default SubscribeNewsletter;
