import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import cn from 'classnames';

const getAnimationFromKey = key => {
  try {
    let data = require(`../../assets/lottie/lottie-${key}.json`);
    return {
      loop: false,
      autoplay: true,
      animationData: data,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
  } catch {
    return undefined;
  }
};

const getAltFromKey = (key, altTexts) =>
  altTexts.find(alt => alt.key === key).altText;

const ErherAnimationsFromKey = ({ className, animationKey, altTexts }) => {
  let animationOptions = getAnimationFromKey(animationKey);
  let animationAlt = getAltFromKey(animationKey, altTexts);
  return (
    <div
      className={cn(className, 'lottie-animation')}
      role="img"
      aria-label={animationAlt}
    >
      <div aria-hidden={true} inert={''}>
        {animationOptions && (
          <Lottie
            options={animationOptions}
            className="lottie-animation__animation"
          />
        )}
      </div>
    </div>
  );
};

ErherAnimationsFromKey.propTypes = {
  className: PropTypes.string,
  animationKey: PropTypes.string,
  altTexts: PropTypes.arrayOf(
    PropTypes.exact({
      key: PropTypes.string,
      altText: PropTypes.string
    })
  )
};

ErherAnimationsFromKey.propTypesMeta = 'exclude';

export default ErherAnimationsFromKey;
