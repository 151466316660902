import React from "react";
import PropTypes from "prop-types";

import cn from "classnames";

import ContentContainer from "components/content-container/content-container";
import Heading from "components/heading/heading";
import Image from "components/image/image";
import Link from "components/link/link";
import PuzzelChat from "components/puzzel-chat/puzzel-chat";
import ComponentList from "components/component-list/component-list";
import RichTextBlock from "components/rich-text-block/rich-text-block";
import RichText from "components/rich-text/rich-text";

const containerThemes = {
  content: "content",
  article: "article"
};

const colorThemes = {
  beige: "beige",
  beigelight: "beigelight",
  beigelighter: "beigelighter"
};

const defaultColors = [
  colorThemes.beige,
  colorThemes.beigelight,
  colorThemes.beigelighter
];

const CampaignItemGroupBlock = ({
  heading,
  campaignItemList,
  containerTheme,
  numberOfColumns
}) => {
  return (
    <div
      className={cn("campaign-item-group-block", {
        "campaign-item-group-block--heading": heading,
        [`campaign-item-group-block--${containerThemes[containerTheme]}`]: containerThemes[
          containerTheme
        ]
      })}
    >
      <ContentContainer themes={[containerThemes[containerTheme]]}>
        {heading && (
          <Heading className="campaign-item-group-block__heading" level={2}>
            {heading}
          </Heading>
        )}
        <div className="campaign-item-group-block__wrapper">
          {campaignItemList.map(
            (
              {
                headingLink,
                intro,
                content,
                linkList,
                puzzelChat,
                image,
                link,
                imageIsBackground,
                imageToLeft,
                color
              },
              index
            ) => (
              <div
                className={cn(
                  "campaign-item-group-block__campaign-item",
                  numberOfColumns < 3
                    ? [
                        {
                          "campaign-item-group-block__campaign-item--juxtaposed": image
                        },
                        {
                          "campaign-item-group-block__campaign-item--centered": !image
                        },
                        {
                          "campaign-item-group-block__campaign-item--image-to-left":
                            imageToLeft === true
                        },
                        {
                          "campaign-item-group-block__campaign-item--two-wide":
                            numberOfColumns === 2
                        },
                        {
                          "campaign-item-group-block__campaign-item--one-wide":
                            numberOfColumns === 1
                        }
                      ]
                    : "campaign-item-group-block__campaign-item--three-wide",
                  color
                    ? [
                        `campaign-item-group-block__campaign-item--${colorThemes[color]}`
                      ]
                    : [
                        `campaign-item-group-block__campaign-item--${defaultColors[index]}`
                      ],
                  {
                    "campaign-item-group-block__campaign-item--image-background": imageIsBackground
                  }
                )}
                key={index}
              >
                <div className="campaign-item-group-block__content">
                  {headingLink && (
                    <Heading
                      level={heading ? 3 : 2}
                      className="campaign-item-group-block__content-heading"
                    >
                      <span className="campaign-item-group-block__content-background">
                        <Link themes={[Link.themes.title]} {...headingLink} />
                      </span>
                    </Heading>
                  )}
                  {imageIsBackground && intro && (
                    <p className="campaign-item-group-block__content-intro">
                      <span className="campaign-item-group-block__content-background">
                        {intro}
                      </span>
                    </p>
                  )}
                  {!imageIsBackground && (
                    <>
                      {content && (
                        <ComponentList
                          className="campaign-item-group-block__content-list"
                          {...content}
                          additionalComponentProps={{
                            RichTextBlock: {
                              containerTheme:
                                RichTextBlock.containerThemes.content,
                              richTextThemes: [RichText.themes.darkLinks]
                            },
                            VideoBlock: {
                              fullWidth: true
                            }
                          }}
                        />
                      )}

                      {linkList && (
                        <div className="campaign-item-group-block__link-spacer">
                          <ul className="campaign-item-group-block__link-list">
                            {linkList.map(link => (
                              <li
                                className="campaign-item-group-block__link-list-item"
                                key={link.text}
                              >
                                <Link
                                  className="campaign-item-group-block__link-list-link"
                                  themes={[Link.themes.neutralDark]}
                                  {...link}
                                />
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}

                      {puzzelChat && (
                        <div className="campaign-item-group-block__link-spacer">
                          <div className="campaign-item-group-block__chat">
                            <PuzzelChat {...puzzelChat} />
                          </div>
                        </div>
                      )}

                      {link && (
                        <div className="campaign-item-group-block__link-spacer">
                          <Link
                            className="campaign-item-group-block__link"
                            themes={[Link.themes.borderedButton]}
                            {...link}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
                {image && (
                  <div className="campaign-item-group-block__image-part">
                    <Image
                      className="campaign-item-group-block__image"
                      {...image}
                    />
                  </div>
                )}
              </div>
            )
          )}
        </div>
      </ContentContainer>
    </div>
  );
};

CampaignItemGroupBlock.propTypes = {
  heading: PropTypes.string,
  campaignItemList: PropTypes.arrayOf(
    PropTypes.exact({
      headingLink: PropTypes.exact(Link.propTypes),
      intro: PropTypes.string,
      content: PropTypes.exact(ComponentList.propTypes),
      linkList: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
      puzzelChat: PropTypes.exact(PuzzelChat.propTypes),
      image: PropTypes.exact(Image.propTypes),
      link: PropTypes.exact(Link.propTypes),
      imageToLeft: PropTypes.bool,
      imageIsBackground: PropTypes.bool,
      color: PropTypes.oneOf(Object.keys(colorThemes))
    })
  ),
  containerTheme: PropTypes.oneOf(Object.keys(containerThemes)),
  numberOfColumns: PropTypes.number
};

CampaignItemGroupBlock.propTypesMeta = {
  containerTheme: "exclude"
};

CampaignItemGroupBlock.containerThemes = containerThemes;

export default CampaignItemGroupBlock;
