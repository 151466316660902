import React from "react";
import PropTypes from "prop-types";
import Button from "components/button/button";
import cn from "classnames";

import { ReactComponent as SearchIcon } from "../../assets/icons/icon-search-copy.svg";

const themes = {
  default: "search-field--default",
  magazine: "search-field--magazine",
  thin: "search-field--thin",
  erHer: "search-field--erher"
};

const SearchField = ({
  id,
  textInputLabel,
  buttonLabel,
  searchText,
  onSearch,
  onSearchTextChange,
  labelVisible,
  theme
}) => {
  return (
    <div
      className={cn(
        "search-field",
        {
          "search-field--active": searchText
        },
        {
          "search-field--visible-label": labelVisible
        },
        theme
      )}
    >
      <label className="search-field__label" htmlFor={id}>
        {textInputLabel}
      </label>
      <div className="search-field__wrapper">
        <input
          className="search-field__input"
          id={id}
          name={id}
          type="search"
          value={searchText}
          onChange={onSearchTextChange}
          placeholder={textInputLabel}
        />
        <Button
          className="search-field__button"
          type="submit"
          text={buttonLabel}
          onClick={onSearch}
          textIsHidden={theme !== themes.erHer}
          themes={
            theme !== themes.erHer
              ? [Button.themes.red]
              : [Button.themes.lightRed, Button.themes.iconWithText]
          }
        >
          <SearchIcon className="search-field__icon" />
        </Button>
      </div>
    </div>
  );
};

SearchField.propTypesMeta = {
  searchText: "exclude",
  labelVisible: "exclude",
  theme: "exclude"
};

SearchField.propTypes = {
  id: PropTypes.string,
  textInputLabel: PropTypes.string,
  buttonLabel: PropTypes.string,
  searchText: PropTypes.string,
  onSearch: PropTypes.func,
  onSearchTextChange: PropTypes.func,
  labelVisible: PropTypes.bool,
  theme: PropTypes.string
};

SearchField.themes = themes;

export default SearchField;
