import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Heading from 'components/heading/heading';
import RichText from 'components/rich-text/rich-text';
import Button from 'components/button/button';
import { ReactComponent as Chevron } from '../../assets/icons/icon-chevron-left.svg';
import cn from 'classnames';

const SupportMaterialBlock = ({
  heading,
  headingLevel,
  richText,
  moreButtonText,
  lessButtonText,
  moreRichText
}) => {
  const [showMore, setShowMore] = useState(false);
  const contentId = heading
    ? heading.replace(/\s+/g, '')
    : richText?.replace(/\s+/g, '');

  return (
    <div
      className={cn('support-material-block', {
        'support-material-block--open': showMore
      })}
    >
      {heading && <Heading level={headingLevel}>{heading}</Heading>}
      <RichText html={richText} themes={[RichText.themes.neutral]} />
      {moreRichText && (
        <>
          <Button
            className="support-material-block__more-button"
            aria-expanded={showMore}
            aria-controls={contentId}
            onClick={() => setShowMore(!showMore)}
          >
            <span className="support-material-block__chevron">
              <Chevron />
            </span>
            <span className="support-material-block__more-text">
              {showMore && lessButtonText ? lessButtonText : moreButtonText}
            </span>
          </Button>
          <div
            className={cn('support-material-block__more-blocks', {
              'support-material-block__more-blocks--open': showMore
            })}
            id={contentId}
          >
            <RichText html={moreRichText} themes={[RichText.themes.neutral]} />
          </div>
        </>
      )}
    </div>
  );
};

SupportMaterialBlock.propTypes = {
  heading: PropTypes.string,
  headingLevel: PropTypes.number,
  richText: PropTypes.string,
  moreButtonText: PropTypes.string,
  lessButtonText: PropTypes.string,
  moreRichText: PropTypes.string
};

SupportMaterialBlock.propTypesMeta = {
  headingLevel: 'exclude'
};

export default SupportMaterialBlock;
