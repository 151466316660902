import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import useClickOutside from 'hooks/use-click-outside';
import Button from 'components/button/button';

import { ReactComponent as Checkmark } from '../../assets/icons/icon-checkmark.svg';

const resetFilters = ref => {
  let filterList = ref.current.querySelectorAll(
    '.filter-list-dropdown__checkbox'
  );
  for (var i = 0; i < filterList.length; i++) {
    filterList[i].checked = false;
  }
};

const FilterListDropdown = ({
  options,
  label,
  id,
  className,
  onToggleOpen,
  resetButtonText
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef();
  useClickOutside(ref, () => {
    setIsOpen(false);
  });

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    onToggleOpen(!isOpen);
  };

  useEffect(() => {
    if (!isOpen) {
      onToggleOpen(false);
    }
  }, [isOpen]);

  return (
    <div
      ref={ref}
      className={cn('filter-list-dropdown', className, {
        'filter-list-dropdown--open': isOpen
      })}
    >
      <button
        type="button"
        className={cn('filter-list-dropdown__toggler', {
          'filter-list-dropdown__toggler--open': isOpen
        })}
        aria-expanded="false"
        aria-controls={id}
        data-toggle={id}
        onClick={toggleOpen}
      >
        <span className="filter-list-dropdown__text">{label}</span>
      </button>
      <div
        className="filter-list-dropdown__menu-wrapper"
        inert={isOpen ? null : ''}
      >
        <div
          id={id}
          className="filter-list-dropdown__menu"
          data-filter-advanced
        >
          <div className="filter-list-dropdown__menu-content">
            {resetButtonText && (
              <Button
                type="button"
                text={resetButtonText}
                themes={[Button.themes.link]}
                onClick={() => resetFilters(ref)}
              />
            )}

            <ul className="filter-list-dropdown__option-list">
              {options &&
                options.map(({ checked, label, id, options }) => (
                  <li key={id} className="filter-list-dropdown__option">
                    <input
                      className="filter-list-dropdown__checkbox"
                      type="checkbox"
                      name="f"
                      id={id}
                      value={id}
                      defaultChecked={checked}
                    />
                    <div>
                      <label
                        className="filter-list-dropdown__option-label"
                        htmlFor={id}
                      >
                        <span className="filter-list-dropdown__checkmark">
                          <Checkmark />
                        </span>
                        {label}
                      </label>
                    </div>
                    {options && (
                      <ul className="filter-list-dropdown__option-list">
                        {options.map(({ checked, label, id, options }) => (
                          <li key={id} className="filter-list-dropdown__option">
                            <input
                              className="filter-list-dropdown__checkbox"
                              type="checkbox"
                              name="f"
                              id={id}
                              value={id}
                              defaultChecked={checked}
                            />
                            <div>
                              <label
                                className="filter-list-dropdown__option-label"
                                htmlFor={id}
                              >
                                <span className="filter-list-dropdown__checkmark">
                                  <Checkmark />
                                </span>
                                {label}
                              </label>
                            </div>
                            {options && (
                              <ul className="filter-list-dropdown__option-list">
                                {options.map(({ checked, label, id }) => (
                                  <li
                                    key={id}
                                    className="filter-list-dropdown__option"
                                  >
                                    <input
                                      className="filter-list-dropdown__checkbox"
                                      type="checkbox"
                                      name="f"
                                      id={id}
                                      value={id}
                                      defaultChecked={checked}
                                    />
                                    <div>
                                      <label
                                        className="filter-list-dropdown__option-label"
                                        htmlFor={id}
                                      >
                                        <span className="filter-list-dropdown__checkmark">
                                          <Checkmark />
                                        </span>
                                        {label}
                                      </label>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

FilterListDropdown.propTypes = {
  className: PropTypes.string,
  onToggleOpen: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      checked: PropTypes.bool,
      label: PropTypes.string,
      id: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.exact({
          checked: PropTypes.bool,
          label: PropTypes.string,
          id: PropTypes.string,
          options: PropTypes.arrayOf(
            PropTypes.exact({
              checked: PropTypes.bool,
              label: PropTypes.string,
              id: PropTypes.string
            })
          )
        })
      )
    })
  ),
  label: PropTypes.string,
  id: PropTypes.string,
  resetButtonText: PropTypes.string
};

FilterListDropdown.propTypesMeta = {
  className: 'exclude',
  onToggleOpen: 'exclude'
};

FilterListDropdown.resetFilters = resetFilters;

export default FilterListDropdown;
