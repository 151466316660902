import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout/layout';
import ComponentList from 'components/component-list/component-list';
import RichText from 'components/rich-text/rich-text';
import ContentContainer from 'components/content-container/content-container';
import CampaignItemGroupBlock from 'components/campaign-item-group-block/campaign-item-group-block';
import EmployeeListBlock from 'components/employee-list-block/employee-list-block';

const ContentPage = ({ body, layout }) => {
  return (
    <Layout {...layout}>
      <div className="content-page">
        {body && (
          <div className="magazine-article__body">
            <ComponentList
              themes={[ComponentList.themes.margins]}
              additionalComponentProps={{
                RichText: { themes: [RichText.themes.article] },
                ResourceBlock: {
                  containerTheme: ContentContainer.themes.content
                },
                CampaignBlock: {
                  containerTheme: ContentContainer.themes.content
                },
                CampaignItemGroupBlock: {
                  containerTheme: CampaignItemGroupBlock.containerThemes.content
                },
                EmployeeListBlock: {
                  containerTheme: EmployeeListBlock.containerThemes.content
                }
              }}
              {...body}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

ContentPage.propTypes = {
  body: PropTypes.exact(ComponentList.propTypes),
  layout: PropTypes.exact(Layout.propTypes)
};

export default ContentPage;
