import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Collapse from 'react-tiny-collapse';

import Heading from 'components/heading';
import useToggle from 'hooks/use-toggle';

const ResourceAccordion = ({ id, children, headingLevel, triggerText }) => {
  const contentRef = useRef();
  const triggerRef = useRef();

  const [isOpen, toggleIsOpen] = useToggle();

  return (
    <div
      className={cn('resource-accordion', {
        'resource-accordion--open': isOpen
      })}
    >
      <Heading
        level={headingLevel}
        className="resource-accordion__title"
        themes={[Heading.themes.noMargins]}
      >
        <button
          className={cn('resource-accordion__trigger', {
            'resource-accordion__trigger--active': isOpen
          })}
          aria-expanded={isOpen}
          aria-controls={id}
          onClick={toggleIsOpen}
          ref={triggerRef}
        >
          <span className="resource-accordion__trigger-content">
            {triggerText && (
              <span className="resource-accordion__trigger-text">
                {triggerText}
              </span>
            )}
          </span>
        </button>
      </Heading>
      <div
        id={id}
        className={cn('resource-accordion__content-wrapper', {
          'resource-accordion__content-wrapper--open': isOpen
        })}
        ref={contentRef}
        tabIndex="-1"
      >
        <Collapse className="resource-accordion__collapse" isOpen={isOpen}>
          <div className="resource-accordion__content">{children}</div>
        </Collapse>
      </div>
    </div>
  );
};

ResourceAccordion.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  headingLevel: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  triggerText: PropTypes.string.isRequired
};

ResourceAccordion.propTypesMeta = 'exclude';

export default ResourceAccordion;
