import React from 'react';
import PropTypes from 'prop-types';
import Accordion from 'components/accordion/accordion';
import ContentContainer from 'components/content-container/content-container';
import RichText from 'components/rich-text/rich-text';

const AccordionBlock = ({ accordionList, accordionTheme, richTextThemes }) => (
  <ContentContainer className="accordion-block">
    {accordionList &&
      accordionList.length > 0 &&
      accordionList.map(({ triggerText, htmlContent, headingLevel }) => (
        <Accordion
          key={triggerText}
          headingLevel={headingLevel || 2}
          visualLevel={2}
          triggerText={triggerText}
          theme={accordionTheme}
        >
          <RichText html={htmlContent} themes={richTextThemes} />
        </Accordion>
      ))}
  </ContentContainer>
);

AccordionBlock.propTypes = {
  accordionList: PropTypes.arrayOf(
    PropTypes.exact({
      htmlContent: PropTypes.string,
      triggerText: PropTypes.string,
      headingLevel: PropTypes.number
    })
  ),
  accordionTheme: PropTypes.string,
  richTextThemes: PropTypes.string
};

AccordionBlock.propTypesMeta = {
  accordionTheme: 'exclude',
  richTextThemes: 'exclude'
};

export default AccordionBlock;
