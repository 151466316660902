import React from 'react';
import PropTypes from 'prop-types';
import ComponentList from 'components/component-list/component-list';
import RichText from 'components/rich-text/rich-text';
import MagazineArticleHeader from 'components/magazine-article-header/magazine-article-header';
import Layout from 'components/layout/layout';

const MagazineArticle = ({ header, body, layout }) => {
  return (
    <Layout {...layout}>
      <div className="magazine-article">
        {header && <MagazineArticleHeader {...header} />}
        {body && (
          <div className="magazine-article__body">
            <ComponentList
              additionalComponentProps={{
                RichText: { themes: [RichText.themes.article] }
              }}
              {...body}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

MagazineArticle.propTypes = {
  header: PropTypes.exact(MagazineArticleHeader.propTypes),
  body: PropTypes.exact(ComponentList.propTypes),
  layout: PropTypes.exact(Layout.propTypes)
};

export default MagazineArticle;
