import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link/link';
import cn from 'classnames';
import useClickOutside from 'hooks/use-click-outside';

const LinkListDropdown = ({ buttonText, linkList }) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = React.useRef();
  useClickOutside(ref, () => setIsOpen(false));
  const contentId = buttonText.replace(/\s+/g, '');
  return (
    <div ref={ref} className="link-list-dropdown">
      <button
        className="link-list-dropdown__button"
        aria-expanded={isOpen}
        aria-controls={contentId}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="link-list-dropdown__button-text">{buttonText}</span>
      </button>
      <div
        id={contentId}
        className={cn('link-list-dropdown__content', {
          'link-list-dropdown__content--open': isOpen
        })}
      >
        {linkList &&
          linkList.map(link => (
            <div key={link.href} className="link-list-dropdown__link">
              <Link {...link} />
            </div>
          ))}
      </div>
    </div>
  );
};

LinkListDropdown.propTypes = {
  buttonText: PropTypes.string,
  linkList: PropTypes.arrayOf(PropTypes.exact(Link.propTypes))
};

export default LinkListDropdown;
