import React from 'react';
import PropTypes from 'prop-types';
import ContentContainer from 'components/content-container/content-container';

const VideoEmbedElementBlock = ({ src, accessibleTitle, containerTheme }) => {
  return (
    <ContentContainer
      className="video-embed-element-block"
      themes={[containerTheme]}
    >
      <video
        className="video-embed-element-block__video"
        controls
        aria-label={accessibleTitle}
      >
        <source src={src} />
      </video>
    </ContentContainer>
  );
};

VideoEmbedElementBlock.propTypes = {
  src: PropTypes.string,
  accessibleTitle: PropTypes.string,
  containerTheme: PropTypes.string
};

VideoEmbedElementBlock.propTypesMeta = {
  containerTheme: 'exclude'
};

export default VideoEmbedElementBlock;
