import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import supportedBlocks from './supported-blocks';
import { error } from 'js/log';

import ReactComponent from 'components/react-component';

const backgroundThemes = {
  default: 'default',
  gray: 'gray'
};

const ComponentListItem = ({
  additionalComponentProps,
  componentNotSupportedText,
  backgroundTheme,
  data,
  id,
  name,
  langTag
}) => {
  const Component = supportedBlocks()[name];

  if (!Component) {
    error(`😵 ComponentList does not support rendering of '${name}'.`);
    return componentNotSupportedText ? (
      <div className="component-list-item--fallback">
        {componentNotSupportedText} <i>{name}</i>
      </div>
    ) : null;
  }

  if (!data || !Object.keys(data).length) {
    return null;
  }

  const additionalProps = additionalComponentProps[name];

  if (process.env.NODE_ENV !== 'production') {
    // NOTE: wrapping in 'props' in order to do strict validation with 'exact' (top level propTypes are loosely validated)
    PropTypes.checkPropTypes(
      { props: PropTypes.exact(Component.propTypes) },
      { props: { ...data, ...additionalProps } },
      'prop',
      name
    );
  }

  return (
    <div
      key={id}
      id={id}
      className={cn('component-list-item', {
        [`component-list-item--background-${backgroundThemes[backgroundTheme]}`]: backgroundThemes[
          backgroundTheme
        ]
      })}
      lang={langTag}
    >
      <Component {...data} {...additionalProps} />
    </div>
  );
};

ComponentListItem.getKey = props => props && props.id;

ComponentListItem.propTypes = {
  additionalComponentProps: PropTypes.object,
  backgroundTheme: PropTypes.oneOf(Object.keys(backgroundThemes)),
  componentNotSupportedText: PropTypes.string,
  data: PropTypes.shape(ReactComponent.propTypes).isRequired, // Intentional use of 'shape' because validation of 'componentData' is handled by the components themselves
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  langTag: PropTypes.string
};

ComponentListItem.propTypesMeta = {
  additionalComponentProps: 'exclude',
  componentNotSupportedText: 'exclude'
};

ComponentListItem.defaultProps = {
  componentNotSupportedText: null
};

export default ComponentListItem;
