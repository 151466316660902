import React from "react";
import PropTypes from "prop-types";

import Heading from "components/heading/heading";
import Image from "components/image/image";
import RichText from "components/rich-text/rich-text";
import Video from "components/video/video";

const PageHeader = ({
  heading,
  introAsHtml,
  textAsHtml,
  image,
  video,
  theme
}) => {
  return (
    <div className="page-header">
      <div className="page-header__content-wrapper">
        <div className="page-header__content">
          <Heading level={1} className="page-header__heading">
            {heading}
          </Heading>
          {introAsHtml && (
            <RichText className="page-header__intro" html={introAsHtml} />
          )}
          {textAsHtml && (
            <RichText className="page-header__text" html={textAsHtml} />
          )}
          {video && (
            <div className="page-header__video-part">
              <Video
                className="page-header__video"
                {...video}
                isLargeVideo={true}
              />
            </div>
          )}
        </div>
      </div>
      <div className="page-header__image-part">
        {image && !video && <Image className="page-header__image" {...image} />}
      </div>
    </div>
  );
};

PageHeader.propTypes = {
  heading: PropTypes.string,
  introAsHtml: PropTypes.string,
  textAsHtml: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  video: PropTypes.exact(Video.propTypes)
};

export default PageHeader;
