import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import spinner from '../../assets/loading-spinner.png';

const LoadingSpinner = ({ className, text }) => (
  <div
    role="alert"
    aria-busy={true}
    className={cn('loading-spinner', className)}
  >
    <span className="loading-spinner__text">{text}</span>
    <img aria-hidden={true} src={spinner} />
  </div>
);

LoadingSpinner.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string
};

LoadingSpinner.defaultProps = {
  text: 'Laster'
};

LoadingSpinner.propTypesMeta = 'exclude';

export default LoadingSpinner;
