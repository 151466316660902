import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { ReactComponent as SearchIcon } from '../../assets/icons/icon-search.svg';

const GlobalSearch = ({
  buttonOpenText,
  buttonCloseText,
  searchFormDescription,
  searchEndpoint,
  searchFieldLabel,
  placeholder,
  searchButtonText,
  isMenuOpen
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const openSearch = () => {
    isMenuOpen(true);
    setIsOpen(true);
  };
  const closeSearch = () => {
    isMenuOpen(false);
    setIsOpen(false);
  };

  return (
    <div className="global-search">
      <div
        id="search"
        className={cn('search-wrapper', { expanded: isOpen })}
        data-search
      >
        <button
          type="button"
          className="search-close"
          aria-hidden="true"
          tabIndex="-1"
          onClick={closeSearch}
        >
          {buttonCloseText}
        </button>

        <div className="search">
          <div className="search-inner">
            <form
              aria-label={searchFormDescription}
              role="search"
              action={searchEndpoint}
              onFocus={openSearch}
              onBlur={closeSearch}
            >
              <fieldset>
                <div className="search-actions">
                  <label
                    htmlFor="global-search-input"
                    className="search-terms-label"
                  >
                    <SearchIcon />
                    <span className="visuallyhidden">{searchFieldLabel}</span>
                    <span aria-hidden="true">{buttonOpenText}</span>
                  </label>
                  <input
                    id="global-search-input"
                    name="q"
                    type="search"
                    placeholder={placeholder}
                    accessKey="s"
                    autoComplete="off"
                    className="search-terms"
                  />
                  <button type="submit" className="search-submit">
                    <span className="visuallyhidden">{searchButtonText}</span>
                    <SearchIcon />
                  </button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

GlobalSearch.propTypes = {
  buttonOpenText: PropTypes.string,
  buttonCloseText: PropTypes.string,
  searchFormDescription: PropTypes.string,
  searchEndpoint: PropTypes.string,
  searchFieldLabel: PropTypes.string,
  placeholder: PropTypes.string,
  searchButtonText: PropTypes.string,
  isMenuOpen: PropTypes.func
};

export default GlobalSearch;
