import React from 'react';
import PropTypes from 'prop-types';
import Heading from 'components/heading/heading';
import RichText from 'components/rich-text/rich-text';
import SupportMaterialBlock from 'components/support-material-block/support-material-block';

const SupportMaterials = ({ heading, textAsHtml, blocks }) => {
  return (
    <div className="support-materials">
      {heading && <Heading level={2}>{heading}</Heading>}
      <RichText className="support-materials__text" html={textAsHtml} />
      <div className="support-materials__blocks">
        {blocks?.map((block, i) => (
          <SupportMaterialBlock
            key={i}
            {...block}
            headingLevel={heading ? 3 : 2}
          />
        ))}
      </div>
    </div>
  );
};

SupportMaterials.propTypes = {
  heading: PropTypes.string,
  textAsHtml: PropTypes.string,
  blocks: PropTypes.arrayOf(PropTypes.exact(SupportMaterialBlock.propTypes))
};

export default SupportMaterials;
