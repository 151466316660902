import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import useIsMounted from 'hooks/use-is-mounted';
import useEvent from 'hooks/use-event';
import useToggle from 'hooks/use-toggle';
import useClickOutside from 'hooks/use-click-outside';

const themes = {
  simple: 'select--simple',
  simpleBubble: 'select--simple-bubble',
  short: 'select--short',
  long: 'select--long',
  longPreselected: 'select--long-preselected',
  borderless: 'select--borderless',
  innerShadow: 'select--innershadow',
  regelverkstolkninger: 'select--regelverkstolkninger',
  visibleLabel: 'select--visible-label',
  erher: 'select--erher'
};

// NOTE: This is a naive implementation of 'lodash/get'. Replace it if you have 'lodash' as a dependency
const get = (object, key, fallback) => {
  try {
    return object[key];
  } catch (_) {
    return fallback;
  }
};

/*
NOTE: Since an option can't have `null` or `false` as a value (the text label of the option will be used instead),
the "null" choice is represented using a single space. A single space is serialized to an empty value when
submitting a form (which is what we want). Comparing against `magicNullValue` also lets the component return `null`
from its `onChange` callback when selecting the placeholder option.
*/

const magicNullValue = ' ';

const Select = ({
  className,
  selectedId,
  disabled,
  id,
  name,
  labelText,
  onChange,
  options,
  placeholder,
  theme
}) => {
  const [isOpen, toggle, setIsOpen] = useToggle(false);

  const [hasTouch, setHasTouch] = React.useState(false);
  useEvent('touchstart', () => setHasTouch(true));

  const fakeSelectRef = React.useRef();
  useClickOutside(fakeSelectRef, () => setIsOpen(false));

  const isMounted = useIsMounted();

  const handleChange = value => {
    onChange(value);
    setIsOpen(false);
  };

  const selectedLabel = React.useMemo(() => {
    return get(
      options.find(o => o.value === selectedId),
      'label',
      placeholder ? placeholder.label : 'Select'
    );
  }, [options, selectedId]);

  return (
    <div
      className={cn('select', className, theme, {
        'select--has-touch': hasTouch,
        'select--is-mounted': isMounted,
        'select--is-disabled': disabled
      })}
    >
      {labelText && (
        <label htmlFor={id} className="select__text-label">
          {labelText}
        </label>
      )}
      <select
        disabled={disabled}
        name={name}
        id={id}
        onChange={e => handleChange(e.target.value)}
        value={selectedId}
      >
        {placeholder && (
          <option value={placeholder.value || magicNullValue} disabled>
            {placeholder.label}
          </option>
        )}
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
      <div className="select__fake" ref={fakeSelectRef}>
        <div
          className={cn('select__element', {
            'select__element--active': isOpen
          })}
          onClick={disabled ? () => {} : toggle}
        >
          <span className="select__label">{selectedLabel}</span>
        </div>
        {isOpen && (
          <ul>
            {options.map(({ value, label }) => (
              <li
                className={cn('select__option', {
                  'select__option--chosen': value === selectedId
                })}
                key={value}
                onClick={() => handleChange(value)}
              >
                <span>{label}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

Select.propTypes = {
  className: PropTypes.string,
  selectedId: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  placeholder: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  theme: PropTypes.string
};

Select.propTypesMeta = {
  className: 'exclude',
  disabled: 'exclude',
  theme: 'exclude',
  selectedId: 'exclude'
};

Select.defaultProps = {
  theme: 'simple',
  onChange: () => {},
  onBlur: () => {},
  options: []
};

Select.getKey = props => props.name;

Select.themes = themes;

export default Select;
