import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link/link';
import cn from 'classnames';

const NavigationMenu = ({
  prevLink,
  nextLink,
  numberedPageHrefs,
  currentPageNr
}) => {
  return (
    <nav aria-label="Sidenavigasjon">
      <ul className="navigation-menu">
        <li className="navigation-menu__prev">
          <Link {...prevLink} />
        </li>
        {numberedPageHrefs &&
          numberedPageHrefs.map(({ href, linkNr }) => (
            <li key={href} className="navigation-menu__list-item">
              <a
                className={cn('navigation-menu__nummbered-link', {
                  'navigation-menu__nummbered-link--current':
                    currentPageNr === linkNr
                })}
                href={href}
                aria-current={currentPageNr === linkNr ? 'page' : null}
              >
                {linkNr}
              </a>
            </li>
          ))}
        <li className="navigation-menu__next">
          <Link {...nextLink} />
        </li>
      </ul>
    </nav>
  );
};

NavigationMenu.propTypes = {
  prevLink: PropTypes.exact(Link.propTypes),
  nextLink: PropTypes.exact(Link.propTypes),
  numberedPageHrefs: PropTypes.arrayOf(
    PropTypes.exact({
      href: PropTypes.string,
      linkNr: PropTypes.number
    })
  ),
  currentPageNr: PropTypes.number
};

export default NavigationMenu;
