import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link/link';
import cn from 'classnames';
import useClickOutside from 'hooks/use-click-outside';
import arrayOfObjectValues from 'js/prop-types-helpers/array-of-object-values';

import { ReactComponent as DownloadIcon } from '../../assets/icons/icon-download.svg';

const themes = {
  small: 'download-links-dropdown--small',
  wide: 'download-links-dropdown--wide',
  greyContent: 'download-links-dropdown--grey-content',
  staticMenu: 'download-links-dropdown--static-menu'
};

const DownloadLinksDropdown = ({
  id,
  buttonText,
  linkList,
  themes,
  isDropDown
}) => {
  const contentId = 'dropDownContent-' + id;

  const [isOpen, setIsOpen] = useState(false);

  const ref = React.useRef();
  useClickOutside(ref, () => setIsOpen(false));
  return (
    <div ref={ref} className={cn('download-links-dropdown', themes)}>
      {linkList && isDropDown ? (
        <button
          className="download-links-dropdown__button"
          onClick={() => setIsOpen(!isOpen)}
          aria-expanded={isOpen}
          aria-controls={contentId}
        >
          <div className={'download-links-dropdown__button-icon'}>
            <DownloadIcon />
          </div>

          <div
            className={cn(
              'download-links-dropdown__button-text',
              'download-links-dropdown__button-text--skew'
            )}
          >
            {buttonText}
          </div>
        </button>
      ) : (
        <Link
          className="download-links-dropdown__button"
          themes={[Link.themes.white]}
          href={linkList[0].href}
        >
          <div className={cn('download-links-dropdown__button-text')}>
            {buttonText}
          </div>
        </Link>
      )}
      <div
        id={contentId}
        className={cn('download-links-dropdown__content', {
          'download-links-dropdown__content--open': isOpen
        })}
        aria-hidden={!isOpen}
        hidden={!isOpen}
      >
        {linkList &&
          isDropDown &&
          linkList.map(link => (
            <div key={link.href} className="download-links-dropdown__link">
              <Link {...link} />
            </div>
          ))}
      </div>
    </div>
  );
};

DownloadLinksDropdown.propTypes = {
  id: PropTypes.string,
  buttonText: PropTypes.string,
  linkList: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  themes: arrayOfObjectValues(themes),
  isDropDown: PropTypes.bool
};

DownloadLinksDropdown.propTypesMeta = {
  themes: 'exclude'
};

DownloadLinksDropdown.defaultProps = {
  themes: [themes.small]
};

DownloadLinksDropdown.themes = themes;

export default DownloadLinksDropdown;
