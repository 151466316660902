import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Heading from 'components/heading/heading';
import RichText from 'components/rich-text/rich-text';
import Button from 'components/button/button';
import SearchField from 'components/search-field/search-field';
import FilterListDropdown from 'components/filter-list-dropdown/filter-list-dropdown';
import SearchFilterMenu from 'components/search-filter-menu/search-filter-menu';
import Link from 'components/link/link';
import ComponentList from 'components/component-list/component-list';

import { ReactComponent as ShoppingCartIcon } from '../../assets/icons/icon-shopping-cart.svg';
import OrderingForm from 'components/ordering-form/ordering-form';

const TeachingAidsHeader = ({
  heading,
  introAsXhtml,
  content,
  orderedAidsListHeading,
  removeAllButtonText,
  itemsInCart,
  removeOrderButtonText,
  sendOrderLink,
  nrSelectLabel,
  formURL,
  searchURL,
  searchText,
  search,
  filterMenu,
  orderForm,
  removeTeachingAid,
  removeAllTeachingAids
}) => {
  const formRef = useRef();

  const onSearch = () => formRef.current.submit();

  const [searchTextState, setSearchTextState] = useState(
    searchText ? searchText : ''
  );

  const OrderingFormOrDiv = orderForm ? 'form' : 'div';
  const orderingFormAttribs = orderForm
    ? {
        ref: formRef,
        action: formURL,
        method: 'get',
        id: 'search',
        'aria-labelledby': 'form-heading'
      }
    : {};

  return (
    <OrderingFormOrDiv
      className="teaching-aids-header"
      {...orderingFormAttribs}
    >
      <div className="teaching-aids-header__pre-content">
        <Heading id="form-heading" level={1}>
          {heading}
        </Heading>
        {introAsXhtml && <RichText html={introAsXhtml} />}
      </div>
      {content && <ComponentList {...content} />}
      <div className="teaching-aids-header__post-content">
        {orderedAidsListHeading && (
          <div className="teaching-aids-header__ordered-items-box">
            <div className="teaching-aids-header__ordered-items-header">
              <span>
                <ShoppingCartIcon />
                <Heading
                  className="teaching-aids-header__ordered-items-heading"
                  level={2}
                >
                  {orderedAidsListHeading}
                </Heading>
              </span>
              {removeAllButtonText && (
                <Button
                  className="teaching-aids-header__remove-all-items"
                  themes={[Button.themes.bordered]}
                  onClick={removeAllTeachingAids}
                  disabled={!itemsInCart.length}
                >
                  {removeAllButtonText}
                </Button>
              )}
              {nrSelectLabel && (
                <span
                  id="nrSelectLabel"
                  className="teaching-aids-header__nr-select-label"
                >
                  {nrSelectLabel}
                </span>
              )}
            </div>
            {itemsInCart && itemsInCart.length > 0 && (
              <ul className="teaching-aids-header__orders-list">
                {itemsInCart.map(({ id, titleForOrder, nrSelect }) => (
                  <li className="teaching-aids-header__order" key={id}>
                    <span className="teaching-aids-header__order-item">
                      <span id={id}>{titleForOrder}</span>
                      {removeOrderButtonText && (
                        <Button
                          className="teaching-aids-header__remove-order-button"
                          themes={[Button.themes.link]}
                          onClick={() => removeTeachingAid(id)}
                          text={removeOrderButtonText}
                        />
                      )}
                    </span>
                    {nrSelect && (
                      <div className="teaching-aids-header__select-wrapper">
                        <select
                          aria-labelledby={id}
                          aria-describedby="nrSelectLabel"
                          className="teaching-aids-header__nr-select"
                          name={id}
                        >
                          <option value={1}>{1}</option>
                          <option value={2}>{2}</option>
                        </select>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            )}
            {sendOrderLink && (
              <Link
                role="link"
                className="teaching-aids-header__send-order-button"
                themes={[Link.themes.redButton, Link.themes.fitContent]}
                {...sendOrderLink}
                href={
                  itemsInCart.length > 0
                    ? sendOrderLink.href +
                      '?orderedAids=' +
                      itemsInCart.map(item => item.id).join('&orderedAids=')
                    : undefined
                }
                aria-disabled={!itemsInCart.length}
              />
            )}
          </div>
        )}
        {(search || filterMenu) && (
          <form
            role="search"
            aria-label="På denne siden"
            ref={formRef}
            action={searchURL}
            method="get"
            id="search"
          >
            {search && (
              <div className="teaching-aids-header__search-wrapper">
                <SearchField
                  onSearch={onSearch}
                  theme={SearchField.themes.thin}
                  onSearchTextChange={e => setSearchTextState(e.target.value)}
                  searchText={searchTextState}
                  {...search}
                />
              </div>
            )}
            {filterMenu && (
              <div className="teaching-aids-header__filter-wrapper">
                <SearchFilterMenu {...filterMenu} />
              </div>
            )}
          </form>
        )}
        {orderForm && (
          <OrderingForm additionalData={itemsInCart} {...orderForm} />
        )}
      </div>
    </OrderingFormOrDiv>
  );
};

TeachingAidsHeader.propTypes = {
  heading: PropTypes.string,
  introAsXhtml: PropTypes.string,
  content: PropTypes.exact(ComponentList.propTypes),
  orderedAidsListHeading: PropTypes.string,
  removeAllButtonText: PropTypes.string,
  itemsInCart: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      titleForOrder: PropTypes.string,
      nrSelect: PropTypes.bool
    })
  ),
  removeOrderButtonText: PropTypes.string,
  nrSelectLabel: PropTypes.string,
  sendOrderLink: PropTypes.exact(Link.propTypes),
  formURL: PropTypes.string,
  searchURL: PropTypes.string,
  searchText: PropTypes.string,
  search: PropTypes.exact(SearchField.propTypes),
  filterMenu: PropTypes.exact(SearchFilterMenu.propTypes),
  orderForm: PropTypes.exact(OrderingForm.propTypes),
  removeTeachingAid: PropTypes.func,
  removeAllTeachingAids: PropTypes.func
};

TeachingAidsHeader.propTypesMeta = {
  itemsInCart: 'exclude'
};

export default TeachingAidsHeader;
