import React from 'react';
import PropTypes from 'prop-types';

import Heading from 'components/heading/heading';
import RichText from 'components/rich-text/rich-text';
import Layout from 'components/layout/layout';
import ContentContainer from 'components/content-container/content-container';

const ErrorPageContent = ({ layout, header, type, htmlIntro, htmlBody }) => (
  <Layout {...layout}>
    <div className="error-page">
      <ContentContainer themes={[ContentContainer.themes.article]}>
        {header && <Heading level={1}>{header}</Heading>}
        <p className="error-page__type">{type}</p>
        <div className="error-page__intro">
          <RichText html={htmlIntro} />
        </div>
        <div className="error-page__body">
          <RichText html={htmlBody} />
        </div>
      </ContentContainer>
    </div>
  </Layout>
);

ErrorPageContent.propTypes = {
  layout: PropTypes.exact(Layout.propTypes),
  header: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  htmlIntro: PropTypes.exact(RichText.propTypes),
  htmlBody: PropTypes.exact(RichText.propTypes)
};

export default ErrorPageContent;
