import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button/button';
import Link from 'components/link/link';

const PuzzelChat = ({ puzzelChatButtonText, linkAlternative }) => {
  return (
    <div className="puzzel-chat">
      {linkAlternative ? (
        <Link themes={[Link.themes.dark]} {...linkAlternative} />
      ) : (
        <>
          <script src="https://chat.puzzel.com/Content/Client/js/jquery-latest.min.js"></script>
          <script src="https://chat.puzzel.com/Content/Client/js/jquery-intelecomchat.libs.latest.min.js"></script>
          <script src="https://chat.puzzel.com/Content/Client/js/jquery-intelecomchat.latest.min.js"></script>
          <span
            dangerouslySetInnerHTML={{
              __html:
                "<script>jQuery.noConflict();jQuery( document ).ready(function( $ ) {$('#puzzelChat').intelecomChat({customerKey: '106914',queueKey: 'Q_chat_statped',languageCode: 'NO',showForm: true,showStarter: false,showStarterWhenMinimized: false, onChatWindowShow: function () {$('#startChat').hide();},onChatWindowMinimize: function () {$('#startChat').html('Vis chat');$('#startChat').show();},onChatWindowClose: function () {$('#startChat').html('Start chat');$('#startChat').show();}});});</script>"
            }}
          />
          <Button
            id="startChat"
            name="startChat"
            className="chatstarter"
            themes={[Button.themes.bordered]}
          >
            {puzzelChatButtonText}
          </Button>
          <div id="puzzelChat" className="intelecomchat"></div>
        </>
      )}
    </div>
  );
};

PuzzelChat.propTypes = {
  puzzelChatButtonText: PropTypes.string,
  linkAlternative: PropTypes.exact(Link.propTypes)
};

export default PuzzelChat;
