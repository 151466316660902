import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout/layout';
import Heading from 'components/heading/heading';
import ErHerSearchAndFilter from 'components/er-her-search-and-filter/er-her-search-and-filter';
import ResourceCard from 'components/resource-card/resource-card';
import LoadingSpinner from 'components/loading-spinner/loading-spinner';
import apiHelper from 'js/api-helper';

const ErHerSearchPage = ({
  heading,
  searchMenu,
  resultInfo,
  searchResults,
  searchApiEndpoint
}) => {
  const [resultInfoText, setResultInfoText] = useState(resultInfo);
  const [results, setResults] = useState(searchResults);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = (searchText, filters) => {
    setIsLoading(true);
    apiHelper
      .execute(searchApiEndpoint, { searchText, filters })
      .then(({ resultInfo, searchResults }) => {
        setResultInfoText(resultInfo);
        setResults(searchResults);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <Layout>
      <div className="er-her-search-page">
        <div className="er-her-search-page__header">
          <Heading themes={[Heading.themes.erHer]} level={1}>
            {heading}
          </Heading>
          <ErHerSearchAndFilter onSearch={handleSearch} {...searchMenu} />
        </div>
        <div className="er-her-search-page__results">
          <span
            className="er-her-search-page__result-live-region"
            aria-live="polite"
            aria-atomic="true"
          >
            {!isLoading && resultInfoText}
          </span>
          {isLoading && (
            <LoadingSpinner
              className="er-her-search-page__loading-spinner"
              text="Laster innhold"
            />
          )}
          {results && !!results.length && !isLoading && (
            <ul className="er-her-search-page__result-list">
              {results.map((result, i) => (
                <li key={i} className="er-her-search-page__result-list-item">
                  <ResourceCard {...result} />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </Layout>
  );
};

ErHerSearchPage.propTypes = {
  heading: PropTypes.string,
  searchMenu: PropTypes.exact(ErHerSearchAndFilter.propTypes),
  resultInfo: PropTypes.string,
  searchResults: PropTypes.arrayOf(PropTypes.exact(ResourceCard.propTypes)),
  searchApiEndpoint: PropTypes.string
};

export default ErHerSearchPage;
