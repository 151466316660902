import React from 'react';
import PropTypes from 'prop-types';
import ComponentList from 'components/component-list/component-list';
import RichText from 'components/rich-text/rich-text';
import ContentContainer from 'components/content-container/content-container';
import ErherResourceHeader from 'components/erher-resource-header/erher-resource-header';
import Heading from 'components/heading/heading';
import ErherResourceSuggestionsBanner from 'components/erher-resource-suggestions-banner/erher-resource-suggestions-banner';
import Accordion from 'components/accordion/accordion';

const ErherResource = ({
  header,
  aboutHeading,
  categoryTitle,
  category,
  subjectTitle,
  subject,
  body,
  otherResources
}) => {
  return (
    <div className="erher-resource">
      <main>
        <ErherResourceHeader {...header} />
        <ContentContainer className="erher-resource__about">
          {aboutHeading && (
            <Heading
              className="erher-resource__about-heading"
              level={2}
              themes={[Heading.themes.erHer]}
            >
              {aboutHeading}
            </Heading>
          )}
          <div className="erher-resource__about-data">
            {categoryTitle && category && (
              <div>
                <strong>{categoryTitle + ': '}</strong>
                {category}
              </div>
            )}
            {subjectTitle && subject && (
              <div>
                <strong>{subjectTitle + ': '}</strong>
                {subject}
              </div>
            )}
          </div>
        </ContentContainer>
        {body && (
          <div className="erher-resource__content">
            <ComponentList
              themes={[ComponentList.themes.margins]}
              additionalComponentProps={{
                RichText: { themes: [RichText.themes.erher] },
                RichTextBlock: {
                  richTextThemes: [RichText.themes.erher]
                },
                ResourceBlock: {
                  containerTheme: ContentContainer.themes.content
                },
                CampaignBlock: {
                  containerTheme: ContentContainer.themes.content
                },
                VideoEmbedElementBlock: {
                  containerTheme: ContentContainer.themes.articleLimitedOverflow
                },
                VideoBlock: {
                  containerTheme: ContentContainer.themes.articleLimitedOverflow
                },
                EmbededContentBlock: {
                  containerTheme: ContentContainer.themes.articleLimitedOverflow
                },
                AccordionBlock: {
                  accordionTheme: Accordion.themes.erher,
                  richTextThemes: [RichText.themes.erher]
                }
              }}
              {...body}
            />
          </div>
        )}
      </main>
      <ErherResourceSuggestionsBanner {...otherResources} />
    </div>
  );
};

ErherResource.propTypes = {
  header: PropTypes.exact(ErherResourceHeader.propTypes),
  aboutHeading: PropTypes.string,
  categoryTitle: PropTypes.string,
  category: PropTypes.string,
  subjectTitle: PropTypes.string,
  subject: PropTypes.string,
  body: PropTypes.exact(ComponentList.propTypes),
  otherResources: PropTypes.exact(ErherResourceSuggestionsBanner.propTypes)
};

export default ErherResource;
