import React from "react";
import PropTypes from "prop-types";
import RichText from "components/rich-text/rich-text";
import ContentContainer from "components/content-container/content-container";

const ParallaxBlock = ({ html, imageSrc, focusPoint }) => {
  return (
    <ContentContainer className="parallax-block">
      <div className="parallax-block__parallax-wrapper">
        <div
          className="parallax-block__parallax"
          style={{
            backgroundImage: `url(${imageSrc})`,
            backgroundPosition: focusPoint
              ? `${focusPoint.x}% ${focusPoint.y}%`
              : "center"
          }}
        />
      </div>
      <div className="parallax-block__textbox">
        <RichText html={html} />
      </div>
    </ContentContainer>
  );
};

ParallaxBlock.propTypes = {
  html: PropTypes.string,
  imageSrc: PropTypes.string,
  focusPoint: PropTypes.exact({
    x: PropTypes.number,
    y: PropTypes.number
  })
};

export default ParallaxBlock;
