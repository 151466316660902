import React from "react";
import PropTypes from "prop-types";
import Image from "components/image/image";

const FeaturedArticle = ({ image, text, href }) => {
  return (
    <a className="featured-article" href={href}>
      <div className="featured-article__text-wrapper">
        <span className="featured-article__text">{text}</span>
      </div>
      <div className="featured-article__image-wrapper">
        <Image className="featured-article__image" alt="" {...image} />
      </div>
    </a>
  );
};

FeaturedArticle.propTypes = {
  image: PropTypes.exact(Image.propTypes),
  text: PropTypes.string,
  href: PropTypes.string
};

export default FeaturedArticle;
