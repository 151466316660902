import React from 'react';
import PropTypes from 'prop-types';
import ContentContainer from 'components/content-container/content-container';
import Link from 'components/link/link';
import Heading from 'components/heading/heading';
import cn from 'classnames';
import RichText from 'components/rich-text/rich-text';

const componentThemes = {
  threeCols: 'three-cols',
  fourCols: 'four-cols',
  minimal: 'minimal'
};

const LinkListBlock = ({
  heading,
  ingress,
  links,
  theme,
  noOuterBorderAndUnderline
}) => {
  const expected = theme === 'threeCols' ? 3 : theme === 'fourCols' ? 4 : 0;
  const missingBlocks = (expected - (links.length % expected)) % expected;

  return (
    <div
      className={cn(
        'link-list-block',
        [`link-list-block--${componentThemes[theme]}`],
        {
          [`link-list-block--no-outer-border-and-underline`]: noOuterBorderAndUnderline
        }
      )}
    >
      <ContentContainer
        className="link-list-block__container"
        themes={[ContentContainer.themes.wide]}
      >
        {heading && (
          <Heading className="link-list-block__heading">{heading}</Heading>
        )}
        {ingress && (
          <RichText className="link-list-block__ingress" html={ingress} />
        )}
        {links && links.length > 0 && (
          <ul className="link-list-block__list">
            {links.map(({ href, title, text }, index) => (
              <li className="link-list-block__item" key={index}>
                {theme === componentThemes.minimal ? (
                  <Link
                    className="link-list-block__link"
                    href={href}
                    text={title}
                    themes={[
                      Link.themes.grayButton,
                      Link.themes.bigButton,
                      ...(noOuterBorderAndUnderline
                        ? Link.themes.decorated
                        : [])
                    ]}
                  />
                ) : (
                  <a className="link-list-block__link" href={href}>
                    <span>
                      <p className="link-list-block__link-title">{title}</p>
                    </span>
                    {text && (
                      <p className="link-list-block__link-text">{text}</p>
                    )}
                  </a>
                )}
              </li>
            ))}
            {missingBlocks > 0 &&
              Array(missingBlocks)
                .fill(0)
                .map((_, index) => (
                  <li
                    key={index}
                    className={cn(
                      'link-list-block__item',
                      'link-list-block__item--no-border'
                    )}
                  />
                ))}
          </ul>
        )}
      </ContentContainer>
    </div>
  );
};

LinkListBlock.propTypes = {
  heading: PropTypes.string,
  ingress: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.exact({
      href: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string
    })
  ),
  theme: PropTypes.oneOf(Object.keys(componentThemes)),
  noOuterBorderAndUnderline: PropTypes.bool
};

LinkListBlock.defaultProps = {
  theme: []
};

export default LinkListBlock;
