import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import arrayOfObjectValues from 'js/prop-types-helpers/array-of-object-values';

const themes = {
  article: 'article',
  ingress: 'ingress',
  neutral: 'neutral',
  document: 'document',
  inverted: 'inverted',
  centered: 'centered',
  fancyLinks: 'fancy-links',
  darkLinks: 'dark-links',
  whiteLinks: 'white-links',
  faintLinks: 'faint-links',
  slimText: 'slim-text',
  abstract: 'abstract',
  listStyleReset: 'list-style-reset',
  wide: 'wide',
  erher: 'erher'
};

const RichText = ({ className, html, themes }) => {
  const themeClasses = themes.map(theme => `rich-text--${theme}`);

  return (
    <div
      className={cn('rich-text', themeClasses, className)}
      dangerouslySetInnerHTML={{
        __html: html
      }}
    />
  );
};

RichText.propTypes = {
  className: PropTypes.string,
  html: PropTypes.string.isRequired,
  themes: arrayOfObjectValues(themes)
};

RichText.propTypesMeta = {
  className: 'exclude',
  themes: 'exclude'
};

RichText.defaultProps = {
  html: '',
  themes: []
};

RichText.themes = themes;

export default RichText;
