/* eslint-disable no-console */
import React from 'react';

/** Render a list of components. Components rendered this way need to have a `getKey` property (a function that accepts props and returns a `string`)
```jsx
const Component = ({ text }) => <div>{text}</div>;
Component.getKey = props => props.text;

// Render a list of `Text` components:
const OtherComponent = ({ texts }) => (
  <div>{mapWithKey(texts, Text)}</div>
);
```
 */
export default function mapWithKey(data = [], Component, extraProps = {}) {
  return data.map((props, index) => {
    const key = Component.getKey && Component.getKey(props);

    if (process.env.NODE_ENV === 'development') {
      if (!Component.getKey) {
        console.error(
          `mapWithKey: Key missing for '${Component.name}' because it doesn't have a 'getKey' method`
        );
      } else if (!key) {
        console.error(
          `mapWithKey: Key missing for '${Component.name}' because nothing was returned from '${Component.name}.getKey'.`
        );
      }
    }

    const extra =
      typeof extraProps === 'function' ? extraProps(index) : extraProps;

    return React.createElement(Component, {
      key: `${index}-${key}`,
      ...props,
      ...extra
    });
  });
}
