import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link/link';
import cn from 'classnames';
import useClickOutside from 'hooks/use-click-outside';
import { ReactComponent as ExternalLinkIcon } from '../../assets/icons/icon-link-out-no-background.svg';
import { ReactComponent as ExternalLinkHoverIcon } from '../../assets/icons/icon-link-out-hover.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/icon-link-download_no-background.svg';
import { ReactComponent as DownloadIconHover } from '../../assets/icons/icon-link-download_hover.svg';

const ErherLinkListDropdown = ({ className, buttonText, linkList }) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = React.useRef();
  useClickOutside(ref, () => setIsOpen(false));
  const contentId = buttonText.replace(/\s+/g, '');
  return (
    <div
      ref={ref}
      className={cn('erher-link-list-dropdown', className, {
        'erher-link-list-dropdown--open': isOpen
      })}
    >
      <button
        className="erher-link-list-dropdown__button"
        aria-expanded={isOpen}
        aria-controls={contentId}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="erher-link-list-dropdown__button-text">
          {buttonText}
        </span>
      </button>
      <div id={contentId} className="erher-link-list-dropdown__content">
        {linkList && linkList.length && (
          <ul className="erher-link-list-dropdown__link-list" role="menu">
            {linkList.map(({ href, text, isDownload }) => (
              <li
                key={href}
                className="erher-link-list-dropdown__list-item"
                role="menuitem"
              >
                <a
                  key={href}
                  className="erher-link-list-dropdown__link"
                  themes={[Link.themes.erherNavBar]}
                  href={href}
                >
                  <span className="erher-link-list-dropdown__link-text">
                    {text}
                  </span>
                  {isDownload ? (
                    <>
                      <DownloadIcon
                        className="erher-link-list-dropdown__link-icon"
                        aria-label="Nedlasting"
                      />
                      <DownloadIconHover
                        className="erher-link-list-dropdown__link-icon erher-link-list-dropdown__link-icon--hover"
                        aria-hidden="true"
                      />
                    </>
                  ) : (
                    <>
                      <ExternalLinkIcon
                        className="erher-link-list-dropdown__link-icon"
                        aria-label="Ekstern side"
                      />
                      <ExternalLinkHoverIcon
                        className="erher-link-list-dropdown__link-icon erher-link-list-dropdown__link-icon--hover"
                        aria-hidden="true"
                      />
                    </>
                  )}
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

ErherLinkListDropdown.propTypes = {
  className: PropTypes.string,
  buttonText: PropTypes.string,
  linkList: PropTypes.arrayOf({
    href: PropTypes.string,
    text: PropTypes.string,
    isDownload: PropTypes.bool
  })
};

ErherLinkListDropdown.propTypesMeta = {
  className: 'exclude'
};

export default ErherLinkListDropdown;
