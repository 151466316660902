import React from 'react';
import PropTypes from 'prop-types';

import ComponentList from 'components/component-list/component-list';
import RichText from 'components/rich-text/rich-text';
import ArticleHeader from 'components/article-header/article-header';
import BylineBlock from 'components/byline-block/byline-block';
import Layout from 'components/layout/layout';
import ProductInfo from 'components/product-info/product-info';
import VideoBlock from 'components/video-block/video-block';
import ContentContainer from 'components/content-container/content-container';

const TeachingResource = ({
  focusVideo,
  header,
  body,
  layout,
  byline,
  afterContent,
  productInfo
}) => {
  return (
    <Layout {...layout}>
      <div className="teaching-resource">
        {focusVideo && (
          <div className="teaching-resource__focus-video">
            <VideoBlock {...focusVideo} />
          </div>
        )}
        <div className="teaching-resource__header">
          {header && <ArticleHeader {...header} />}
          {byline && <BylineBlock {...byline} />}
        </div>
        <div className="teaching-resource__sidebar">
          <ProductInfo {...productInfo} />
        </div>
        {body && (
          <div className="teaching-resource__body">
            <ComponentList
              additionalComponentProps={{
                RichText: { themes: [RichText.themes.article] }
              }}
              {...body}
            />
          </div>
        )}
        <div className="teaching-resource__after">
          {afterContent && (
            <div className="teaching-resource__body">
              <ComponentList
                themes={[ComponentList.themes.margins]}
                additionalComponentProps={{
                  RichText: { themes: [RichText.themes.article] },
                  ResourceBlock: {
                    containerTheme: ContentContainer.themes.content
                  },
                  CampaignBlock: {
                    containerTheme: ContentContainer.themes.content
                  }
                }}
                {...afterContent}
              />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

TeachingResource.propTypes = {
  header: PropTypes.exact(ArticleHeader.propTypes),
  byline: PropTypes.exact(BylineBlock.propTypes),
  body: PropTypes.exact(ComponentList.propTypes),
  layout: PropTypes.exact(Layout.propTypes),
  productInfo: PropTypes.exact(ProductInfo.propTypes),
  focusVideo: PropTypes.exact(VideoBlock.propTypes),
  afterContent: PropTypes.exact(ComponentList.propTypes)
};

export default TeachingResource;
