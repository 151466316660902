import React from 'react';
import PropTypes from 'prop-types';
import Heading from 'components/heading/heading';
import RichText from 'components/rich-text/rich-text';

const CourseInformationParagraph = ({ title, textAsHtml }) => (
  <div className="course-information-paragraph">
    <Heading className="course-information-paragraph__title" level={2}>
      {title}
    </Heading>
    <RichText html={textAsHtml} />
  </div>
);

CourseInformationParagraph.propTypes = {
  title: PropTypes.string,
  textAsHtml: PropTypes.string
};

export default CourseInformationParagraph;
