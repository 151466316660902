import React from 'react';
import PropTypes from 'prop-types';

import ids from 'js/global-ids';
import Feedback from 'components/feedback/feedback';

/* import Feedback from 'components/feedback';
import GoToTop from 'components/go-to-top'; */

const Layout = ({ children, feedback }) => {
  /* In order to not appear in UDIR's search noindex is added as a class, see: https://jira.udir.no/browse/UDIRSOK-239 */
  return (
    <main className="layout" id={ids.mainContent} tabIndex="-1">
      {children}
      {feedback && <Feedback {...feedback} />}
    </main>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  feedback: PropTypes.exact(Feedback.propTypes)
};

Layout.defaultProps = {
  showWarningBackground: false
};

export default Layout;
