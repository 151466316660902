import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Heading from 'components/heading/heading';
import RichText from 'components/rich-text/rich-text';
import ContentContainer from 'components/content-container/content-container';
import cn from 'classnames';
import Link from 'components/link/link';
import { ReactComponent as WorkIcon } from '../../assets/icons/icon-work.svg';
import { ReactComponent as MailIcon } from '../../assets/icons/icon-mail.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/icon-phone.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/icon-plus.svg';
import { ReactComponent as MinusIcon } from '../../assets/icons/icon-minus.svg';

import Button from 'components/button/button';

const containerThemes = {
  content: ContentContainer.themes.content,
  article: ContentContainer.themes.article
};

const EmployeeListBlock = ({
  heading,
  ingress,
  employees,
  defaultShownEmployees,
  showMoreButtonText,
  showLessButtonText,
  containerTheme
}) => {
  const [shownEmployees, setShownEmployees] = useState(
    employees.slice(0, defaultShownEmployees)
  );

  const firstHiddenEmployeeRef = useRef();

  const handleToggleShowMore = () => {
    if (shownEmployees.length === defaultShownEmployees) {
      setShownEmployees(employees);
    } else {
      setShownEmployees(employees.slice(0, defaultShownEmployees));
    }
  };

  useEffect(() => {
    if (firstHiddenEmployeeRef.current) firstHiddenEmployeeRef.current.focus();
  }, [firstHiddenEmployeeRef.current]);

  return (
    <div
      className={cn('employee-list-block', {
        [`employee-list-block--${containerThemes[containerTheme]}`]: containerThemes[
          containerTheme
        ]
      })}
    >
      <ContentContainer themes={[containerThemes[containerTheme]]}>
        <div className="employee-list-block__header">
          <Heading className="employee-list-block__heading" level={2}>
            {heading}
          </Heading>
          {ingress && (
            <RichText className="employee-list-block__ingress" html={ingress} />
          )}
        </div>
        {employees && (
          <ul
            className="employee-list-block__list"
            ref={
              shownEmployees.length === defaultShownEmployees
                ? firstHiddenEmployeeRef
                : null
            }
          >
            {shownEmployees.map(
              ({ name, mail, phoneNumber, workAddress }, index) => (
                <li
                  key={index}
                  className="employee-list-block__list-item"
                  tabIndex={index === defaultShownEmployees ? -1 : null}
                  ref={
                    index === defaultShownEmployees
                      ? firstHiddenEmployeeRef
                      : null
                  }
                >
                  <div className="employee-list-block__column-left">
                    <div className="employee-list-block__data">
                      {name && name}
                    </div>
                    <div className="employee-list-block__data">
                      {mail && (
                        <>
                          <span className="employee-list-block__icon">
                            <MailIcon />
                          </span>
                          <Link
                            className="employee-list-block__data-link"
                            themes={[Link.themes.noUnderline]}
                            {...mail}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="employee-list-block__column-right">
                    <div className="employee-list-block__data">
                      {phoneNumber && (
                        <>
                          <span className="employee-list-block__icon">
                            <PhoneIcon />
                          </span>
                          <Link
                            className="employee-list-block__data-link"
                            themes={[Link.themes.noUnderline]}
                            {...phoneNumber}
                          />
                        </>
                      )}
                    </div>
                    <div className="employee-list-block__data">
                      {workAddress && (
                        <>
                          <span className="employee-list-block__icon">
                            <WorkIcon />
                          </span>
                          {workAddress}
                        </>
                      )}
                    </div>
                  </div>
                </li>
              )
            )}
          </ul>
        )}
        {showMoreButtonText &&
          showLessButtonText &&
          employees &&
          defaultShownEmployees < employees.length && (
            <Button
              className="employee-list-block__show-button"
              onClick={handleToggleShowMore}
            >
              {shownEmployees.length === defaultShownEmployees ? (
                <>
                  <PlusIcon /> {showMoreButtonText}
                </>
              ) : (
                <>
                  <MinusIcon /> {showLessButtonText}
                </>
              )}
            </Button>
          )}
      </ContentContainer>
    </div>
  );
};

EmployeeListBlock.propTypes = {
  heading: PropTypes.string,
  ingress: PropTypes.string,
  employees: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      mail: PropTypes.exact(Link.propTypes),
      phoneNumber: PropTypes.exact(Link.propTypes),
      workAddress: PropTypes.string
    })
  ),
  defaultShownEmployees: PropTypes.number,
  showMoreButtonText: PropTypes.string,
  showLessButtonText: PropTypes.string,
  containerTheme: PropTypes.oneOf(Object.keys(containerThemes))
};

EmployeeListBlock.propTypesMeta = {
  containerTheme: 'exclude'
};

EmployeeListBlock.containerThemes = containerThemes;

export default EmployeeListBlock;
