import React from 'react';
import PropTypes from 'prop-types';

import ArticleHeader from 'components/article-header/article-header';
import BylineBlock from 'components/byline-block/byline-block';
import SubscribeNewsletter from 'components/subcribe-newsletter/subcribe-newsletter';
import Layout from 'components/layout/layout';

const Newsletter = ({ header, byline, intro, subscribe, layout }) => {
  return (
    <Layout {...layout}>
      <div className="newsletter">
        {header && <ArticleHeader {...header} />}
        {byline && <BylineBlock {...byline} />}
        {intro && <p className="newsletter__intro">{intro}</p>}
        {subscribe && <SubscribeNewsletter {...subscribe} />}
      </div>
    </Layout>
  );
};

Newsletter.propTypes = {
  header: PropTypes.exact(ArticleHeader.propTypes),
  byline: PropTypes.exact(BylineBlock.propTypes),
  intro: PropTypes.string,
  subscribe: PropTypes.exact(SubscribeNewsletter.propTypes),
  layout: PropTypes.exact(Layout.propTypes)
};

export default Newsletter;
