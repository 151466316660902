import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import arrayOfObjectValues from 'js/prop-types-helpers/array-of-object-values';

const themes = {
  black: 'black',
  white: 'white',
  blackOutline: 'black-outline',
  whiteOutline: 'white-outline',
  blackWide: 'black-wide',
  bubble: 'bubble',
  bubbleActive: 'bubble-active',
  green: 'green',
  redText: 'red-text',
  small: 'small',
  long: 'long',
  fillWidth: 'fill-width',
  link: 'link',
  noUnderline: 'no-underline',
  whiteLink: 'white-link',
  bordered: 'bordered',
  circular: 'circular',
  iconWithText: 'icon-with-text',
  whiteBackground: 'white-background',
  gray: 'gray',
  purple: 'purple',
  red: 'red',
  lightRed: 'light-red',
  big: 'big'
};

const Button = React.forwardRef(
  (
    {
      children,
      className,
      disabled,
      onClick,
      textIsHidden,
      onMouseEnter,
      text,
      themes,
      type,
      ...restProps
    },
    ref
  ) => {
    const themeClasses = themes.map(theme => `button--${theme}`);
    return (
      <button
        className={cn('button', className, themeClasses)}
        disabled={disabled}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        ref={ref}
        type={type}
        {...restProps}
      >
        {text && (
          <span
            className={cn('button__text', {
              'button__text--hidden': textIsHidden
            })}
          >
            {text}
          </span>
        )}
        {children}
      </button>
    );
  }
);

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  textIsHidden: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  text: PropTypes.string,
  themes: arrayOfObjectValues(themes),
  type: PropTypes.string
};

Button.defaultProps = {
  onClick: () => {},
  type: 'button',
  themes: []
};

Button.propTypesMeta = {
  className: 'exclude',
  disabled: 'exclude',
  themes: 'exclude',
  type: 'exclude',
  textIsHidden: 'exclude'
};

Button.themes = themes;

export default Button;
