import React from "react";
import PropTypes from "prop-types";
import ContentContainer from "components/content-container/content-container";

const QuoteBlock = ({ quote, name, profession }) => {
  return (
    <ContentContainer
      className="quote-block"
      themes={[ContentContainer.themes.article]}
    >
      <blockquote className="quote-block__quote">{quote}</blockquote>
      <small className="quote-block__person">{name}</small>
      <small className="quote-block__profession">{profession}</small>
    </ContentContainer>
  );
};

QuoteBlock.propTypes = {
  quote: PropTypes.string,
  name: PropTypes.string,
  profession: PropTypes.string
};

export default QuoteBlock;
