import React from 'react';
import PropTypes from 'prop-types';
import ProcessAccordion from 'components/process-accordion/process-accordion';
import ContentContainer from 'components/content-container/content-container';
import Heading from 'components/heading/heading';

const containerThemes = {
  article: ContentContainer.themes.article,
  content: ContentContainer.themes.content
};

const ProcessAccordionBlock = ({
  id,
  title,
  accordionList,
  mainOnToggle,
  openKey,
  containerTheme
}) => {
  const onToggle = key => {
    mainOnToggle(key);
  };

  return (
    <div id={id} className="process-accordion-block">
      <ContentContainer themes={[containerThemes[containerTheme]]}>
        {title && (
          <Heading className="process-accordion-block__heading" level={2}>
            {title}
          </Heading>
        )}
        {accordionList &&
          accordionList.map(accordion => (
            <ProcessAccordion
              key={accordion.id}
              headingLevel={3}
              openKey={openKey}
              onToggle={onToggle}
              {...accordion}
              theme={
                containerTheme === 'article' ? ProcessAccordion.themes.gray : ''
              }
            />
          ))}
      </ContentContainer>
    </div>
  );
};

ProcessAccordionBlock.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  accordionList: PropTypes.arrayOf(PropTypes.exact(ProcessAccordion.propTypes)),
  mainOnToggle: PropTypes.func,
  openKey: PropTypes.string,
  containerTheme: PropTypes.oneOf(Object.keys(containerThemes))
};

ProcessAccordionBlock.defaultProps = {
  containerTheme: 'article'
};

ProcessAccordion.propTypesMeta = {
  mainOnToggle: 'exclude',
  openKey: 'exclude',
  containerTheme: 'exclude'
};

ProcessAccordion.containerThemes = containerThemes;

export default ProcessAccordionBlock;
