import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import SearchField from 'components/search-field/search-field';
import Button from 'components/button/button';
import CategoryFilter from 'components/category-filter/category-filter';
import Select from 'components/select/select';
import cn from 'classnames';
import { ReactComponent as PlusIcon } from '../../assets/icons/icon-plus.svg';
import { ReactComponent as MinusIcon } from '../../assets/icons/icon-minus.svg';

const categoryKeys = [
  'alle',
  'barn',
  'trinn14',
  'trinn57',
  'trinn810',
  'vgs',
  'voksen'
];

const ErHerSearchAndFilter = ({
  defaultTargetGroup,
  defaultRessourceType,
  defaultSubject,
  defaultSearchText,
  search,
  resetFiltersText,
  filterOpenButtonText,
  filterClosedButtonText,
  targetGroupFilter,
  resourceTypeSelector,
  subjectSelector,
  subjectsOptionsPerTargetGroup,
  onSearch
}) => {
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);

  const [selectedTargetGroup, setSelectedTargetGroup] = useState(
    defaultTargetGroup || {
      categoryKey: 'alle',
      category: 'Alle'
    }
  );
  const [searchText, setSearchText] = useState(defaultSearchText);
  const [selectedResourceType, setSelectedResourceType] = useState(
    defaultRessourceType
  );
  const [selectedSubject, setSelectedSubject] = useState(defaultSubject);
  const [subjectSelectorState, setSubjectSelctorState] = useState(
    subjectSelector
  );

  const focusAfterVisibleRef = useRef();
  const firstUpdate = useRef(true);

  const handleSubmit = e => {
    e?.preventDefault();

    setSubjectSelctorState({
      ...subjectSelector,
      options:
        selectedTargetGroup.categoryKey === 'alle'
          ? subjectSelector.options
          : subjectSelector.options.filter(option =>
              subjectsOptionsPerTargetGroup[
                selectedTargetGroup.categoryKey
              ].some(someValue => someValue === option.value)
            )
    });
    let payload = {
      targetGroups: selectedTargetGroup,
      subject: selectedSubject,
      resourceType: selectedResourceType
    };
    onSearch(searchText, payload);
  };

  const handleTargetGroupsChange = category => {
    setSelectedTargetGroup(category);
  };

  const handleResetFilters = () => {
    setSelectedTargetGroup({
      categoryKey: 'alle',
      category: 'Alle'
    });
    setSelectedResourceType();
    setSelectedSubject();
  };

  useEffect(() => {
    if (isFilterMenuOpen && focusAfterVisibleRef.current)
      focusAfterVisibleRef.current.focus();
  }, [isFilterMenuOpen]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    handleSubmit();
  }, [selectedTargetGroup, selectedResourceType, selectedSubject]);

  return (
    <form
      className={cn('er-her-search-and-filter', {
        'er-her-search-and-filter--open': isFilterMenuOpen
      })}
      role="search"
      onSubmit={handleSubmit}
    >
      <div className="er-her-search-and-filter__search-wrapper">
        <SearchField
          theme={SearchField.themes.erHer}
          {...search}
          searchText={searchText}
          onSearchTextChange={e => setSearchText(e.target.value)}
        />
        <div className="er-her-search-and-filter__active-filter-list-wrapper">
          <Button
            type="button"
            className="er-her-search-and-filter__reset-filters-button"
            onClick={handleResetFilters}
            text={resetFiltersText}
          />
        </div>
      </div>
      <div
        id="filter-menu-wrapper"
        className="er-her-search-and-filter__filter-menu"
      >
        <CategoryFilter
          activeFilter={selectedTargetGroup}
          onChange={handleTargetGroupsChange}
          {...targetGroupFilter}
          focusRef={focusAfterVisibleRef}
        />
        <div className="er-her-search-and-filter__selectors">
          <Select
            className="er-her-search-and-filter__selector-filter"
            theme={Select.themes.erher}
            selectedId={selectedSubject}
            onChange={setSelectedSubject}
            {...subjectSelectorState}
          />
          <Select
            className="er-her-search-and-filter__selector-filter"
            theme={Select.themes.erher}
            selectedId={selectedResourceType}
            onChange={setSelectedResourceType}
            {...resourceTypeSelector}
          />
        </div>
      </div>
      <Button
        className="er-her-search-and-filter__filter-menu-toggle"
        onClick={() => setIsFilterMenuOpen(prevValue => !prevValue)}
        aria-expanded={isFilterMenuOpen}
        aria-controls="filter-menu-wrapper"
      >
        {isFilterMenuOpen ? (
          <>
            <MinusIcon /> {filterClosedButtonText}
          </>
        ) : (
          <>
            <PlusIcon /> {filterOpenButtonText}
          </>
        )}
      </Button>
    </form>
  );
};

ErHerSearchAndFilter.propTypes = {
  defaultTargetGroup: PropTypes.exact({
    category: PropTypes.string,
    categoryKey: PropTypes.oneOf(categoryKeys)
  }),
  defaultRessourceType: PropTypes.string,
  defaultSubject: PropTypes.string,
  defaultSearchText: PropTypes.string,
  resetFiltersText: PropTypes.string,
  search: PropTypes.exact(SearchField.propTypes),
  filterOpenButtonText: PropTypes.string,
  filterClosedButtonText: PropTypes.string,
  targetGroupFilter: PropTypes.exact(CategoryFilter.propTypes),
  resourceTypeSelector: PropTypes.exact(Select.propTypes),
  subjectSelector: PropTypes.exact(Select.propTypes),
  subjectsOptionsPerTargetGroup: PropTypes.exact({
    barn: PropTypes.arrayOf(PropTypes.string),
    trinn14: PropTypes.arrayOf(PropTypes.string),
    trinn57: PropTypes.arrayOf(PropTypes.string),
    trinn810: PropTypes.arrayOf(PropTypes.string),
    vgs: PropTypes.arrayOf(PropTypes.string),
    voksen: PropTypes.arrayOf(PropTypes.string)
  }),
  onSearch: PropTypes.func
};

export default ErHerSearchAndFilter;
