import React from 'react';
import PropTypes from 'prop-types';
import ErherIconFromKey from 'components/erher-icon-from-key/erher-icon-from-key';
import cn from 'classnames';

const CategoryFilter = ({
  activeFilter,
  groupLabelHidden,
  categories,
  onChange,
  focusRef,
  radioGroupName
}) => {
  const handleOnChange = (e, category) => {
    onChange(category, e.target.checked);
  };

  return (
    <fieldset className="category-filter" tabIndex={-1} ref={focusRef}>
      <legend className="category-filter__group-label">
        {groupLabelHidden}
      </legend>
      {categories &&
        categories.map(({ category, categoryKey }) => {
          let checked = activeFilter?.categoryKey === categoryKey;
          return (
            <div className="category-filter__option-wrapper" key={categoryKey}>
              <label
                className={cn('category-filter__filter-option', {
                  'category-filter__filter-option--active': checked
                })}
                htmlFor={categoryKey}
              >
                <ErherIconFromKey
                  className="category-filter__icon"
                  iconKey={categoryKey}
                  active={checked}
                />
                <ErherIconFromKey
                  className="category-filter__icon category-filter__icon--hover"
                  iconKey={categoryKey}
                  hover={true}
                />
                <div className="category-filter__label-text">{category}</div>
              </label>
              <input
                id={categoryKey}
                type="radio"
                className="category-filter__checkbox"
                name={radioGroupName}
                value={categoryKey}
                checked={checked}
                onChange={e => handleOnChange(e, { category, categoryKey })}
              />
            </div>
          );
        })}
    </fieldset>
  );
};

CategoryFilter.propTypes = {
  activeFilter: PropTypes.exact({
    category: PropTypes.string,
    categoryKey: PropTypes.string
  }),
  radioGroupName: PropTypes.string,
  groupLabelHidden: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.exact({
      category: PropTypes.string,
      categoryKey: PropTypes.string
    })
  ),
  onChange: PropTypes.func,
  focusRef: PropTypes.shape({})
};

CategoryFilter.propTypesMeta = {
  activeFilter: 'exclude',
  focusRef: 'exclude'
};

export default CategoryFilter;
