import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout/layout';
import ComponentList from 'components/component-list/component-list';
import RichText from 'components/rich-text/rich-text';
import ResourceBlock from 'components/resource-block/resource-block';
import CampaignBlock from 'components/campaign-block/campaign-block';
import ArticleHeader from 'components/article-header/article-header';
import BylineBlock from 'components/byline-block/byline-block';
import TableOfContents from 'components/table-of-contents/table-of-contents';
import SupportMaterials from 'components/support-materials/support-materials';
import Image from 'components/image/image';
import Link from 'components/link/link';
import ContentContainer from 'components/content-container/content-container';
import { ReactComponent as Chevron } from '../../assets/icons/icon-chevron-left.svg';
import cn from 'classnames';

const BigDocument = ({
  body,
  afterContent,
  layout,
  header,
  byline,
  tableOfContents,
  leftImage,
  supportingMaterial,
  pageNrText,
  prevLink,
  nextLink,
  printOnLoad
}) => {
  useEffect(() => printOnLoad && window.print(), []);

  return (
    <Layout {...layout}>
      <div className="big-document">
        <div className="big-document__header">
          {header && <ArticleHeader {...header} />}
          {byline && <BylineBlock {...byline} />}
        </div>
        <div className="big-document__left-column">
          {leftImage && (
            <Image className="big-document__image" {...leftImage} />
          )}
          {tableOfContents && <TableOfContents {...tableOfContents} />}
        </div>
        {body && (
          <div className="big-document__body">
            <ComponentList
              additionalComponentProps={{
                RichText: { themes: [RichText.themes.article] },
                ResourceBlock: {
                  containerTheme: ResourceBlock.containerThemes.article
                },
                CampaignBlock: {
                  containerTheme: CampaignBlock.containerThemes.article
                },
                CampaignItemGroupBlock: {
                  containerTheme: CampaignBlock.containerThemes.article
                }
              }}
              {...body}
            />
            <ComponentList
              themes={[ComponentList.themes.margins]}
              additionalComponentProps={{
                RichText: { themes: [RichText.themes.article] },
                ResourceBlock: {
                  containerTheme: ContentContainer.themes.article
                },
                CampaignBlock: {
                  containerTheme: ContentContainer.themes.footer
                }
              }}
              {...afterContent}
            />
            <ContentContainer themes={[ContentContainer.themes.article]}>
              <div className="big-document__page-number">
                <p className="big-document__page-number-text">{pageNrText}</p>
              </div>
              <nav
                aria-label="Sidenavigasjon"
                className={cn('big-document__navigation', {
                  'big-document__navigation--first-page': !prevLink
                })}
              >
                {prevLink && (
                  <a className="big-document__prev-link" href={prevLink.href}>
                    <span className="big-document__prev-icon">
                      <Chevron />
                    </span>
                    {prevLink.text}
                  </a>
                )}
                {nextLink && (
                  <a className="big-document__next-link" href={nextLink.href}>
                    {nextLink.text}
                    <span className="big-document__next-icon">
                      <Chevron />
                    </span>
                  </a>
                )}
              </nav>
            </ContentContainer>
          </div>
        )}
        {supportingMaterial && (
          <div className="big-document__supporting-block">
            <SupportMaterials {...supportingMaterial} />
          </div>
        )}
      </div>
    </Layout>
  );
};

BigDocument.propTypes = {
  body: PropTypes.exact(ComponentList.propTypes),
  afterContent: PropTypes.exact(ComponentList.propTypes),
  layout: PropTypes.exact(Layout.propTypes),
  header: PropTypes.exact(ArticleHeader.propTypes),
  byline: PropTypes.exact(BylineBlock.propTypes),
  tableOfContents: PropTypes.exact(TableOfContents.propTypes),
  leftImage: PropTypes.exact(Image.propTypes),
  supportingMaterial: PropTypes.exact(SupportMaterials.propTypes),
  pageNrText: PropTypes.string,
  prevLink: PropTypes.exact(Link.propTypes),
  nextLink: PropTypes.exact(Link.propTypes),
  printOnLoad: PropTypes.bool
};

export default BigDocument;
