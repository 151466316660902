import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import arrayOfObjectValues from 'js/prop-types-helpers/array-of-object-values';

const themes = {
  noMargins: 'heading--no-margins',
  slimFont: 'heading--slim-font',
  big: 'heading--big',
  thickFont: 'heading--thick-font',
  centerAligned: 'heading--center-aligned',
  erHer: 'heading--er-her'
};

const Heading = ({ children, className, level, themes, visualLevel, id }) =>
  !children
    ? null
    : React.createElement(
        `h${level}`,
        {
          id: id,
          className: cn(
            'heading',
            {
              [`heading--level-${visualLevel}`]: visualLevel
            },
            className,
            themes
          )
        },
        children
      );

const headingLevels = [1, 2, 3, 4, 5, 6];

Heading.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  level: PropTypes.oneOf(headingLevels),
  themes: arrayOfObjectValues(themes),
  visualLevel: PropTypes.oneOf(headingLevels),
  id: PropTypes.string
};

Heading.defaultProps = {
  level: 2,
  themes: []
};

Heading.propTypesMeta = 'exclude';

Heading.themes = themes;

export default Heading;
