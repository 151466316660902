import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image/image';

const ArticleElement = ({ image, name, url, firstArticleRef }) => {
  return (
    <a className="article-element" href={url} ref={firstArticleRef}>
      <div className="article-element__image-wrapper">
        <Image className="article-element__image" alt="" {...image} />
      </div>
      <div className="article-element__text">{name}</div>
    </a>
  );
};

ArticleElement.propTypes = {
  image: PropTypes.exact(Image.propTypes),
  name: PropTypes.string,
  url: PropTypes.string,
  id: PropTypes.string,
  firstArticleRef: PropTypes.any
};

ArticleElement.propTypesMeta = {
  firstArticleRef: 'exclude'
};

export default ArticleElement;
