import React from 'react';
import PropTypes from 'prop-types';
import ContentContainer from 'components/content-container/content-container';
import cn from 'classnames';

//It seems that the hp5 embeds that are used here automatically adjust their height. This only works on the same domain for security reasons.
const EmbededContentBlock = ({
  src,
  description,
  containerTheme,
  hasVideoAspectRatio,
  height
}) => {
  return (
    <ContentContainer
      className={cn('embeded-content-block', {
        'embeded-content-block--video-aspect': hasVideoAspectRatio
      })}
      themes={[containerTheme]}
    >
      <iframe
        className="embeded-content-block__element"
        src={src}
        aria-label={description}
        frameborder="0"
        allowfullscreen="allowfullscreen"
        allow="fullscreen"
        height={height}
      />
    </ContentContainer>
  );
};

EmbededContentBlock.propTypes = {
  src: PropTypes.string,
  description: PropTypes.string,
  containerTheme: PropTypes.string,
  hasVideoAspectRatio: PropTypes.bool,
  height: PropTypes.number
};

EmbededContentBlock.propTypesMeta = {
  containerTheme: 'exclude'
};

export default EmbededContentBlock;
