import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Heading from 'components/heading/heading';
import Link from 'components/link/link';
import Button from 'components/button/button';

import cn from 'classnames';

const inputTypes = ['text', 'email', 'tel'];
const autoCompleteTypes = [
  'organization',
  'street-address',
  'postal-code',
  'address-level2',
  'name',
  'email',
  'tel'
];

const fieldNames = [
  'Skole',
  'Adr',
  'Postnr',
  'Poststed',
  'Kontakt',
  'Email',
  'Tlf',
  'Elev'
];

const OrderingForm = ({
  heading,
  fields,
  cancelLink,
  orderButtonText,
  returnLink,
  additionalData
}) => {
  return (
    <div className="ordering-form">
      <Heading level={2}>{heading}</Heading>
      {fields &&
        fields.map(({ id, label, type }) => {
          const [isInvalid, setIsInvalid] = useState(false);
          return (
            <div
              className={cn('ordering-form__field-wrapper', {
                'ordering-form__field-wrapper--invalid': isInvalid
              })}
              key={id}
            >
              <label className="ordering-form__field-label" htmlFor={id}>
                {label}
              </label>
              <input
                id={id}
                name={id}
                className="ordering-form__field"
                type={type}
                required="required"
                autoComplete={
                  fieldNames.indexOf(id) < autoCompleteTypes.length
                    ? autoCompleteTypes[fieldNames.indexOf(id)]
                    : null
                }
                onInvalid={() => setIsInvalid(true)}
                onInput={() => setIsInvalid(false)}
              />
            </div>
          );
        })}
      {additionalData &&
        additionalData.map(({ id }) => (
          <input
            key={id}
            hidden
            type="checkbox"
            name="orderedAids"
            value={id}
            checked={true}
            readOnly={true}
          />
        ))}
      <div className="ordering-form__action-group">
        {cancelLink && (
          <Link className="ordering-form__cancel" {...cancelLink} />
        )}
        {orderButtonText && (
          <Button
            type="submit"
            value="Send"
            name="Cmd"
            themes={[Button.themes.red]}
          >
            {orderButtonText}
          </Button>
        )}
        {returnLink && (
          <Link themes={[Link.themes.redButton]} {...returnLink} />
        )}
      </div>
    </div>
  );
};

OrderingForm.propTypes = {
  heading: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.oneOf(fieldNames),
      label: PropTypes.string,
      type: PropTypes.oneOf(inputTypes)
    })
  ),
  cancelLink: PropTypes.exact(Link.propTypes),
  orderButtonText: PropTypes.string,
  returnLink: PropTypes.exact(Link.propTypes),
  additionalData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string
    })
  )
};

OrderingForm.propTypesMeta = {
  additionalData: 'exclude'
};

export default OrderingForm;
