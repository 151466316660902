import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ContentContainer from 'components/content-container/content-container';
import Button from 'components/button/button';
import Link from 'components/link/link';

import { ReactComponent as PrintIcon } from '../../assets/icons/icon-print.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/icon-download.svg';

import cn from 'classnames';
import useClickOutside from 'hooks/use-click-outside';

const BylineBlock = ({
  articleType,
  publishDate,
  printButtonText,
  printPageText,
  printFullPageLink,
  downloadButtonText,
  downloadLinks,
  downloadPDFLink,
  magazineTheme
}) => {
  const [printDropdownOpen, setPrintDropdownOpen] = useState(false);
  const [downloadDropdownOpen, setDownloadDropdownOpen] = useState(false);

  const printRef = useRef();
  const downloadRef = useRef();
  useClickOutside(printRef, () => setPrintDropdownOpen(false));
  useClickOutside(downloadRef, () => setDownloadDropdownOpen(false));

  const handlePrint = () => {
    if (printPageText || printFullPageLink) {
      setPrintDropdownOpen(!printDropdownOpen);
    } else {
      window.print();
    }
  };

  return (
    <ContentContainer
      lang="nb"
      className={cn('byline-block', {
        'byline-block--magazine': magazineTheme
      })}
    >
      <span className="byline-block__info">
        {articleType && <p className="byline-block__type">{articleType}</p>}
        {publishDate && <p className="byline-block__date">{publishDate}</p>}
      </span>
      <span className="byline-block__actions">
        {downloadPDFLink && (
          <Link
            className="byline-block__action-wrapper"
            themes={[Link.themes.grayButton]}
            {...downloadPDFLink}
          >
            <span className="byline-block__button-icon">
              <DownloadIcon />
            </span>
          </Link>
        )}
        {printButtonText && (
          <div
            className={cn('byline-block__action-wrapper', {
              'byline-block__action-wrapper--open': printDropdownOpen
            })}
            ref={printRef}
          >
            <Button
              className="byline-block__button"
              themes={[Button.themes.gray, Button.themes.small]}
              onClick={handlePrint}
              aria-expanded={printDropdownOpen}
              aria-controls="dropdown-print-options"
            >
              <span className="byline-block__button-icon">
                <PrintIcon />
              </span>
              <span className="byline-block__button-text">
                {printButtonText}
              </span>
            </Button>
            {(printPageText || printFullPageLink) && (
              <ul
                id="dropdown-print-options"
                className="byline-block__dropdown"
              >
                <li>
                  <Button
                    themes={[Button.themes.link]}
                    onClick={() => window.print()}
                  >
                    {printPageText}
                  </Button>
                </li>
                <li>
                  <Link {...printFullPageLink} />
                </li>
              </ul>
            )}
          </div>
        )}
        {downloadButtonText && (
          <div
            className={cn('byline-block__action-wrapper', {
              'byline-block__action-wrapper--open': downloadDropdownOpen
            })}
            ref={downloadRef}
          >
            <>
              <Button
                className="byline-block__button"
                themes={[Button.themes.gray, Button.themes.small]}
                onClick={() => setDownloadDropdownOpen(!downloadDropdownOpen)}
                aria-expanded={downloadDropdownOpen}
                aria-controls="dropdown-download-links"
              >
                <span className="byline-block__button-icon">
                  <DownloadIcon />
                </span>
                <span className="byline-block__button-text">
                  {downloadButtonText}
                </span>
              </Button>
              {downloadLinks && downloadLinks.length > 0 && (
                <ul
                  id="dropdown-download-links"
                  className="byline-block__dropdown"
                >
                  {downloadLinks &&
                    downloadLinks.map((link, i) => (
                      <li key={i}>
                        <Link {...link} />
                      </li>
                    ))}
                </ul>
              )}
            </>
          </div>
        )}
      </span>
    </ContentContainer>
  );
};

BylineBlock.propTypes = {
  articleType: PropTypes.string,
  publishDate: PropTypes.string,
  printButtonText: PropTypes.string,
  downloadButtonText: PropTypes.string,
  magazineTheme: PropTypes.bool,
  printPageText: PropTypes.string,
  printFullPageLink: PropTypes.exact(Link.propTypes),
  downloadLinks: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  downloadPDFLink: PropTypes.exact(Link.propTypes)
};

export default BylineBlock;
