import React from "react";
import PropTypes from "prop-types";

import Image from "components/image";
import ContentContainer from "components/content-container";

const DecoratedArticleImageBlock = ({ image, text }) => {
  return (
    <div className="decorated-article-image-block">
      <ContentContainer
        className="decorated-article-image-block__container"
        themes={[ContentContainer.themes.articleOverflow]}
      >
        <figure>
          <div className="decorated-article-image-block__image-wrapper">
            <Image
              {...image}
              className="decorated-article-image-block__image"
            />
          </div>
          <figcaption className="decorated-article-image-block__text">
            {text}
          </figcaption>
        </figure>
      </ContentContainer>
    </div>
  );
};

DecoratedArticleImageBlock.propTypes = {
  image: PropTypes.exact(Image.propTypes).isRequired,
  text: PropTypes.string
};

export default DecoratedArticleImageBlock;
