import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Heading from 'components/heading';
import ContentContainer from 'components/content-container';

const containerThemes = {
  footer: ContentContainer.themes.footer,
  content: ContentContainer.themes.content
};

const ProcessSectionBlock = ({
  anchorId,
  steps,
  headerText,
  containerTheme,
  gray,
  withNumbers
}) => {
  return (
    <div
      className={cn('process-section-block', {
        'process-section-block--gray': gray
      })}
      id={anchorId}
    >
      <ContentContainer themes={[containerThemes[containerTheme]]}>
        <div className="process-section-block__header">
          <Heading
            level={2}
            themes={[Heading.themes.noMargins]}
            className="process-section-block__title"
          >
            {headerText}
          </Heading>
        </div>
        <ol
          className={cn('process-section-block__steps', {
            'process-section-block__steps--with-numbers': withNumbers
          })}
        >
          {steps.map(({ isCurrent, href, text }, index) => {
            return (
              <li
                key={index}
                className={cn('process-section-block__step', {
                  'process-section-block__step--active': isCurrent
                })}
              >
                <a
                  href={href}
                  className="process-section-block__link"
                  aria-current={isCurrent ? 'page' : null}
                >
                  <span className="process-section-block__link-text">
                    {text}
                  </span>
                </a>
              </li>
            );
          })}
        </ol>
      </ContentContainer>
    </div>
  );
};

ProcessSectionBlock.propTypes = {
  anchorId: PropTypes.string,
  headerText: PropTypes.string,
  steps: PropTypes.arrayOf(
    PropTypes.exact({
      text: PropTypes.string,
      href: PropTypes.string,
      isCurrent: PropTypes.bool
    })
  ),
  containerTheme: PropTypes.string,
  gray: PropTypes.bool,
  withNumbers: PropTypes.bool
};

ProcessSectionBlock.propTypesMeta = {
  containerTheme: 'exclude',
  gray: 'exclude'
};

ProcessSectionBlock.defaultProps = {
  steps: [],
  containerTheme: ContentContainer.themes.content,
  withNumbers: false
};

ProcessSectionBlock.containerThemes = containerThemes;

export default ProcessSectionBlock;
