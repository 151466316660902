import React from 'react';
import PropTypes from 'prop-types';

const CtaLinksBlock = ({ links }) => {
  return (
    <div className="cta-links-block">
      {links.map(({ href, text }) => (
        <a key={href} className="cta-links-block__link" href={href}>
          <div className="cta-links-block__text">{text}</div>
        </a>
      ))}
    </div>
  );
};

CtaLinksBlock.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.exact({
      href: PropTypes.string,
      text: PropTypes.string
    })
  )
};

export default CtaLinksBlock;
