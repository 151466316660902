import React from 'react';
import PropTypes from 'prop-types';

import RichText from 'components/rich-text/rich-text';

const ErHerFooter = ({ firstFooterText, secondFooterText }) => {
  return (
    <footer className="er-her-footer" data-footer>
      <div className="er-her-footer__row">
        <div className="er-her-footer__column">
          <RichText
            themes={[RichText.themes.listStyleReset, RichText.themes.darkLinks]}
            html={firstFooterText}
          />
        </div>
        <div className="er-her-footer__column er-her-footer__column-right">
          <RichText
            themes={[RichText.themes.listStyleReset, RichText.themes.darkLinks]}
            html={secondFooterText}
          />
        </div>
      </div>
    </footer>
  );
};

ErHerFooter.propTypes = {
  firstFooterText: PropTypes.string,
  secondFooterText: PropTypes.string
};

export default ErHerFooter;
