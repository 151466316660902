import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Checkmark } from '../../assets/icons/icon-checkmark.svg';

const CheckBox = ({ checked, label, id }) => {
  return (
    <div className="checkbox">
      <input
        className="checkbox__checkbox"
        type="checkbox"
        name="f"
        id={id}
        value={id}
        defaultChecked={checked}
      />
      <div>
        <label className="checkbox__option-label" htmlFor={id}>
          <span className="checkbox__checkmark">
            <Checkmark />
          </span>
          {label}
        </label>
      </div>
    </div>
  );
};

CheckBox.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string
};

CheckBox.defaultProps = {
  checked: false
}

export default CheckBox;
