import React from "react";
import PropTypes from "prop-types";
import ContentContainer from "components/content-container/content-container";
import Heading from "components/heading/heading";
import cn from "classnames";
import RichText from "components/rich-text/rich-text";

const themes = {
  beige: "beige",
  beigelight: "beigelight",
  beigelighter: "beigelighter"
};

const FactsBlock = ({ heading, htmlContent, theme }) => {
  return (
    <aside className={cn("facts-block", `facts-block--${theme}`)}>
      <ContentContainer
        themes={[ContentContainer.themes.article]}
        className="facts-block__content"
      >
        <Heading className="facts-block__heading" level={2}>
          {heading}
        </Heading>
        <RichText className="facts-block__text" html={htmlContent} />
      </ContentContainer>
    </aside>
  );
};

FactsBlock.defaultProps = {
  theme: themes.beige
};

FactsBlock.propTypes = {
  theme: PropTypes.oneOf(Object.keys(themes)),
  heading: PropTypes.string,
  htmlContent: PropTypes.string
};

FactsBlock.themes = themes;

export default FactsBlock;
