import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link/link';
import Button from 'components/button/button';
import { ReactComponent as ArrowDown } from '../../assets/icons/icon-arrow-down.svg';
import cn from 'classnames';

const TableOfContentsItem = ({
  chapterLink,
  chapterList,
  toggleButtonText,
  current,
  open,
  level
}) => {
  const [isOpen, setIsOpen] = useState(open);

  const contentId = chapterLink.text.replace(/\s+/g, '');

  return (
    <li
      className={cn(
        'table-of-contents-item',
        `table-of-contents-item--level-${level}`,
        { 'table-of-contents-item--current-chapter': current },
        { 'table-of-contents-item--open': isOpen }
      )}
    >
      {!chapterList || chapterList <= 0 ? (
        <Link
          className="table-of-contents-item__chapter-link"
          themes={[Link.themes.noUnderline]}
          disabled={current}
          {...chapterLink}
        />
      ) : (
        <>
          <div className="table-of-contents-item__chapter">
            <Link
              className="table-of-contents-item__chapter-link"
              themes={[Link.themes.noUnderline]}
              disabled={current}
              {...chapterLink}
            />
            <Button
              className="table-of-contents-item__toggle-button"
              text={toggleButtonText}
              aria-expanded={isOpen}
              aria-controls={contentId}
              textIsHidden={true}
              onClick={() => setIsOpen(!isOpen)}
            >
              <ArrowDown />
            </Button>
          </div>
          <ul
            className="table-of-contents-item__sub-chapter-list"
            id={contentId}
          >
            {chapterList.map(chapter => (
              <TableOfContentsItem
                key={chapter.chapterLink.text}
                level={level + 1}
                {...chapter}
              />
            ))}
          </ul>
        </>
      )}
    </li>
  );
};

TableOfContentsItem.propTypes = {
  chapterLink: PropTypes.exact(Link.propTypes),
  toggleButtonText: PropTypes.string,
  current: PropTypes.bool,
  open: PropTypes.bool,
  level: PropTypes.number
};

TableOfContentsItem.propTypes.chapterList = PropTypes.arrayOf(
  PropTypes.exact(TableOfContentsItem.propTypes)
);

TableOfContentsItem.propTypesMeta = {
  toggleButtonText: 'exclude',
  level: 'exclude'
};

TableOfContentsItem.defaultProps = {
  chapterList: [],
  level: 1
};

export default TableOfContentsItem;
