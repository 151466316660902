import React from 'react';
import PropTypes from 'prop-types';

import serialize from 'form-serialize';

import analytics from 'js/analytics';
import apiHelper from 'js/api-helper';

import Button from 'components/button';
import ContentContainer from 'components/content-container';
import Heading from 'components/heading';
import HiddenInput from 'components/hidden-input';
import Radio from 'components/radio';

const Feedback = ({
  confirmationMessage,
  endpoint,
  inputLabel,
  inputName,
  inputPlaceholderNo,
  inputPlaceholderYes,
  maxReplyLength,
  optionNo,
  optionYes,
  pagePath,
  stage1,
  stage2,
  submitButtonText,
  title
}) => {
  const formRef = React.useRef();
  const [reply, setReply] = React.useState('');
  const [isComplete, setIsComplete] = React.useState();
  const [hasChoice, setHasChoice] = React.useState(false);
  const [choiceValue, setChoiceValue] = React.useState('');

  const serializeData = () => serialize(formRef.current, { hash: true });

  const onSubmit = e => {
    e.preventDefault();

    if (reply.length <= 2) {
      return;
    }

    analytics.send({ event: 'userFeedback', userFeedback: reply });
    apiHelper
      .execute(endpoint, serializeData())
      .then(() => setIsComplete(true));
  };

  const onRadioChange = e => {
    const { value } = e.target;
    setHasChoice(true);
    setChoiceValue(value);

    analytics.send({ event: 'userResponded', userResponse: value });
    apiHelper.execute(endpoint, serializeData());
  };

  /* In order to not appear in UDIR's search noindex is added as a class, see: https://jira.udir.no/browse/UDIRSOK-239 */
  return (
    <div className="feedback noindex">
      <ContentContainer>
        <form
          aria-labelledby="feed-back-title"
          action={endpoint}
          method="POST"
          onSubmit={onSubmit}
          className="feedback__content"
          ref={formRef}
        >
          <div className="feedback__confirmation" aria-live="polite">
            {isComplete && (
              <Heading themes={[Heading.themes.noMargins]}>
                {confirmationMessage}
              </Heading>
            )}
          </div>
          {!isComplete && (
            <React.Fragment>
              {pagePath && <HiddenInput {...pagePath} />}
              {stage1 && reply.length === 0 && <HiddenInput {...stage1} />}
              <div className="feedback__header">
                <Heading id="feed-back-title" className="feedback__heading">
                  {title}
                </Heading>
                <fieldset>
                  <legend>{title}</legend>
                  <div className="feedback__buttons">
                    <span className="feedback__button">
                      <Radio
                        onChange={onRadioChange}
                        themes={[Radio.themes.default]}
                        {...optionYes}
                      />
                    </span>
                    <span className="feedback__button">
                      <Radio
                        onChange={onRadioChange}
                        themes={[Radio.themes.default]}
                        {...optionNo}
                      />
                    </span>
                  </div>
                </fieldset>
              </div>
              {hasChoice && (
                <React.Fragment>
                  {stage2 && reply.length > 0 && <HiddenInput {...stage2} />}
                  <textarea
                    aria-label={inputLabel}
                    className="feedback__input"
                    maxLength={maxReplyLength}
                    name={inputName}
                    onChange={e => setReply(e.target.value)}
                    placeholder={
                      choiceValue === optionYes.value
                        ? inputPlaceholderYes
                        : inputPlaceholderNo
                    }
                  />
                  <div className="feedback__submit">
                    <div>{`${reply.length} / ${maxReplyLength}`}</div>
                    <Button
                      className="feedback__submit-button"
                      themes={[Button.themes.red, Button.themes.big]}
                      type="submit"
                    >
                      {submitButtonText}
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </form>
      </ContentContainer>
    </div>
  );
};

Feedback.propTypes = {
  confirmationMessage: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  inputLabel: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  inputPlaceholderNo: PropTypes.string.isRequired,
  inputPlaceholderYes: PropTypes.string.isRequired,
  maxReplyLength: PropTypes.number,
  optionNo: PropTypes.exact(Radio.propTypes).isRequired,
  optionYes: PropTypes.exact(Radio.propTypes).isRequired,
  pagePath: PropTypes.exact(HiddenInput.propTypes),
  stage1: PropTypes.exact(HiddenInput.propTypes),
  stage2: PropTypes.exact(HiddenInput.propTypes),
  submitButtonText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

Feedback.defaultProps = {
  maxReplyLength: 250
};

export default Feedback;
