import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { ReactComponent as Cross } from "../../assets/icons/icon-close-heavy.svg";

import Heading from "components/heading/heading";
import Image from "components/image/image";
import RichText from "components/rich-text/rich-text";
import SearchField from "components/search-field/search-field";
import Button from "components/button/button";
import Link from "components/link/link";
import SearchFilterList from "components/search-filter-list/search-filter-list";
import LinkList from "components/link-list/link-list";

const themes = {
  wide: "search-page-header--wide",
  narrow: "search-page-header--narrow"
};

const SearchPageHeader = ({
  searchURL,
  searchText,
  heading,
  introAsHtml,
  image,
  search,
  didYouMeanHeading,
  didYouMeanLinkList,
  searchSuggestionHeading,
  searchSuggestionLinkList,
  activeFiltersHeading,
  activeFilterLinks,
  audioBooksLink,
  brailleBooksLink,
  proposedFiltersHeading,
  proposedFilterLinks,
  filterList,
  openFilterButtonText,
  closeFilterButtonText,
  theme,
  listBelowTitle
}) => {
  const formRef = useRef();

  const onSearch = () => formRef.current.submit();

  const [searchTextState, setSearchTextState] = useState(
    searchText ? searchText : ""
  );
  const [filtersOpen, setFiltersOpen] = useState(false);

  return (
    <form
      aria-label="På denne siden"
      role="search"
      ref={formRef}
      action={searchURL}
      method="get"
      id="search"
      className={cn("search-page-header", theme)}
    >
      <div className="search-page-header__header-wrapper">
        <div className="search-page-header__content-wrapper">
          <div className="search-page-header__content">
            <Heading level={1} className="search-page-header__heading">
              {heading}
            </Heading>
            <RichText
              className="search-page-header__text"
              themes={[RichText.themes.darkLinks]}
              html={introAsHtml}
            />
            {listBelowTitle && (
              <LinkList
                className="search-page-header__list-below-title"
                linkThemes={[
                  Link.themes.neutralDark,
                  Link.themes.borderedThick
                ]}
                listStyleReset={false}
                {...listBelowTitle}
              />
            )}
            <div className="search-page-header__search-field-wrapper">
              <SearchField
                onSearch={onSearch}
                onSearchTextChange={e => setSearchTextState(e.target.value)}
                searchText={searchTextState}
                {...search}
              />
            </div>
            {didYouMeanHeading &&
              didYouMeanLinkList &&
              !!didYouMeanLinkList.length && (
                <div className="search-page-header__suggestion-wrapper">
                  <strong>{didYouMeanHeading}</strong>
                  <ul className="search-page-header__suggestion-link-list">
                    {didYouMeanLinkList.map(link => (
                      <li
                        className="search-page-header__suggestion-list-item"
                        key={link.text}
                      >
                        <Link themes={[Link.themes.neutralDark]} {...link} />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            {searchSuggestionHeading &&
              searchSuggestionLinkList &&
              !!searchSuggestionLinkList.length && (
                <div className="search-page-header__suggestion-wrapper">
                  <strong>{searchSuggestionHeading}</strong>
                  <ul className="search-page-header__suggestion-link-list">
                    {searchSuggestionLinkList.map(link => (
                      <li
                        className="search-page-header__suggestion-list-item"
                        key={link.text}
                      >
                        <Link themes={Link.themes.neutralDark} {...link} />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            {activeFiltersHeading && (
              <Heading
                level={2}
                className="search-page-header__active-filters-heading"
              >
                {activeFiltersHeading}
              </Heading>
            )}
            {activeFilterLinks && (
              <ul className="search-page-header__active-filter-list">
                {activeFilterLinks.map(({ href, text }) => (
                  <li
                    key={text + href}
                    className="search-page-header__active-filter"
                  >
                    <a
                      className="search-page-header__active-filter-remove"
                      href={href}
                    >
                      <span className="search-page-header__active-filter-text">
                        {text}
                      </span>
                      <div className="search-page-header__cross">
                        <Cross />
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            )}
            {audioBooksLink && (
              <div>
                <Link themes={[Link.themes.neutralDark]} {...audioBooksLink} />
              </div>
            )}
            {brailleBooksLink && (
              <div>
                <Link
                  themes={[Link.themes.neutralDark]}
                  {...brailleBooksLink}
                />
              </div>
            )}
            {proposedFiltersHeading && (
              <Heading
                level={2}
                className="search-page-header__proposed-filters-heading"
              >
                {proposedFiltersHeading}
              </Heading>
            )}
            {proposedFilterLinks && (
              <ul className="search-page-header__proposed-filter-list">
                {proposedFilterLinks.map(link => (
                  <li key={link.text}>
                    <Link
                      className="search-page-header__proposed-filter"
                      themes={[Link.themes.whiteButton]}
                      {...link}
                    />
                  </li>
                ))}
              </ul>
            )}
            <Button
              className={cn("search-page-header__filter-toggle", {
                "search-page-header__filter-toggle--active": filtersOpen
              })}
              onClick={() => setFiltersOpen(!filtersOpen)}
              aria-expanded={filtersOpen}
            >
              <span className="search-page-header__toggle-text">
                {filtersOpen ? closeFilterButtonText : openFilterButtonText}
              </span>
            </Button>
          </div>
        </div>
        {image && (
          <div className="search-page-header__image-part">
            <div className="search-page-header__image-wrapper">
              <Image className="search-page-header__image" {...image} />
            </div>
          </div>
        )}
      </div>

      {filterList && (
        <div className="teaching-aids-header__filter-wrapper">
          <SearchFilterList {...filterList} open={filtersOpen} />
        </div>
      )}
    </form>
  );
};

SearchPageHeader.propTypes = {
  search: PropTypes.exact(SearchField.propTypes),
  searchURL: PropTypes.string,
  searchText: PropTypes.string,
  heading: PropTypes.string,
  introAsHtml: PropTypes.string,
  didYouMeanHeading: PropTypes.string,
  didYouMeanLinkList: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  searchSuggestionHeading: PropTypes.string,
  searchSuggestionLinkList: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  activeFiltersHeading: PropTypes.string,
  activeFilterLinks: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  audioBooksLink: PropTypes.exact(Link.propTypes),
  brailleBooksLink: PropTypes.exact(Link.propTypes),
  proposedFiltersHeading: PropTypes.string,
  proposedFilterLinks: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  image: PropTypes.exact(Image.propTypes),
  filterList: PropTypes.exact(SearchFilterList.propTypes),
  openFilterButtonText: PropTypes.string,
  closeFilterButtonText: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes)),
  listBelowTitle: PropTypes.exact(LinkList.propTypes)
};

SearchPageHeader.propTypesMeta = {
  theme: "exclude"
};

SearchPageHeader.defaultProps = {
  activeFilterLinks: [],
  theme: themes.wide
};

SearchPageHeader.themes = themes;

export default SearchPageHeader;
