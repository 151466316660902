import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image/image';

const ResourceItem = ({ text, href, image }) => {
  return (
    <a className="resource-item" href={href}>
      <div className="resource-item__image-wrapper">
        <Image className="resource-item__image" {...image} />
      </div>
      <span className="resource-item__text">{text}</span>
    </a>
  );
};

ResourceItem.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  image: PropTypes.exact(Image.propTypes)
};

export default ResourceItem;
