import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button/button';
import TableOfContentsItem from 'components/table-of-contents-item/table-of-contents-item';
import cn from 'classnames';
import { ReactComponent as Close } from '../../assets/icons/icon-close.svg';
import { ReactComponent as Hamburger } from '../../assets/icons/icon-hamburger.svg';

const TableOfContents = ({ buttonText, chapterList, toggleButtonText }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenMobile, setIsOpenMobile] = useState(false);

  return (
    <div
      className={cn('table-of-contents', {
        'table-of-contents--mobile-open': isOpenMobile
      })}
    >
      <Button
        className="table-of-contents__open-button"
        aria-expanded={isOpen}
        aria-controls="table-of-contents"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? <Close /> : <Hamburger />}
        {buttonText}
      </Button>
      <Button
        className="table-of-contents__open-button-mobile"
        aria-expanded={isOpenMobile}
        aria-controls="table-of-contents"
        onClick={() => setIsOpenMobile(!isOpenMobile)}
      >
        {isOpenMobile ? <Close /> : <Hamburger />}
        {buttonText}
      </Button>
      <nav aria-label="Innholdsfortegnelse">
        <ul
          id="table-of-contents"
          className={cn('table-of-contents__chapter-list', {
            'table-of-contents__chapter-list--open': isOpen,
            'table-of-contents__chapter-list--open-mobile': isOpenMobile
          })}
        >
          {chapterList?.map(chapter => (
            <TableOfContentsItem
              key={chapter.chapterLink.text}
              toggleButtonText={toggleButtonText}
              level={1}
              {...chapter}
            />
          ))}
        </ul>
      </nav>
    </div>
  );
};

TableOfContents.propTypes = {
  buttonText: PropTypes.string,
  chapterList: PropTypes.arrayOf(
    PropTypes.exact(TableOfContentsItem.propTypes)
  ),
  toggleButtonText: PropTypes.string
};

export default TableOfContents;
