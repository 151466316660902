import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import ids from 'js/global-ids';
import Link from 'components/link';
import TabMenu from 'components/tab-menu';

import { ReactComponent as SearchIcon } from '../../assets/icons/icon-search.svg';
import { ReactComponent as Hamburger } from '../../assets/icons/icon-hamburger.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/icon-close.svg';
import { ReactComponent as ExternalLinkIcon } from '../../assets/icons/icon-link-out.svg';
import { ReactComponent as ExternalLinkHoverIcon } from '../../assets/icons/icon-link-out-hover.svg';
import { ReactComponent as LogoIcon } from '../../assets/icons/erher/statped-logo-black.svg';
import SearchField from 'components/search-field/search-field';
import Button from 'components/button/button';

const ErHerHeader = ({
  tabMenu,
  linkList,
  linkToHomeUrl,
  searchButtonTextOpen,
  searchButtonTextClose,
  menuButtonTextOpen,
  menuButtonTextClose,
  searchUrl,
  search
}) => {
  const [searchDrawerVisible, setSearchDrawerVisible] = useState(false);
  const [menuDrawerVisible, setMenuDrawerVisible] = useState(false);

  const handleSearchDrawerOpen = () => {
    setSearchDrawerVisible(!searchDrawerVisible);
    document.getElementById('searchformid').inert = searchDrawerVisible;
    document.getElementById(search.id).focus();
  };

  return (
    <React.Fragment>
      {tabMenu && <TabMenu {...tabMenu} />}
      <div
        className={cn('er-her-header', {
          'er-her-header--search': searchDrawerVisible,
          'er-her-header--navigation-open': menuDrawerVisible
        })}
      >
        <div className="er-her-header__background-gradient" />
        <header
          className="er-her-header__header"
          id={ids.goToTopTarget}
          tabIndex="-1"
        >
          <a className="er-her-header__logo" href={linkToHomeUrl}>
            <LogoIcon
              className="er-her-header__logo-icon"
              aria-label="Statped"
            />
            <span className="er-her-header__logo-subdomain">erher</span>
          </a>
          <div className="er-her-header__content">
            {linkList && (
              <span
                inert={searchDrawerVisible ? '' : null}
                aria-hidden={searchDrawerVisible}
              >
                <Button
                  className="er-her-header__menu-button"
                  text={
                    menuDrawerVisible ? menuButtonTextClose : menuButtonTextOpen
                  }
                  textIsHidden={!menuDrawerVisible}
                  onClick={() => setMenuDrawerVisible(prevValue => !prevValue)}
                  aria-expanded={menuDrawerVisible}
                >
                  {menuDrawerVisible ? <CloseIcon /> : <Hamburger />}
                </Button>
                <div className="er-her-header__navigation">
                  <nav>
                    <ul className="er-her-header__link-list">
                      {linkList.map(({ href, text }, i) => (
                        <li className="er-her-header__list-item" key={i}>
                          <Link
                            className="er-her-header__nav-link"
                            themes={[Link.themes.erherNavBar]}
                            href={href}
                          >
                            {text}
                            {i === 0 && (
                              <>
                                <ExternalLinkIcon
                                  className="er-her-header__external-link-icon"
                                  aria-label="Ekstern side"
                                />
                                <ExternalLinkHoverIcon
                                  className="er-her-header__external-link-icon er-her-header__external-link-icon--hover"
                                  aria-hidden="true"
                                />
                              </>
                            )}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </span>
            )}
            {searchButtonTextOpen && searchButtonTextClose && (
              <Button
                className="er-her-header__open-search-button"
                themes={[Button.themes.iconWithText]}
                onClick={handleSearchDrawerOpen}
                aria-expanded={searchDrawerVisible}
                inert={menuDrawerVisible ? '' : null}
                aria-hidden={menuDrawerVisible}
              >
                <span className="er-her-header__open-search-button-text">
                  {searchDrawerVisible
                    ? searchButtonTextClose
                    : searchButtonTextOpen}
                </span>
                {searchDrawerVisible ? (
                  <CloseIcon className="er-her-header__close-search-button-icon" />
                ) : (
                  <SearchIcon className="er-her-header__open-search-button-icon" />
                )}
              </Button>
            )}
          </div>
        </header>
        <div className="er-her-header__search-content">
          <form
            id="searchformid"
            action={searchUrl}
            inert={''}
            aria-hidden={!searchDrawerVisible}
            className="er-her-header__search-form"
            role="search"
          >
            <SearchField
              theme={SearchField.themes.erHer}
              labelVisible={true}
              {...search}
            />
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

ErHerHeader.propTypes = {
  tabMenu: PropTypes.exact(TabMenu.propTypes),
  searchButtonTextOpen: PropTypes.string,
  searchButtonTextClose: PropTypes.string,
  menuButtonTextOpen: PropTypes.string,
  menuButtonTextClose: PropTypes.string,
  searchUrl: PropTypes.string,
  search: PropTypes.exact(SearchField.propTypes),
  linkList: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  linkToHomeUrl: PropTypes.string.isRequired
};

export default ErHerHeader;
