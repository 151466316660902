import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Collapse from 'react-tiny-collapse';

import Heading from 'components/heading';
import ComponentList from 'components/component-list/component-list';
import { ReactComponent as Chevron } from '../../assets/icons/icon-chevron-left.svg';

const themes = {
  gray: 'gray'
};

const ProcessAccordion = ({
  id,
  contentList,
  headingLevel,
  triggerText,
  theme
}) => {
  const contentId = 'content-' + id;
  const contentRef = useRef();
  const triggerRef = useRef();

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    if (id) {
      !isOpen
        ? (location.hash = id)
        : history.pushState('', document.title, location.pathname);
    }
    setIsOpen(prev => !prev);
  };

  const checkIfAccordionIsTarget = () => {
    const currentAccordion = location.hash.replace('#', '');
    currentAccordion !== id && setIsOpen(false);
  };

  useEffect(() => {
    if (id && location.hash === '#' + id) {
      if (!isOpen) toggle();
    }
    if (location.hash !== '') {
      const noHashtag = location.hash.replace('#', '');
      const element = document.getElementById('content-' + noHashtag);
      if (element) element.scrollIntoView({ behavior: 'smooth' });
    }
    window.addEventListener('hashchange', () => {
      checkIfAccordionIsTarget();
    });
    return () => {
      window.removeEventListener('hashchange', () => {
        checkIfAccordionIsTarget();
      });
    };
  }, []);

  return (
    <div
      className={cn('process-accordion', {
        'process-accordion--open': isOpen
      })}
    >
      <Heading
        level={headingLevel}
        className="process-accordion__title"
        themes={[Heading.themes.noMargins]}
      >
        <button
          className={cn('process-accordion__trigger', {
            'process-accordion__trigger--active': isOpen
          })}
          aria-expanded={isOpen}
          aria-controls={contentId}
          onClick={toggle}
          ref={triggerRef}
        >
          <span className="process-accordion__trigger-content">
            {triggerText && (
              <span className="process-accordion__trigger-title">
                {triggerText}
              </span>
            )}
          </span>
          <Chevron className="process-accordion__icon" />
        </button>
      </Heading>
      <div
        id={contentId}
        className={cn('process-accordion__content-wrapper', {
          'process-accordion__content-wrapper--open': isOpen,
          [`process-accordion__content-wrapper--${themes[theme]}`]: themes[
            theme
          ]
        })}
        ref={contentRef}
        tabIndex="-1"
      >
        <Collapse className="process-accordion__collapse" isOpen={isOpen}>
          <div className="process-accordion__content">
            <ComponentList {...contentList} />
          </div>
        </Collapse>
      </div>
    </div>
  );
};

ProcessAccordion.propTypes = {
  id: PropTypes.string,
  headingLevel: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  triggerText: PropTypes.string,
  contentList: PropTypes.exact(ComponentList.propTypes),
  theme: PropTypes.string
};

ProcessAccordion.propTypesMeta = {
  headingLevel: 'exclude',
  theme: 'exclude'
};

ProcessAccordion.defaultProps = {
  headingLevel: 2
};

ProcessAccordion.themes = themes;

export default ProcessAccordion;
