import React from 'react';
import PropTypes from 'prop-types';

import Heading from 'components/heading';
import ContentContainer from 'components/content-container';

const HeadingBlock = ({ anchorId, text, level }) => {
  return (
    <ContentContainer themes={[ContentContainer.themes.article]}>
      <div className="heading-block" id={anchorId}>
        <Heading
          className="heading-block__heading"
          level={level}
          themes={[Heading.themes.noMargins]}
        >
          {text}
        </Heading>
      </div>
    </ContentContainer>
  );
};

HeadingBlock.propTypes = {
  anchorId: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  level: PropTypes.number
};

export default HeadingBlock;
