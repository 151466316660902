import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ComponentListItem from 'components/component-list-item';

import mapWithKey from 'js/map-with-key';
import arrayOfObjectValues from 'js/prop-types-helpers/array-of-object-values';

/*
  NOTE: additionalComponentProps can be used to send props to all components of a specific type.
  This only applies to the components rendered directly by ComponentListItem (in other words these props don't trickle all the way down the render tree)

  Example: forcing a 'blue' theme for all Links rendered by a ComponentList:
  <ComponentList additionalComponentProps={{ Link: { theme: Link.themes.blue } }} />
*/

const themes = {
  inline: 'inline',
  margins: 'margins'
};

const ComponentList = ({
  additionalComponentProps, // See above comment
  components,
  themes,
  componentNotSupportedText
}) =>
  components.length === 0 ? null : (
    <div
      className={cn(
        'component-list',
        themes.map(theme => `component-list--${theme}`)
      )}
    >
      {mapWithKey(components, ComponentListItem, index => ({
        additionalComponentProps: Object.assign({}, additionalComponentProps, {
          ListBlock: { index }
        }),
        componentNotSupportedText
      }))}
    </div>
  );

ComponentList.propTypes = {
  themes: arrayOfObjectValues(themes),
  additionalComponentProps: PropTypes.object,
  components: PropTypes.arrayOf(PropTypes.exact(ComponentListItem.propTypes)),
  componentNotSupportedText: PropTypes.string
};

ComponentList.propTypesMeta = {
  additionalComponentProps: 'exclude',
  themes: 'exclude'
};

ComponentList.defaultProps = {
  additionalComponentProps: {},
  components: [],
  themes: []
};

ComponentList.themes = themes;

export default ComponentList;
