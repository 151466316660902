import React from 'react';
import PropTypes from 'prop-types';
import ContentContainer from 'components/content-container/content-container';
import RichText from 'components/rich-text/rich-text';
import arrayOfObjectValues from 'js/prop-types-helpers/array-of-object-values';

const containerThemes = {
  article: ContentContainer.themes.article,
  content: ContentContainer.themes.content
};

const RichTextBlock = ({ html, containerTheme, richTextThemes }) => {
  return (
    <ContentContainer
      className="rich-text-block"
      themes={[containerThemes[containerTheme]]}
    >
      <RichText html={html} themes={richTextThemes ? richTextThemes : []} />
    </ContentContainer>
  );
};

RichTextBlock.propTypes = {
  html: PropTypes.string,
  containerTheme: PropTypes.string,
  richTextThemes: arrayOfObjectValues(RichText.themes)
};

RichTextBlock.propTypesMeta = {
  containerTheme: 'exclude',
  richTextThemes: 'exclude'
};

RichTextBlock.defaultProps = {
  containerTheme: ContentContainer.themes.article
};

RichTextBlock.containerThemes = containerThemes;

export default RichTextBlock;
