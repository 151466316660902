import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image/image';
import DownloadLinksDropdown from 'components/download-links-dropdown/download-links-dropdown';
import Link from 'components/link/link';

const ProductInfo = ({ downloadMenu, image, facts }) => {
  return (
    <div className="product-info">
      {image && <Image className="product-info__image" {...image} />}
      {downloadMenu && (
        <span className="product-info__download-button">
          <DownloadLinksDropdown
            themes={[DownloadLinksDropdown.themes.wide]}
            {...downloadMenu}
          />
        </span>
      )}
      {facts && facts.length > 0 && (
        <ol className="product-info__list">
          {facts.map(({ title, text, href, langTag }, index) => (
            <li key={title + index} className="product-info__fact">
              <b>{title}: </b>

              {href ? (
                <Link href={href} languageTag={langTag}>
                  {text}
                </Link>
              ) : (
                <span lang={langTag}>{text}</span>
              )}
            </li>
          ))}
        </ol>
      )}
    </div>
  );
};

ProductInfo.propTypes = {
  image: PropTypes.exact(Image.propTypes),
  downloadMenu: PropTypes.exact(DownloadLinksDropdown.propTypes),
  facts: PropTypes.arrayOf(
    PropTypes.exact({
      title: PropTypes.string,
      text: PropTypes.string,
      href: PropTypes.string,
      langTag: PropTypes.string
    })
  )
};

export default ProductInfo;
