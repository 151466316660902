import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import Collapse from "react-tiny-collapse";

import ContentContainer from "components/content-container/content-container";
import Heading from "components/heading/heading";
import RichText from "components/rich-text/rich-text";
import useToggle from "hooks/use-toggle";
import Image from "components/image/image";

import { ReactComponent as Chevron } from "../../assets/icons/icon-chevron-left.svg";
import Button from "components/button/button";

const ExpandableFactBlock = ({
  heading,
  headingLevel,
  subTitle,
  image,
  htmlContent,
  buttonText
}) => {
  const [isOpen, toggleIsOpen] = useToggle(false);

  return (
    <ContentContainer
      className={cn("expandable-fact-block", {
        "expandable-fact-block--no-image": !image
      })}
    >
      <div className="expandable-fact-block__header">
        {image && <Image className="expandable-fact-block__image" {...image} />}
        <div className="expandable-fact-block__heading-wrapper">
          <div>
            <Heading
              level={headingLevel}
              className="expandable-fact-block__heading"
              themes={[Heading.themes.noMargins]}
            >
              {heading}
            </Heading>
            <span className="expandable-fact-block__sub-heading">
              {subTitle}
            </span>
          </div>
          <Button
            className={cn("expandable-fact-block__trigger", {
              "expandable-fact-block__trigger--active": isOpen
            })}
            aria-expanded={isOpen}
            text={buttonText}
            textIsHidden={true}
            onClick={toggleIsOpen}
          >
            <Chevron />
          </Button>
        </div>
      </div>

      <Collapse className="expandable-fact-block__collapse" isOpen={isOpen}>
        <div className="expandable-fact-block__content">
          <p className="expandable-fact-block__sub-heading expandable-fact-block__sub-heading--small-view">
            {subTitle}
          </p>
          <RichText
            className="expandable-fact-block__text"
            html={htmlContent}
          />
        </div>
      </Collapse>
    </ContentContainer>
  );
};

ExpandableFactBlock.propTypes = {
  heading: PropTypes.string.isRequired,
  headingLevel: PropTypes.number,
  subTitle: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  htmlContent: PropTypes.string.isRequired,
  buttonText: PropTypes.string
};

export default ExpandableFactBlock;
