import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout/layout';
import ProcessHeader from 'components/process-header/process-header';
import ProcessAccordionBlock from 'components/process-accordion-block/process-accordion-block';
import Button from 'components/button/button';

import { ReactComponent as PrintIcon } from '../../assets/icons/icon-print.svg';
import ProcessSectionBlock from 'components/process-section-block/process-section-block';

const Process = ({
  header,
  layout,
  byline,
  processSteps,
  processAccordionBlockList
}) => {
  const [openKey, setOpenKey] = useState(null);

  const onToggle = key => {
    setOpenKey(openKey !== key ? key : null);
  };

  return (
    <Layout {...layout}>
      <div className="process">
        {header && <ProcessHeader {...header} />}
        {byline && (
          <div className="process__byline">
            <div className="process__byline-info">
              <span className="process__byline-info-title">
                {byline.info.title}
              </span>
              {byline.info.text}
            </div>
            {byline.action && (
              <Button
                className="process__action-button"
                onClick={() => window.print()}
              >
                <span className="process__action-icon">
                  <PrintIcon />
                </span>
                <span className="process__action-text">{byline.action}</span>
              </Button>
            )}
          </div>
        )}
        {processSteps && (
          <ProcessSectionBlock
            gray
            containerTheme={ProcessSectionBlock.containerThemes.content}
            {...processSteps}
          />
        )}
        {processAccordionBlockList &&
          processAccordionBlockList.map((processAccordionBlock, index) => (
            <ProcessAccordionBlock
              key={processAccordionBlock.title + index}
              mainOnToggle={onToggle}
              openKey={openKey}
              containerTheme={'content'}
              {...processAccordionBlock}
            />
          ))}
      </div>
    </Layout>
  );
};

Process.propTypes = {
  header: PropTypes.exact(ProcessHeader.propTypes),
  byline: PropTypes.exact({
    info: PropTypes.exact({
      title: PropTypes.string,
      text: PropTypes.string
    }),
    action: PropTypes.string
  }),
  processSteps: PropTypes.exact(ProcessSectionBlock.propTypes),
  processAccordionBlockList: PropTypes.arrayOf(
    PropTypes.exact(ProcessAccordionBlock.propTypes)
  ),
  layout: PropTypes.exact(Layout.propTypes)
};

export default Process;
