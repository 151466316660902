import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button/button';
import Image from 'components/image/image';
import cn from 'classnames';
import ContentContainer from 'components/content-container/content-container';

import { ReactComponent as PlayIcon } from '../../assets/icons/icon-play.svg';
import { ReactComponent as PauseIcon } from '../../assets/icons/icon-pause.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/icon-chevron-left.svg';

const ImageCarouselBlock = ({
  steps,
  autoplay,
  interval,
  liveRegionText,
  previousButtonLabel,
  nextButtonLabel,
  pauseButtonText,
  playButtonText,
  carouselDescription
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isPlaying, setIsPlaying] = useState(autoplay);
  const [carouselInterval, setCarouselInterval] = useState();
  const [isHalting, setIsHalting] = useState(false);

  const pause = () => {
    clearInterval(carouselInterval);
    setIsPlaying(false);
  };

  const goToNext = () => {
    setCurrentStep(currentStep === steps.length - 1 ? 0 : currentStep + 1);
    pause();
  };
  const goToPrevious = () => {
    setCurrentStep(currentStep < 1 ? steps.length - 1 : currentStep - 1);
    pause();
  };
  const goToIndex = index => {
    setCurrentStep(index);
    pause();
  };

  const togglePlay = () => {
    if (!isPlaying)
      !isHalting &&
        setCarouselInterval(
          setInterval(
            () =>
              setCurrentStep(prev =>
                prev === steps.length - 1 ? 0 : prev + 1
              ),
            interval
          )
        );
    else clearInterval(carouselInterval);
    setIsPlaying(!isPlaying);
  };

  const haltCarousel = () => {
    setIsHalting(true);
    clearInterval(carouselInterval);
    setCarouselInterval(undefined);
  };

  const restartCarousel = () => {
    setIsHalting(false);
    isPlaying &&
      !carouselInterval &&
      setCarouselInterval(
        setInterval(
          () =>
            setCurrentStep(prev => (prev === steps.length - 1 ? 0 : prev + 1)),
          interval
        )
      );
  };

  useEffect(() => {
    if (isPlaying) {
      const carousel = setInterval(
        () =>
          setCurrentStep(prev => (prev === steps.length - 1 ? 0 : prev + 1)),
        interval
      );
      setCarouselInterval(carousel);
    }
  }, []);

  const replaceLiveRegionText = (step, stepsTotal) =>
    liveRegionText.replace('$step', step).replace('$stepsTotal', stepsTotal);

  return (
    <ContentContainer
      className="image-carousel-block"
      themes={[ContentContainer.themes.article]}
    >
      <section
        onMouseEnter={haltCarousel}
        onMouseLeave={restartCarousel}
        onFocus={haltCarousel}
        onBlur={restartCarousel}
        aria-roledescription="carousel"
        aria-label={carouselDescription}
      >
        <div className="image-carousel-block__image-wrapper">
          {steps[currentStep].image && (
            <Image
              className="image-carousel-block__image"
              {...steps[currentStep].image}
            />
          )}
          <div className="image-carousel-block__previous-button-wrapper">
            <Button
              className="image-carousel-block__nav-button"
              onClick={goToPrevious}
              text={previousButtonLabel}
              textIsHidden={true}
            >
              <ArrowIcon />
            </Button>
          </div>
          <div className="image-carousel-block__next-button-wrapper">
            <Button
              className="image-carousel-block__nav-button"
              onClick={goToNext}
              text={nextButtonLabel}
              textIsHidden={true}
            >
              <ArrowIcon />
            </Button>
          </div>
        </div>

        <div className="image-carousel-block__content-wrapper">
          <span
            aria-live="polite"
            className="image-carousel-block__live-region"
          >
            {!isPlaying && replaceLiveRegionText(currentStep + 1, steps.length)}
          </span>
          <div className="image-carousel-block__controls">
            <button className="image-carousel-block__play" onClick={togglePlay}>
              {isPlaying ? <PauseIcon /> : <PlayIcon />}
              <span className="image-carousel-block__play-text">
                {isPlaying ? pauseButtonText : playButtonText}
              </span>
            </button>
            <ul className="image-carousel-block__step-list">
              {steps.map((step, index) => (
                <li
                  key={index}
                  className="image-carousel-block__step-list-item"
                >
                  <button
                    className={cn('image-carousel-block__step-list-button', {
                      'image-carousel-block__step-list-button--current':
                        currentStep === index
                    })}
                    onClick={() => goToIndex(index)}
                  >
                    <div className="image-carousel-block__step-circle">
                      <span className="image-carousel-block__step-nr">
                        {index + 1}
                      </span>
                    </div>
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <p className="image-carousel-block__image-text">
            {steps[currentStep].imageText}
          </p>
        </div>
      </section>
    </ContentContainer>
  );
};

ImageCarouselBlock.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.exact({
      imageText: PropTypes.string,
      image: PropTypes.exact(Image.propTypes)
    })
  ),
  autoplay: PropTypes.bool,
  interval: PropTypes.number,
  liveRegionText: PropTypes.string,
  previousButtonLabel: PropTypes.string,
  nextButtonLabel: PropTypes.string,
  pauseButtonText: PropTypes.string,
  playButtonText: PropTypes.string,
  carouselDescription: PropTypes.string
};

export default ImageCarouselBlock;
