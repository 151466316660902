import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image/image';
import ContentContainer from 'components/content-container/content-container';

const ImageComparisonBlock = ({ firstImage, secondImage, sliderLabel }) => {
  const ref = useRef();

  const onSliderChange = event => {
    ref.current.style.setProperty('--slider-pos', event.target.value + '%');
  };

  return (
    <ContentContainer>
      <div ref={ref} className="image-comparison-block">
        <div className="image-comparison-block__image-part">
          <div className="image-comparison-block__left-image-clip-box">
            <Image
              className="image-comparison-block__left-image"
              {...firstImage}
            />
          </div>
          <Image
            className="image-comparison-block__right-image"
            {...secondImage}
          />
        </div>
        <input
          className="image-comparison-block__slider"
          type="range"
          min="0"
          max="100"
          defaultValue="50"
          aria-label={sliderLabel}
          onChange={onSliderChange}
        />
        <div className="image-comparison-block__separator-line" />
        <div className="image-comparison-block__slider-button" />
      </div>
    </ContentContainer>
  );
};

ImageComparisonBlock.propTypes = {
  firstImage: PropTypes.exact(Image.propTypes),
  secondImage: PropTypes.exact(Image.propTypes),
  sliderLabel: PropTypes.string
};

export default ImageComparisonBlock;
