import React from "react";
import PropTypes from "prop-types";
import Heading from "components/heading/heading";
import RichText from "components/rich-text/rich-text";
import ContentContainer from "components/content-container/content-container";

const ContentBox = ({ title, html }) => {
  return (
    <ContentContainer className="content-box">
      <Heading className="content-box__heading" level={2}>
        {title}
      </Heading>
      <RichText html={html} themes={[RichText.themes.whiteLinks]} />
    </ContentContainer>
  );
};

ContentBox.propTypes = {
  title: PropTypes.string,
  html: PropTypes.string
};

export default ContentBox;
