import React from 'react';
import Link from 'components/link/link';
import ContentContainer from 'components/content-container';

const LinkBlock = link => {
  return (
    <ContentContainer className="link-block">
      <Link {...link} />
    </ContentContainer>
  );
};

LinkBlock.propTypes = Link.propTypes;

export default LinkBlock;
