import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as StatpedLogo } from "../../assets/icons/statped-logo.svg";

import ContentContainer from "components/content-container/content-container";
import RichText from "components/rich-text/rich-text";

const Footer = ({ firstFooterText, secondFooterText }) => {
  return (
    <footer className="footer" data-footer lang="nb">
      <ContentContainer themes={[ContentContainer.themes.wide]}>
        <div className="row">
          <div className="column top">
            <span className="footer__statped-logo">
              <StatpedLogo />
            </span>
          </div>
          <div className="column">
            <RichText html={firstFooterText} />
          </div>
          <div className="column">
            <RichText html={secondFooterText} />
          </div>
          <div className="column bottom" />
        </div>
      </ContentContainer>
    </footer>
  );
};

Footer.propTypes = {
  firstFooterText: PropTypes.string,
  secondFooterText: PropTypes.string
};

export default Footer;
