import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Link from 'components/link';
import Button from 'components/button/button';
import { ReactComponent as HomeIcon } from '../../assets/icons/icon-home.svg';
import useToggle from 'hooks/use-toggle';

const Breadcrumbs = ({ className, items, label, languageTag, title }) => {
  const [breadcrumbsOpen, toggleBreadcrums] = useToggle(false);

  return (
    <nav
      aria-label={label}
      className={cn('breadcrumbs', className)}
      lang={languageTag}
    >
      <p className="breadcrumbs__title">{title}</p>

      <span className="breadcrumbs__title-small">
        <Button
          className="breadcrumbs__title-button"
          onClick={toggleBreadcrums}
        >
          <span className="breadcrumbs__home-icon">
            <HomeIcon />
          </span>
          {title}
        </Button>
      </span>

      <ul
        className={cn('breadcrumbs__list', {
          'breadcrumbs__list--open': breadcrumbsOpen
        })}
      >
        {items.map(link => (
          <li className="breadcrumbs__item" key={Link.getKey(link)}>
            <Link
              className="breadcrumbs__link"
              themes={[Link.themes.dark]}
              {...link}
            />
            <span className="breadcrumbs__icon">-</span>
          </li>
        ))}
      </ul>
    </nav>
  );
};

Breadcrumbs.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  label: PropTypes.string.isRequired,
  languageTag: PropTypes.string,
  title: PropTypes.string.isRequired
};

Breadcrumbs.propTypesMeta = {
  className: 'exclude'
};

Breadcrumbs.defaultProps = {
  items: []
};

export default Breadcrumbs;
