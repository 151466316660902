import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ArticleElement from 'components/article-element/article-element';
import Heading from 'components/heading/heading';
import ContentContainer from 'components/content-container/content-container';
import Select from 'components/select/select';
import RichText from 'components/rich-text/rich-text';
import LoadingSpinner from 'components/loading-spinner/loading-spinner';

const ArticleList = ({
  articles,
  heading,
  sortSelect,
  isSearching,
  extraSortingOption,
  onSortingChange,
  currentSorting,
  noArticlesXhtml,
  isLoading,
  isFetchingMore
}) => {
  const [sortOptions, setSortOptions] = useState(sortSelect.options);
  useEffect(() => {
    if (isSearching) {
      setSortOptions([extraSortingOption, ...sortSelect.options]);
    } else {
      setSortOptions(sortSelect.options);
    }
  }, [isSearching]);

  return (
    <div className="article-list">
      <ContentContainer themes={[ContentContainer.themes.articleOverflow]}>
        <div className="article-list__heading-row">
          <Heading className="article-list__heading" level={2} visualLevel={1}>
            {heading}
          </Heading>
          <Select
            theme={Select.themes.visibleLabel}
            className="article-list__sort"
            onChange={onSortingChange}
            {...sortSelect}
            selectedId={currentSorting}
            options={sortOptions}
          />
        </div>
        {articles.length === 0 && (
          <RichText
            className="article-list__no-articles-text"
            html={noArticlesXhtml}
          />
        )}

        <div className="article-list__list">
          {(!isLoading || isFetchingMore) &&
            articles.map(article => (
              <ArticleElement key={article.id} {...article} />
            ))}
          {isLoading && <LoadingSpinner className="article-list__spinner" />}
        </div>
      </ContentContainer>
    </div>
  );
};

ArticleList.defaultProps = {
  articles: []
};

ArticleList.propTypesMeta = {
  isSearching: 'exclude',
  currentSorting: 'exclude',
  isLoading: 'exclude',
  isFetchingMore: 'exclude'
};

ArticleList.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.exact(ArticleElement.propTypes)),
  heading: PropTypes.string,
  sortSelect: PropTypes.exact(Select.propTypes),
  onSortingChange: PropTypes.func,
  noArticlesXhtml: PropTypes.string,
  extraSortingOption: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }),
  isSearching: PropTypes.bool,
  currentSorting: PropTypes.string,
  isLoading: PropTypes.bool,
  isFetchingMore: PropTypes.bool
};

export default ArticleList;
