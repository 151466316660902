import React from 'react';
import PropTypes from 'prop-types';
import Heading from 'components/heading/heading';
import ResourceCard from 'components/resource-card/resource-card';

const ErherResourceSuggestionsBanner = ({ heading, resources }) => {
  return (
    <aside className="erher-resource-suggestions-banner">
      <div className="erher-resource-suggestions-banner__content">
        <Heading>{heading}</Heading>
        {resources && !!resources.length && (
          <ul className="erher-resource-suggestions-banner__list">
            {resources.map(resource => (
              <li
                key={resource.url}
                className="erher-resource-suggestions-banner__list-item"
              >
                <ResourceCard {...resource} />
              </li>
            ))}
          </ul>
        )}
      </div>
    </aside>
  );
};

ErherResourceSuggestionsBanner.propTypes = {
  heading: PropTypes.string,
  resources: PropTypes.arrayOf(PropTypes.exact(ResourceCard.propTypes))
};

export default ErherResourceSuggestionsBanner;
