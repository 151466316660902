import React from 'react';
import PropTypes from 'prop-types';
import ContentContainer from 'components/content-container/content-container';
import cn from 'classnames';
import Button from 'components/button/button';
import videoHelper from 'js/video-helper';
import Video from 'components/video/video';
import Image from 'components/image/image';

const videoPlayerTypes = {
  youtube: 'youtube',
  vimeo: 'vimeo'
};

const VideoBlock = ({
  id,
  title,
  src,
  fullWidth,
  timestamps,
  videoType,
  thumbnail,
  playButtonText,
  containerTheme
}) => {
  var player = undefined;

  const setYoutubePlayer = videoPlayer => (player = videoPlayer);

  const handleTimeStampClick = time => {
    switch (videoType) {
      case videoPlayerTypes.vimeo:
        videoHelper.setCurrentTimeVimeo(player, time);
        break;
      case videoPlayerTypes.youtube:
        videoHelper.setCurrentTimeYoutube(player, time);
        break;
    }
  };

  return (
    <ContentContainer
      className={cn('video-block')}
      themes={
        fullWidth
          ? [ContentContainer.themes.articleOverflow]
          : containerTheme
          ? [containerTheme]
          : [ContentContainer.themes.article]
      }
    >
      <Video
        id={id}
        title={title}
        src={src}
        thumbnail={thumbnail}
        playButtonText={playButtonText}
        videoType={videoType}
        isLargeVideo={fullWidth}
        onPlayerLoad={setYoutubePlayer}
      />

      {timestamps && timestamps.length > 0 && (
        <div className="video-block__timestamps-wrapper">
          {timestamps.map(({ name, time }) => (
            <Button
              key={name}
              themes={[Button.themes.red]}
              onClick={() => handleTimeStampClick(time)}
            >
              {name}
            </Button>
          ))}
        </div>
      )}
    </ContentContainer>
  );
};

VideoBlock.propTypes = {
  id: PropTypes.string,
  src: PropTypes.string,
  title: PropTypes.string,
  fullWidth: PropTypes.bool,
  timestamps: PropTypes.arrayOf(
    PropTypes.exact({
      time: PropTypes.number,
      name: PropTypes.string
    })
  ),
  videoType: PropTypes.oneOf(Object.values(videoPlayerTypes)).isRequired,
  containerTheme: PropTypes.string,
  thumbnail: PropTypes.exact(Image.propTypes),
  playButtonText: PropTypes.string
};

VideoBlock.propTypesMeta = {
  containerTheme: 'exclude'
};

export default VideoBlock;
