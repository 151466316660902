import React from "react";
import PropTypes from "prop-types";
import Heading from "components/heading/heading";
import RichText from "components/rich-text/rich-text";

const FrontPageHeader = ({
  mainFeature,
  secondFeature,
  thirdFeature,
  imageSrc
}) => {
  return (
    <div className="front-page-header">
      <a
        className="front-page-header__main-feature"
        href={mainFeature.href}
        style={{ backgroundImage: "url(" + imageSrc + ")" }}
      >
        <div className="front-page-header__main-content">
          <div className="front-page-header__main-content-focus">
            <Heading
              level={2}
              visualLevel={1}
              className="front-page-header__main-heading"
            >
              {mainFeature.heading}
            </Heading>
            <RichText
              className="front-page-header__text"
              html={mainFeature.textAsHtml}
            />
          </div>
        </div>
      </a>
      <div className="front-page-header__secondary-features">
        <a className="front-page-header__link" href={secondFeature.href}>
          <div className="front-page-header__content">
            <div className="front-page-header__content-focus">
              <Heading level={2} className="front-page-header__heading">
                {secondFeature.heading}
              </Heading>
              <RichText
                className="front-page-header__text"
                html={secondFeature.textAsHtml}
              />
            </div>
          </div>
        </a>
        <a className="front-page-header__link" href={thirdFeature.href}>
          <div className="front-page-header__content">
            <div className="front-page-header__content-focus">
              <Heading level={2} className="front-page-header__heading">
                {thirdFeature.heading}
              </Heading>
              <RichText
                className="front-page-header__text"
                html={thirdFeature.textAsHtml}
              />
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

FrontPageHeader.propTypes = {
  mainFeature: PropTypes.exact({
    heading: PropTypes.string,
    textAsHtml: PropTypes.string,
    href: PropTypes.string
  }),
  secondFeature: PropTypes.exact({
    heading: PropTypes.string,
    textAsHtml: PropTypes.string,
    href: PropTypes.string
  }),
  thirdFeature: PropTypes.exact({
    heading: PropTypes.string,
    textAsHtml: PropTypes.string,
    href: PropTypes.string
  }),
  imageSrc: PropTypes.string
};

export default FrontPageHeader;
