import React from 'react';
import PropTypes from 'prop-types';
import Heading from 'components/heading/heading';
import Link from 'components/link/link';
import ErherIconFromKey from 'components/erher-icon-from-key/erher-icon-from-key';
import Image from 'components/image/image';
import { ReactComponent as AnchorLinkIcon } from '../../assets/icons/icon-link-anchor.svg';
import { ReactComponent as AnchorLinkHoverIcon } from '../../assets/icons/icon-link-anchor-hover.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/icon-arrow-right.svg';
import ErherLinkListDropdown from 'components/erher-link-list-dropdown';

const categoryKeys = [
  'barn',
  'trinn14',
  'trinn57',
  'trinn810',
  'vgs',
  'voksen'
];

const ErherResourceHeader = ({
  heading,
  resourceTypes,
  image,
  intro,
  targetGroups,
  resourceActionLink,
  downloadLinks,
  anchorLinksTitle,
  anchorLinks
}) => {
  return (
    <div className="erher-resource-header">
      <div className="erher-resource-header__content">
        <div className="erher-resource-header__heading-wrapper">
          <span className="erher-resource-header__subheading">
            {resourceTypes &&
              resourceTypes.map((resourceType, i) =>
                i === 0 ? resourceType : ' | ' + resourceType
              )}
          </span>
          <Heading
            className="erher-resource-header__heading"
            level={1}
            themes={[Heading.themes.erHer]}
          >
            {heading}
          </Heading>
        </div>
        <div className="erher-resource-header__text-content">
          {intro && <p className="erher-resource-header__intro">{intro}</p>}
          <div className="erher-resource-header__target-group-list">
            {targetGroups &&
              targetGroups.map(({ href, text, categoryKey }) => (
                <Link
                  key={categoryKey}
                  className="erher-resource-header__target-group"
                  themes={[Link.themes.whiteHover, Link.themes.noUnderline]}
                  href={href}
                >
                  <ErherIconFromKey
                    className="erher-resource-header__target-group-icon"
                    iconKey={categoryKey}
                  />
                  <ErherIconFromKey
                    className="erher-resource-header__target-group-icon erher-resource-header__target-group-icon--hover"
                    iconKey={categoryKey}
                    hover={true}
                  />
                  {text}
                </Link>
              ))}
          </div>
          {resourceActionLink && (
            <Link
              className="erher-resource-header__resource-link"
              themes={[Link.themes.lightRedButton]}
              textBeforeChildren={true}
              {...resourceActionLink}
            >
              <ArrowIcon className="erher-resource-header__resource-link-icon" />
            </Link>
          )}
          {downloadLinks && (
            <ErherLinkListDropdown
              className="erher-resource-header__resource-link-list"
              {...downloadLinks}
            />
          )}
          {anchorLinks && !!anchorLinks.length && (
            <nav aria-labelledby="anchor-links-heading">
              <Heading
                id="anchor-links-heading"
                className="erher-resource-header__anchor-links-heading"
                level={2}
              >
                {anchorLinksTitle}
              </Heading>
              <ul className="erher-resource-header__anchor-list">
                {anchorLinks.map(link => (
                  <li
                    key={link.text}
                    className="erher-resource-header__anchor-list-item"
                  >
                    <Link
                      className="erher-resource-header__anchor-link"
                      themes={[Link.themes.erherNavBar]}
                      {...link}
                    >
                      <AnchorLinkIcon
                        className="erher-resource-header__anchor-link-icon"
                        aria-label="ankerlenke"
                      />
                      <AnchorLinkHoverIcon
                        className="erher-resource-header__anchor-link-icon erher-resource-header__anchor-link-icon--hover"
                        aria-hidden="true"
                      />
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          )}
        </div>
        <div className="erher-resource-header__image-wrapper">
          <Image className="erher-resource-header__image" {...image} />
        </div>
      </div>
    </div>
  );
};

ErherResourceHeader.propTypes = {
  heading: PropTypes.string,
  resourceTypes: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.exact(Image.propTypes),
  intro: PropTypes.string,
  targetGroups: PropTypes.arrayOf(
    PropTypes.exact({
      href: PropTypes.string,
      text: PropTypes.string,
      categoryKey: PropTypes.oneOf(categoryKeys)
    })
  ),
  resourceActionLink: PropTypes.exact(Link.propTypes),
  downloadLinks: PropTypes.exact(ErherLinkListDropdown.propTypes),
  anchorLinksTitle: PropTypes.string,
  anchorLinks: PropTypes.arrayOf(PropTypes.exact(Link.propTypes))
};

export default ErherResourceHeader;
