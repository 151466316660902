import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Heading from 'components/heading/heading';
import CategoryFilter from 'components/category-filter/category-filter';
import ScrollableCardList from 'components/scrollable-card-list/scrollable-card-list';
import Layout from 'components/layout/layout';
import { ReactComponent as HeaderImage } from '../../assets/icons/erher/header-image-alle.svg';
import ErherAnimationsFromKey from 'components/erher-animations-from-key/erher-animations-from-key';

const categoryKeys = [
  'alle',
  'barn',
  'trinn14',
  'trinn57',
  'trinn810',
  'vgs',
  'voksen'
];

const ErHerFrontpage = ({
  heading,
  categoryFilter,
  contentBlocks,
  headerImageAltText,
  animationsAltTexts
}) => {
  const [selectedTargetGroup, setSelectedTargetGroup] = useState({
    categoryKey: 'alle',
    category: 'Alle'
  });

  const handleFilterChange = category => {
    setSelectedTargetGroup(oldCategory =>
      oldCategory?.categoryKey === category?.categoryKey ? undefined : category
    );
  };

  const filterContent = () => {
    if (!selectedTargetGroup || selectedTargetGroup.categoryKey === 'alle')
      return contentBlocks;
    return contentBlocks.map(block => ({
      ...block,
      cards:
        block.cards &&
        block.cards.filter(({ targetGroups }) =>
          targetGroups?.some(
            targetGroup => selectedTargetGroup.categoryKey === targetGroup.key
          )
        ),
      links:
        block.links &&
        block.links.filter(({ targetGroup }) =>
          targetGroup?.some(
            targetGroup => selectedTargetGroup.categoryKey === targetGroup
          )
        )
    }));
  };

  return (
    <Layout>
      <div className="er-her-frontpage">
        <div className="er-her-frontpage__header">
          <div className="er-her-frontpage__heading-wrapper">
            {!selectedTargetGroup ||
            selectedTargetGroup.categoryKey === 'alle' ? (
              <HeaderImage
                className="er-her-frontpage__header-image"
                role="img"
                aria-label={headerImageAltText}
              />
            ) : (
              <ErherAnimationsFromKey
                className="er-her-frontpage__header-animation"
                animationKey={selectedTargetGroup.categoryKey}
                altTexts={animationsAltTexts}
              />
            )}
            <Heading
              className="er-her-frontpage__heading"
              themes={[Heading.themes.erHer]}
              level={1}
            >
              {heading}
            </Heading>
          </div>
          {categoryFilter && (
            <CategoryFilter
              {...categoryFilter}
              activeFilter={selectedTargetGroup}
              onChange={handleFilterChange}
            />
          )}
        </div>
        <div className="er-her-frontpage__content">
          {contentBlocks &&
            filterContent().map(content => {
              if (
                (content.cards && !!content.cards.length) ||
                (content.links && !!content.links.length)
              )
                return (
                  <ScrollableCardList
                    key={content.heading}
                    {...content}
                    activeFilter={selectedTargetGroup}
                  />
                );
            })}
        </div>
      </div>
    </Layout>
  );
};

ErHerFrontpage.propTypes = {
  heading: PropTypes.string,
  categoryFilter: PropTypes.exact({
    groupLabelHidden: PropTypes.string,
    radioGroupName: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.exact({
        category: PropTypes.string,
        categoryKey: PropTypes.oneOf(categoryKeys)
      })
    )
  }),
  contentBlocks: PropTypes.arrayOf(
    PropTypes.exact(ScrollableCardList.propTypes)
  ),
  headerImageAltText: PropTypes.string,
  animationsAltTexts: PropTypes.arrayOf(
    PropTypes.exact({
      key: PropTypes.oneOf(categoryKeys),
      altText: PropTypes.string
    })
  )
};

export default ErHerFrontpage;
