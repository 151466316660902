import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import arrayOfObjectValues from 'js/prop-types-helpers/array-of-object-values';

const themes = {
  borderedThick: 'link--bordered-thick',
  borderedLight: 'link--bordered-light',
  dark: 'link--dark',
  light: 'link--light',
  blue: 'link--blue',
  white: 'link--white',
  fancy: 'link--fancy',
  undecorated: 'link--undecorated',
  visited: 'link--visited',
  flexed: 'link--flexed',
  block: 'link--block',
  blackButton: 'link--black-button',
  redButton: 'link--red-button',
  lightRedButton: 'link--light-red-button',
  borderedButton: 'link--bordered-button',
  long: 'link--long',
  fitContent: 'link--fit-content',
  underlined: 'link--underlined',
  noUnderline: 'link--no-underline',
  redUnderline: 'link--red-underline',
  heading: 'link--heading',
  title: 'link--title',
  navBar: 'link--nav-bar',
  erherNavBar: 'link--erher-nav-bar',
  grayButton: 'link--gray-button',
  whiteButton: 'link--white-button',
  bigButton: 'link--big-button',
  decorated: 'link--decorated',
  neutralDark: 'link--neutral-dark',
  whiteHover: 'link--white-hover'
};

const Link = ({
  className,
  children,
  href,
  isSelected,
  languageTag,
  noStyles,
  onClick,
  hrefShouldOpenInNewTab,
  hiddenText,
  text,
  themes,
  disabled,
  textBeforeChildren,
  ...restProps
}) => {
  const Element = href ? 'a' : 'span';

  const targetAttributes =
    href && hrefShouldOpenInNewTab
      ? {
          target: '_blank',
          rel: 'noopener noreferrer'
        }
      : {};

  return (
    <Element
      className={cn(
        { link: !noStyles, 'link--selected': isSelected },
        className,
        themes
      )}
      href={disabled ? null : href}
      lang={languageTag}
      onClick={onClick}
      {...targetAttributes}
      {...restProps}
    >
      {!textBeforeChildren && children}
      {text && (
        <span className={cn('link__text', { hidden: hiddenText })}>{text}</span>
      )}
      {textBeforeChildren && children}
    </Element>
  );
};

Link.getKey = props => props.text;

Link.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
  hrefShouldOpenInNewTab: PropTypes.bool,
  isSelected: PropTypes.bool,
  languageTag: PropTypes.string,
  noStyles: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
  hiddenText: PropTypes.bool,
  themes: arrayOfObjectValues(themes),
  disabled: PropTypes.bool,
  textBeforeChildren: PropTypes.bool
};

Link.propTypesMeta = {
  className: 'exclude',
  noStyles: 'exclude',
  themes: 'exclude',
  hrefShouldOpenInNewTab: 'exclude',
  hiddenText: 'exclude',
  disabled: 'exclude',
  textBeforeChildren: 'exclude'
};

Link.themes = themes;

export default Link;
