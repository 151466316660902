import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from 'components/button/button';
import SearchFilterList from 'components/search-filter-list/search-filter-list';

const SearchFilterMenu = ({
  openFilterMenuButtonText,
  closeFilterMenuButtonText,
  filterList
}) => {
  const [filtersOpen, setFiltersOpen] = useState(false);
  return (
    <div className="search-filter-menu">
      <Button
        className={cn('search-filter-menu__filter-toggle', {
          'search-filter-menu__filter-toggle--active': filtersOpen
        })}
        onClick={() => setFiltersOpen(!filtersOpen)}
        aria-expanded={filtersOpen}
      >
        <span className="search-filter-menu__toggle-text">
          {filtersOpen ? closeFilterMenuButtonText : openFilterMenuButtonText}
        </span>
      </Button>
      <SearchFilterList open={filtersOpen} {...filterList} />
    </div>
  );
};

SearchFilterMenu.propTypes = {
  openFilterMenuButtonText: PropTypes.string,
  closeFilterMenuButtonText: PropTypes.string,
  filterList: PropTypes.exact(SearchFilterList.propTypes)
};

export default SearchFilterMenu;
