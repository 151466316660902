import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TeachingAidsHeader from 'components/teaching-aids-header/teaching-aids-header';
import TeachingAidItem from 'components/teaching-aid-item/teaching-aid-item';
import ContentContainer from 'components/content-container/content-container';
import NavigationMenu from 'components/navigation-menu/navigation-menu';
import Layout from 'components/layout/layout';
import isRunningOnClient from '@creuna/utils/is-running-on-client';

const TeachingAids = ({
  header,
  searchResultText,
  searchResults,
  navigation,
  cartItems,
  sessionStorageID,
  layout
}) => {
  const [itemsInCart, setItemsInCart] = useState(cartItems ? cartItems : []);

  const addTeachingAid = (id, titleForOrder) => {
    setItemsInCart(itemsInCart.concat([{ id, titleForOrder }]));
  };

  const removeTeachingAid = id => {
    setItemsInCart(itemsInCart.filter(item => item.id !== id));
  };

  const removeAllTeachingAids = () => {
    setItemsInCart([]);
  };

  useEffect(() => {
    if (isRunningOnClient && sessionStorageID) {
      let storedItems = sessionStorage.getItem(sessionStorageID);
      if (storedItems) {
        let itemList = sessionStorage.getItem(sessionStorageID).split('+');
        itemList = itemList.map(item => {
          let data = item.split('>');
          return { id: data[0], titleForOrder: data[1] };
        });
        setItemsInCart(itemList);
      }
    }
  }, []);

  useEffect(() => {
    if (isRunningOnClient && sessionStorageID) {
      onbeforeunload = () => {
        sessionStorage.setItem(
          sessionStorageID,
          itemsInCart
            .map(({ id, titleForOrder }) => id + '>' + titleForOrder)
            .join('+')
        );
      };
    }
  }, [itemsInCart]);

  return (
    <Layout {...layout}>
      <div className="teaching-aids">
        <TeachingAidsHeader
          itemsInCart={itemsInCart}
          removeTeachingAid={removeTeachingAid}
          removeAllTeachingAids={removeAllTeachingAids}
          {...header}
        />
        <ContentContainer
          themes={[ContentContainer.themes.articleOverflow]}
          className="teaching-aids__results"
        >
          {searchResults && (
            <section aria-label="Søkeresultater">
              <span role="status">{searchResultText}</span>
              {searchResults.length > 0 && (
                <ul className="teaching-aids__result-list">
                  {searchResults.map(result => {
                    return (
                      <TeachingAidItem
                        key={result.id}
                        addTeachingAid={addTeachingAid}
                        removeTeachingAid={removeTeachingAid}
                        selected={
                          itemsInCart.findIndex(
                            item => item.id === result.id
                          ) >= 0
                        }
                        additionalSelected={
                          itemsInCart.findIndex(
                            item =>
                              result.additionalMaterial &&
                              item.id === result.additionalMaterial.additionalId
                          ) >= 0
                        }
                        {...result}
                      />
                    );
                  })}
                </ul>
              )}
            </section>
          )}
        </ContentContainer>
        {navigation && <NavigationMenu {...navigation} />}
      </div>
    </Layout>
  );
};

TeachingAids.propTypes = {
  header: PropTypes.exact(TeachingAidsHeader.propTypes),
  cartItems: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      titleForOrder: PropTypes.string,
      nrSelect: PropTypes.bool
    })
  ),
  searchResultText: PropTypes.string,
  searchResults: PropTypes.arrayOf(PropTypes.exact(TeachingAidItem.propTypes)),
  navigation: PropTypes.exact(NavigationMenu.propTypes),
  sessionStorageID: PropTypes.string,
  layout: PropTypes.exact(Layout.propTypes)
};

export default TeachingAids;
