import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Image from 'components/image';
import ContentContainer from 'components/content-container';

const ImageBlock = ({ image, text, fullWidth }) => {
  return (
    <div
      className={cn('image-block', { 'image-block--full-width': fullWidth })}
    >
      <ContentContainer className="image-block__container">
        <figure>
          <Image {...image} className="image-block__image" />
          {text && (
            <figcaption className="image-block__text">{text}</figcaption>
          )}
        </figure>
      </ContentContainer>
    </div>
  );
};

ImageBlock.propTypes = {
  image: PropTypes.exact(Image.propTypes).isRequired,
  text: PropTypes.string,
  fullWidth: PropTypes.bool
};

export default ImageBlock;
