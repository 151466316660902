import React from 'react';
import PropTypes from 'prop-types';
import ComponentList from 'components/component-list/component-list';
import RichText from 'components/rich-text/rich-text';
import ResourceBlock from 'components/resource-block/resource-block';
import CampaignBlock from 'components/campaign-block/campaign-block';
import ContentContainer from 'components/content-container/content-container';
import CampaignItemGroupBlock from 'components/campaign-item-group-block/campaign-item-group-block';
import ErherArticleHeader from 'components/erher-article-header/erher-article-header';

const ErherArticle = ({ body, header }) => {
  return (
    <main>
      <div className="erher-article">
        {header && <ErherArticleHeader {...header} />}
        {body && (
          <div className="erher-article__body">
            <ComponentList
              additionalComponentProps={{
                RichText: {
                  themes: [RichText.themes.erher]
                },
                RichTextBlock: {
                  richTextThemes: [RichText.themes.erher]
                },
                ResourceBlock: {
                  containerTheme: ResourceBlock.containerThemes.article
                },
                CampaignBlock: {
                  containerTheme: CampaignBlock.containerThemes.article
                },
                CampaignItemGroupBlock: {
                  containerTheme:
                    CampaignItemGroupBlock.containerThemes.articleOverflow
                },
                VideoEmbedElementBlock: {
                  containerTheme: ContentContainer.themes.articleLimitedOverflow
                },
                VideoBlock: {
                  containerTheme: ContentContainer.themes.articleLimitedOverflow
                },
                EmbededContentBlock: {
                  containerTheme: ContentContainer.themes.articleLimitedOverflow
                }
              }}
              {...body}
            />
          </div>
        )}
      </div>
    </main>
  );
};

ErherArticle.propTypes = {
  header: PropTypes.exact(ErherArticleHeader.propTypes),
  body: PropTypes.exact(ComponentList.propTypes)
};

export default ErherArticle;
