import React from "react";
import PropTypes from "prop-types";
import RichText from "components/rich-text";
import Link from "components/link";
import Image from "components/image";
import DownloadLinksDropdown from "components/download-links-dropdown";
import cn from "classnames";

import { ReactComponent as PlayWhite } from "../../assets/icons/icon-play-hex.svg";
import { ReactComponent as PlayGrey } from "../../assets/icons/icon-play-gray.svg";

const themes = {
  white: "search-result-resource-item--white",
  grey: "search-result-resource-item--grey"
};

const dropdownThemes = [
  DownloadLinksDropdown.themes.wide,
  DownloadLinksDropdown.themes.staticMenu
];

const SearchResultResourceItem = ({
  title,
  titleHref,
  textAsXhtml,
  metadataList,
  image,
  downloadMenu,
  playVideoLink,
  theme,
  isListView
}) => {
  return (
    <li
      className={cn(
        "search-result-resource-item",
        { "search-result-resource-item--list-view": isListView },
        theme
      )}
    >
      <div className="search-result-resource-item__image-wrapper">
        {image && (
          <Image className="search-result-resource-item__image" {...image} />
        )}
      </div>
      <div className="search-result-resource-item__content-wrapper">
        {titleHref ? (
          <h2 className="search-result-resource-item__heading">
            <Link href={titleHref} text={title} />
          </h2>
        ) : (
          <h2 className="search-result-resource-item__heading">{title}</h2>
        )}
        {textAsXhtml && (
          <RichText
            className="search-result-resource-item__text"
            html={textAsXhtml}
          />
        )}
        {metadataList && metadataList.length > 0 && (
          <ul className="search-result-resource-item__metadata">
            {metadataList.map(({ title, data }) => (
              <li>{title + ": " + data}</li>
            ))}
          </ul>
        )}
      </div>
      <div className="search-result-resource-item__download-wrapper">
        {downloadMenu && (
          <DownloadLinksDropdown
            themes={
              theme === themes.white
                ? [...dropdownThemes, DownloadLinksDropdown.themes.greyContent]
                : dropdownThemes
            }
            {...downloadMenu}
          />
        )}
        {playVideoLink && (
          <Link
            className="search-result-resource-item__play-video-link"
            href={playVideoLink.href}
            themes={[Link.themes.dark]}
          >
            <span className="search-result-resource-item__play-video-link-icon">
              {theme === themes.white ? <PlayGrey /> : <PlayWhite />}
            </span>
            <span className="search-result-resource-item__play-video-link-text">
              {playVideoLink.text}
            </span>
          </Link>
        )}
      </div>
    </li>
  );
};

SearchResultResourceItem.propTypes = {
  title: PropTypes.string,
  titleHref: PropTypes.string,
  textAsXhtml: PropTypes.string,
  metadataList: PropTypes.arrayOf(
    PropTypes.exact({
      title: PropTypes.string,
      data: PropTypes.string
    })
  ),
  image: PropTypes.exact(Image.propTypes),
  downloadMenu: PropTypes.exact(DownloadLinksDropdown.propTypes),
  playVideoLink: PropTypes.exact(Link.propTypes),
  theme: PropTypes.string,
  isListView: PropTypes.bool
};

SearchResultResourceItem.propTypesMeta = {
  theme: "exclude",
  isListView: "exclude"
};

SearchResultResourceItem.themes = themes;

export default SearchResultResourceItem;
