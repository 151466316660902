import React, { useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import Button from "components/button/button";
import FilterListDropdown from "components/filter-list-dropdown/filter-list-dropdown";
import Link from "components/link/link";

const SearchFilterList = ({
  filters,
  supportLinks,
  resetAllLink,
  filterButtonText,
  open
}) => {
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  return (
    <div
      className="search-filter-list"
      aria-hidden={!open}
      inert={open ? null : ""}
    >
      <div className="search-filter-list__filters-wrapper">
        <div
          className={cn("search-filter-list__filters", {
            "search-filter-list__filters--active": open,
            "search-filter-list__filters--menu-open": filterMenuOpen
          })}
        >
          <div className="search-filter-list__filter-list">
            {filters &&
              filters.map((filter, i) => (
                <FilterListDropdown
                  key={i}
                  onToggleOpen={setFilterMenuOpen}
                  className="search-filter-list__filter-select"
                  {...filter}
                />
              ))}
          </div>
          <div className="search-filter-list__footer">
            <div>
              {supportLinks &&
                !!supportLinks.length &&
                (supportLinks.length < 2 ? (
                  <Link
                    className="search-filter-list__support-link"
                    {...supportLinks[0]}
                  />
                ) : (
                  <ul className="search-filter-list__support-links">
                    {supportLinks.map(link => (
                      <li
                        className="search-filter-list__support-list-item"
                        key={link.text}
                      >
                        <Link {...link} />
                      </li>
                    ))}
                  </ul>
                ))}
            </div>
            <div className="search-filter-list__filter-actions">
              {resetAllLink && (
                <Link
                  className="search-filter-list__reset-button"
                  {...resetAllLink}
                />
              )}
              {filterButtonText && (
                <Button themes={[Button.themes.red]} type="submit" value="Send">
                  {filterButtonText}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SearchFilterList.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.exact(FilterListDropdown.propTypes)),
  supportLinks: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  filterButtonText: PropTypes.string,
  resetAllLink: PropTypes.exact(Link.propTypes),
  open: PropTypes.bool
};

SearchFilterList.propTypesMeta = {
  open: "exclude"
};

export default SearchFilterList;
