import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Heading from 'components/heading/heading';
import FeaturedArticle from 'components/featured-article/featured-article';
import ArticleList from 'components/article-list/article-list';
import ContentContainer from 'components/content-container/content-container';
import SearchField from 'components/search-field/search-field';
import RichText from 'components/rich-text/rich-text';

import toQueryString from '@creuna/utils/to-query-string';
import apiHelper from 'js/api-helper';
import Button from 'components/button/button';
import Layout from 'components/layout/layout';

const MagazineFrontPage = ({
  heading,
  introAsHtml,
  featuredArticle,
  search,
  articleList,
  morePages,
  searchEndpoint,
  paginationButtonText,
  layout
}) => {
  const [searchText, setSearchText] = useState('');
  const [currentSorting, setCurrentSorting] = useState('newfirst');
  const [searchResultResponse, setSearchResultResponse] = useState('');
  const [currentArticles, setCurrentArticles] = useState(articleList.articles);
  const [nextPaginationStep, setNextPaginationStep] = useState(2);
  const [moreArticles, setMoreArticles] = useState(morePages);
  const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingMore, setIsFeatchingMore] = useState(false);

  const firstHiddenArticle = useRef();

  const onSearchTextChange = event => {
    let searchString = event.target.value;
    setSearchText(searchString);
    if (isSearching && searchString.length < 1) {
      setIsLoading(true);
      setIsSearching(false);
      if (currentSorting === 'relevant') setCurrentSorting('newfirst');
      const data = {
        term: '',
        page: 1,
        sortBy: currentSorting === 'relevant' ? 'newfirst' : currentSorting
      };
      apiHelper.get(searchEndpoint + toQueryString(data)).then(response => {
        let { hits, morePages, nextPage } = response;
        setSearchResultResponse('');
        setCurrentArticles(hits);
        setMoreArticles(morePages);
        setNextPaginationStep(nextPage);
        setIsLoading(false);
      });
    }
  };

  const handleSearch = event => {
    if (searchText) {
      setIsSearching(true);
      !isSearching && setCurrentSorting('relevant');
      setIsLoading(true);
      const data = {
        term: searchText,
        page: 1,
        sortBy: !isSearching ? 'relevant' : currentSorting
      };
      apiHelper.get(searchEndpoint + toQueryString(data)).then(response => {
        let { searchResultText, hits, morePages, nextPage } = response;
        setSearchResultResponse(searchResultText);
        setCurrentArticles(hits);
        setMoreArticles(morePages);
        setNextPaginationStep(nextPage);
        setIsLoading(false);
      });
    }

    event.preventDefault();
  };

  const handleMoreArticles = () => {
    setIsLoading(true);
    setIsFeatchingMore(true);
    const data = {
      term: searchText,
      page: nextPaginationStep,
      sortBy: currentSorting
    };
    apiHelper.get(searchEndpoint + toQueryString(data)).then(response => {
      let { hits, morePages, nextPage } = response;
      hits[0].firstArticleRef = firstHiddenArticle;
      setCurrentArticles([...currentArticles, ...hits]);
      setMoreArticles(morePages);
      setNextPaginationStep(nextPage);
      setIsLoading(false);
      setIsFeatchingMore(false);
      if (firstHiddenArticle.current) {
        firstHiddenArticle.current.focus();
      }
    });
  };

  const handleSortChange = value => {
    setIsLoading(true);
    setCurrentSorting(value);
    const data = {
      term: searchText,
      page: 1,
      sortBy: value
    };
    apiHelper.get(searchEndpoint + toQueryString(data)).then(response => {
      let { hits, morePages, nextPage } = response;
      setCurrentArticles(hits);
      setMoreArticles(morePages);
      setNextPaginationStep(nextPage);
      setIsLoading(false);
    });
  };

  return (
    <Layout {...layout}>
      <div className="magazine-front-page">
        <ContentContainer
          className="magazine-front-page__header"
          themes={[ContentContainer.themes.articleOverflow]}
        >
          <Heading className="magazine-front-page__heading" level={1}>
            {heading}
          </Heading>
          <RichText className="magazine-front-page__intro" html={introAsHtml} />
          <FeaturedArticle {...featuredArticle} />
          <form
            role="search"
            aria-label="På denne siden"
            onSubmit={handleSearch}
          >
            <SearchField
              onSearch={handleSearch}
              onSearchTextChange={onSearchTextChange}
              searchText={searchText}
              theme={SearchField.themes.magazine}
              {...search}
            />
          </form>
          <p role="alert" className="magazine-front-page__search-response">
            {!isLoading && searchResultResponse}
          </p>
        </ContentContainer>

        <div className="magazine-front-page__content">
          <ArticleList
            {...articleList}
            isSearching={isSearching}
            onSortingChange={handleSortChange}
            articles={currentArticles}
            currentSorting={currentSorting}
            isLoading={isLoading}
            isFetchingMore={isFetchingMore}
          />
          {moreArticles && paginationButtonText && (
            <div className="magazine-front-page__button-wrapper">
              <Button
                className="magazine-front-page__pagination-button"
                themes={[Button.themes.bordered]}
                text={paginationButtonText}
                onClick={handleMoreArticles}
              />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

MagazineFrontPage.defaultProps = {
  articleList: [],
  morePages: true
};

MagazineFrontPage.propTypes = {
  heading: PropTypes.string,
  introAsHtml: PropTypes.string,
  featuredArticle: PropTypes.exact(FeaturedArticle.propTypes),
  search: PropTypes.exact(SearchField.propTypes),
  articleList: PropTypes.exact(ArticleList.propTypes),
  morePages: PropTypes.bool,
  searchEndpoint: PropTypes.string,
  paginationButtonText: PropTypes.string,
  layout: PropTypes.exact(Layout.propTypes)
};

export default MagazineFrontPage;
