import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ResourceCard from 'components/resource-card/resource-card';
import Heading from 'components/heading/heading';
import Button from 'components/button/button';
import Link from 'components/link/link';
import { ReactComponent as ArrowIcon } from '../../assets/icons/icon-chevron-left.svg';

const ScrollableCardList = ({
  heading,
  leftButtonLabel,
  rightButtonLabel,
  cards,
  links,
  activeFilter
}) => {
  //TODO: Maybe change this logic so that the first partially visible element is scrolled all the way to the left.
  const scrollRef = useRef();
  const [leftButtonActive, setLeftButtonActive] = useState(false);
  const [rightButtonActive, setRightButtonActive] = useState(true);

  const handleScroll = () => {
    let scrollRegion = scrollRef.current;
    if (
      scrollRegion.scrollWidth -
        scrollRegion.scrollLeft -
        scrollRegion.offsetWidth <=
      60
    )
      setRightButtonActive(false);
    else setRightButtonActive(true);

    if (scrollRegion.scrollLeft >= 60) setLeftButtonActive(true);
    else setLeftButtonActive(false);
  };

  const handleScrollLeftButtonClick = () => {
    let scrollRegion = scrollRef.current;
    scrollRegion.scrollLeft =
      scrollRegion.scrollLeft - scrollRegion.offsetWidth;
  };

  const handleScrollRightButtonClick = () => {
    let scrollRegion = scrollRef.current;
    scrollRegion.scrollLeft =
      scrollRegion.scrollLeft + scrollRegion.offsetWidth;
  };

  const addActiveFiltersAsSearchParams = href => {
    if (!activeFilter) return href;
    let url = href + '&maalgruppe=' + activeFilter.categoryKey;
    return url;
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener('resize', handleScroll);
    return () => {
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  return (
    <div className="scrollable-card-list">
      <div className="scrollable-card-list__header">
        <Heading
          className="scrollable-card-list__heading"
          themes={[Heading.themes.erHer]}
          level={2}
        >
          {heading}
          <span className="scrollable-card-list__number">
            {' (' + (cards ? cards.length : links && links.length) + ') '}
          </span>
        </Heading>
        <div className="scrollable-card-list__scroll-buttons">
          <Button
            className="scrollable-card-list__arrow-button"
            text={leftButtonLabel}
            textIsHidden={true}
            onClick={handleScrollLeftButtonClick}
            disabled={!leftButtonActive}
          >
            <ArrowIcon />
          </Button>
          <Button
            className="scrollable-card-list__arrow-button"
            text={rightButtonLabel}
            textIsHidden={true}
            onClick={handleScrollRightButtonClick}
            disabled={!rightButtonActive}
          >
            <ArrowIcon className="scrollable-card-list__right-arrow-icon" />
          </Button>
        </div>
      </div>
      {cards && !!cards.length && (
        <ul
          className="scrollable-card-list__list"
          ref={scrollRef}
          onScroll={handleScroll}
        >
          {cards.map(card => (
            <li key={card.id} className="scrollable-card-list__list-item">
              <ResourceCard {...card} />
            </li>
          ))}
        </ul>
      )}
      {links && !!links.length && (
        <ul
          className="scrollable-card-list__list"
          ref={scrollRef}
          onScroll={handleScroll}
        >
          {links.map(({ text, href }) => (
            <li key={href} className="scrollable-card-list__link-list-item">
              <a
                className="scrollable-card-list__link-item"
                href={addActiveFiltersAsSearchParams(href)}
              >
                <span>{text}</span>
                <ArrowIcon className="scrollable-card-list__link-arrow-icon" />
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

ScrollableCardList.propTypes = {
  heading: PropTypes.string,
  leftButtonLabel: PropTypes.string,
  rightButtonLabel: PropTypes.string,
  cards: PropTypes.arrayOf(PropTypes.exact(ResourceCard.propTypes)),
  links: PropTypes.arrayOf(
    PropTypes.exact({
      href: PropTypes.string,
      text: PropTypes.string,
      targetGroup: PropTypes.arrayOf(PropTypes.string)
    })
  ),
  activeFilter: PropTypes.exact({
    category: PropTypes.string,
    categoryKey: PropTypes.string
  })
};

ScrollableCardList.propTypesMeta = {
  activeFilter: 'exclude'
};

export default ScrollableCardList;
