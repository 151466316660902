import React from 'react';
import PropTypes from 'prop-types';
import Heading from 'components/heading/heading';
import ResourceItem from 'components/resource-item/resource-item';
import Link from 'components/link/link';
import ContentContainer from 'components/content-container/content-container';
import cn from 'classnames';

const containerThemes = {
  content: ContentContainer.themes.content,
  article: ContentContainer.themes.article,
  footer: ContentContainer.themes.footer
};

const ResourceBlock = ({ heading, link, resources, containerTheme }) => {
  return (
    <div
      className={cn('resource-block', {
        [`resource-block--${containerThemes[containerTheme]}`]: containerThemes[
          containerTheme
        ]
      })}
    >
      <ContentContainer themes={[containerThemes[containerTheme]]}>
        <div className="resource-block__header">
          {link && <div className="resource-block__filler"></div>}
          <Heading className="resource-block__heading" level={2}>
            {heading}
          </Heading>
          {link && (
            <div className="resource-block__link-wrapper">
              <Link themes={[Link.themes.dark]} {...link} />
            </div>
          )}
        </div>
        {resources && (
          <ul className="resource-block__list">
            {resources.map((resource, index) => (
              <li key={index} className="resource-block__list-item">
                <ResourceItem {...resource}></ResourceItem>
              </li>
            ))}
          </ul>
        )}
      </ContentContainer>
    </div>
  );
};

ResourceBlock.propTypes = {
  heading: PropTypes.string,
  link: PropTypes.exact(Link.propTypes),
  resources: PropTypes.arrayOf(PropTypes.exact(ResourceItem.propTypes)),
  containerTheme: PropTypes.oneOf(Object.keys(containerThemes))
};

ResourceBlock.propTypesMeta = {
  containerTheme: 'exclude'
};

ResourceBlock.defaultProps = {
  containerThemes: ContentContainer.themes.content
};

ResourceBlock.containerThemes = containerThemes;

export default ResourceBlock;
