import isRunningOnClient from '@creuna/utils/is-running-on-client';

const VimeoPlayer = isRunningOnClient
  ? require('@vimeo/player').default
  : undefined;

//To use the youtube iframe api the video src must have ?enablejsapi=1 in the url
const YoutubePlayer = isRunningOnClient ? require('youtube-player') : undefined;

function setUpVimeo(id) {
  return new VimeoPlayer(id);
}

function setUpYoutube(id) {
  let play = YoutubePlayer(id, {
    playerVars: { origin: window.location }
  });
  return play;
}

function setCurrentTimeVimeo(player, time) {
  player.setCurrentTime(time);
}

function setCurrentTimeYoutube(player, time) {
  player.seekTo(time);
}

function playVimeo(player) {
  player.play();
}

function playYoutube(player) {
  player.playVideo();
}

export default {
  setUpVimeo,
  setUpYoutube,
  setCurrentTimeVimeo,
  setCurrentTimeYoutube,
  playVimeo,
  playYoutube
};
