import React from 'react';
import PropTypes from 'prop-types';
import LinkList from 'components/link-list/link-list';
import Link from 'components/link/link';
import ContentContainer from 'components/content-container/content-container';

const TableOfContentsFrontPage = ({ linkList, showFullDocumentLink }) => {
  return (
    <ContentContainer
      className="table-of-contents-front-page"
      themes={[ContentContainer.themes.article]}
    >
      <nav aria-label="Innholdsfortegnelse">
        <LinkList themes={[LinkList.themes.disc]} {...linkList} />
        {showFullDocumentLink && (
          <p className="table-of-contents-front-page__show-full">
            <Link {...showFullDocumentLink} />
          </p>
        )}
      </nav>
    </ContentContainer>
  );
};

TableOfContentsFrontPage.propTypes = {
  linkList: PropTypes.exact(LinkList.propTypes),
  showFullDocumentLink: PropTypes.exact(Link.propTypes)
};

export default TableOfContentsFrontPage;
