/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import ContentContainer from 'components/content-container/content-container';
import Link from 'components/link/link';
import RichText from 'components/rich-text/rich-text';
import Heading from 'components/heading/heading';
import CourseInformationParagraph from 'components/course-information-paragraph/course-information-paragraph';

const CourseInformationBlock = ({
  registratonLink,
  privacyPolicyLink,
  informationList,
  registrationInfo,
  descriptionAsHtml,
  contactInfo,
  otherInfo,
  downloadsTitle,
  downloads,
  usefulLinks
}) => {
  return (
    <ContentContainer
      className="course-information-block"
      themes={[ContentContainer.themes.article]}
    >
      {registratonLink && (
        <>
          <Link
            className="course-information-block__registration-link"
            themes={[Link.themes.redButton, Link.themes.bigButton]}
            {...registratonLink}
          />
          <br />
        </>
      )}
      {privacyPolicyLink && (
        <Link
          className="course-information-block__privacy-link"
          {...privacyPolicyLink}
        />
      )}
      {informationList &&
        informationList.map(({ title, text, textAsHtml }, index) => (
          <div key={index}>
            <strong>{title + ': '}</strong>
            {textAsHtml ? (
              <RichText
                className="course-information-block__info-rich-text"
                html={textAsHtml}
              />
            ) : (
              text
            )}
          </div>
        ))}
      {registrationInfo && (
        <p className="course-information-block__registration-info">
          {registrationInfo}
        </p>
      )}
      {descriptionAsHtml && <RichText html={descriptionAsHtml} />}
      {contactInfo && <CourseInformationParagraph {...contactInfo} />}
      {otherInfo && <CourseInformationParagraph {...otherInfo} />}
      {downloads && downloads.length > 0 && (
        <>
          <Heading
            className="course-information-block__paragraph-title"
            level={2}
          >
            {downloadsTitle}
          </Heading>
          <ul>
            {downloads.map((download, index) => (
              <li key={index}>
                <Link {...download} />
              </li>
            ))}
          </ul>
        </>
      )}
      {usefulLinks && <CourseInformationParagraph {...usefulLinks} />}
    </ContentContainer>
  );
};

CourseInformationBlock.propTypes = {
  registratonLink: PropTypes.exact(Link.propTypes),
  privacyPolicyLink: PropTypes.exact(Link.propTypes),
  informationList: PropTypes.arrayOf(
    PropTypes.exact({
      title: PropTypes.string,
      text: PropTypes.string,
      textAsHtml: PropTypes.string
    })
  ),
  registrationInfo: PropTypes.string,
  descriptionAsHtml: PropTypes.string,
  contactInfo: PropTypes.exact(CourseInformationParagraph.propTypes),
  otherInfo: PropTypes.exact(CourseInformationParagraph.propTypes),
  downloadsTitle: PropTypes.string,
  downloads: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  usefulLinks: PropTypes.exact(CourseInformationParagraph.propTypes)
};

export default CourseInformationBlock;
