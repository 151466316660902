import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const keyToIcon = {
  barn: 'bh',
  trinn14: '1-4',
  trinn57: '5-7',
  trinn810: '8-10',
  vgs: 'vgs',
  voksen: 'voksen'
};

const importIcon = (key, hover, active) => {
  if (keyToIcon[key]) {
    let SearchIcon = require(`../../assets/icons/erher/icon-${keyToIcon[key]}${
      hover ? '_hover' : ''
    }${active ? '_active' : ''}.svg`);
    return SearchIcon.ReactComponent;
  }
};

const ErherIconFromKey = ({
  className,
  iconKey,
  hover,
  active,
  ...restProps
}) => {
  let Icon = importIcon(iconKey, hover, active);

  return (
    <>
      {Icon && (
        <Icon className={cn('erher-icon-from-key', className)} {...restProps} />
      )}
    </>
  );
};

ErherIconFromKey.propTypes = {
  className: PropTypes.string,
  iconKey: PropTypes.string,
  hover: PropTypes.bool,
  active: PropTypes.bool
};

ErherIconFromKey.propTypesMeta = 'exclude';

export default ErherIconFromKey;
