import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import SearchPageHeader from 'components/search-page-header';
import SearchResultItem from 'components/search-result-item/search-result-item';
import ContentContainer from 'components/content-container';
import NavigationMenu from 'components/navigation-menu';
import SearchResultSettings from 'components/search-result-settings/search-result-settings';
import SearchResultResourceItem from 'components/search-result-resource-item/search-result-resource-item';
import cn from 'classnames';

const SearchResult = ({
  layout,
  searchHeader,
  settings,
  results,
  productResults,
  navigation,
  isWide
}) => {
  const [isListView, setIsListView] = useState(true);

  const handleViewChange = isList => {
    setIsListView(isList);
  };

  return (
    <div
      className={cn('search-result', {
        'search-result--list-view': isListView
      })}
    >
      <Layout {...layout}>
        <SearchPageHeader
          theme={
            isWide
              ? SearchPageHeader.themes.wide
              : SearchPageHeader.themes.narrow
          }
          {...searchHeader}
        />
        <ContentContainer
          themes={[
            isWide
              ? ContentContainer.themes.wide
              : ContentContainer.themes.searchWidth
          ]}
        >
          <section aria-label="Søkeresultater">
            <SearchResultSettings
              isListView={isListView}
              onViewChange={handleViewChange}
              {...settings}
            />
            {(!!productResults?.length || !!results?.length) && (
              <ul className="search-result__result-wrapper">
                {productResults?.map((result, index) => (
                  <SearchResultResourceItem
                    key={result.title}
                    className="search-result__result"
                    theme={
                      !isListView || index % 2
                        ? SearchResultResourceItem.themes.white
                        : SearchResultResourceItem.themes.grey
                    }
                    isListView={isListView}
                    {...result}
                  />
                ))}
                {results?.map(result => (
                  <SearchResultItem
                    key={result.title}
                    className="search-result__result"
                    {...result}
                  />
                ))}
              </ul>
            )}
          </section>
        </ContentContainer>
        <NavigationMenu {...navigation} />
      </Layout>
    </div>
  );
};

SearchResult.propTypes = {
  layout: PropTypes.exact(Layout.propTypes),
  searchHeader: PropTypes.exact(SearchPageHeader.propTypes),
  settings: PropTypes.exact(SearchResultSettings.propTypes),
  results: PropTypes.arrayOf(PropTypes.exact(SearchResultItem.propTypes)),
  productResults: PropTypes.arrayOf(
    PropTypes.exact(SearchResultResourceItem.propTypes)
  ),
  navigation: PropTypes.exact(NavigationMenu.propTypes),
  isWide: PropTypes.bool
};

export default SearchResult;
