import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Link from 'components/link';

const themes = {
  horizontal: 'link-list--horizontal',
  noStyle: 'link-list--no-style',
  disc: 'link-list--disc'
};

const LinkList = ({ linkThemes, list, className, themes, listStyleReset }) => {
  return (
    <>
      {list && !!list.length && (
        <ul
          className={cn('link-list', className, themes, {
            ['link-list--reset']: listStyleReset
          })}
        >
          {list.map(({ link, list }, i) => (
            <li className="link-list__item" key={i}>
              {link && <Link themes={linkThemes} {...link} />}
              {list && (
                <LinkList linkThemes={linkThemes} themes={themes} {...list} />
              )}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

LinkList.propTypes = {
  linkThemes: PropTypes.array,
  themes: PropTypes.oneOf(Object.keys(themes)),
  className: PropTypes.string,
  listStyleReset: PropTypes.bool
};

// PropTypes is mutated because LinkList refers to LinkList
LinkList.propTypes.list = PropTypes.arrayOf(
  PropTypes.exact({
    link: PropTypes.exact(Link.propTypes),
    list: PropTypes.exact(LinkList.propTypes)
  })
);

LinkList.propTypesMeta = {
  linkThemes: 'exclude',
  themes: 'exclude',
  listStyleReset: 'exclude',
  className: 'exclude'
};

LinkList.defaultProps = {
  level: 1,
  list: [],
  listStyleReset: true
};

LinkList.themes = themes;

export default LinkList;
