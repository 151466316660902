import React from 'react';
import PropTypes from 'prop-types';
import Heading from 'components/heading/heading';
import RichText from 'components/rich-text/rich-text';
import ContentContainer from 'components/content-container/content-container';

const ErherArticleHeader = ({ heading, introAsHtml }) => {
  return (
    <ContentContainer className="erher-article-header">
      <Heading level={1} themes={[Heading.themes.erHer]}>
        {heading}
      </Heading>
      <RichText
        className="erher-article-header__intro"
        themes={[RichText.themes.erher]}
        html={introAsHtml}
      />
    </ContentContainer>
  );
};

ErherArticleHeader.propTypes = {
  heading: PropTypes.string,
  introAsHtml: PropTypes.string
};

export default ErherArticleHeader;
