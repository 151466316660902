import React from 'react';
import PropTypes from 'prop-types';
import Heading from 'components/heading/heading';
import ComponentList from 'components/component-list/component-list';
import RichText from 'components/rich-text/rich-text';
import RichTextBlock from 'components/rich-text-block/rich-text-block';

const ProcessHeader = ({ heading, intro }) => {
  return (
    <div className="process-header">
      <Heading className="process-header__heading" level={1}>
        {heading}
      </Heading>
      {intro && (
        <ComponentList
          additionalComponentProps={{
            RichText: { themes: [RichText.themes.wide] },
            VideoBlock: { fullWidth: true },
            RichTextBlock: {
              containerTheme: RichTextBlock.containerThemes.content
            }
          }}
          {...intro}
        />
      )}
    </div>
  );
};

ProcessHeader.propTypes = {
  heading: PropTypes.string,
  intro: PropTypes.exact(ComponentList.propTypes)
};

export default ProcessHeader;
