import React from 'react';
import PropTypes from 'prop-types';
import Heading from 'components/heading/heading';
import ContentContainer from 'components/content-container/content-container';
import ComponentList from 'components/component-list/component-list';
import RichText from 'components/rich-text/rich-text';

const ArticleHeader = ({ heading, introContent }) => {
  return (
    <div className="article-header">
      <ContentContainer themes={[ContentContainer.themes.article]}>
        <Heading className="article-header__heading" level={1}>
          {heading}
        </Heading>
      </ContentContainer>
      <ComponentList
        className="article-header__intro"
        additionalComponentProps={{
          RichTextBlock: { richTextThemes: [RichText.themes.ingress] }
        }}
        {...introContent}
      />
    </div>
  );
};

ArticleHeader.propTypes = {
  heading: PropTypes.string,
  introContent: PropTypes.exact(ComponentList.propTypes)
};

export default ArticleHeader;
