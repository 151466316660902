import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import LinkListDropdown from "components/link-list-dropdown/link-list-dropdown";

import { ReactComponent as ListIconActive } from "../../assets/icons/icon-list-active.svg";
import { ReactComponent as ListIconInctive } from "../../assets/icons/icon-list-inactive.svg";
import { ReactComponent as GridIconActive } from "../../assets/icons/icon-grid-active.svg";
import { ReactComponent as GridIconInactive } from "../../assets/icons/icon-grid-inactive.svg";

const SearchResultSettings = ({
  status,
  sortingMenu,
  radioGroupHiddenDescription,
  listOptionHiddenLabel,
  gridOptionHiddenLabel,
  isListView,
  onViewChange
}) => {
  const handleViewChange = e => onViewChange(e.target.value === "list");

  return (
    <div className="search-result-settings">
      <span>{status}</span>
      <div className="search-result-settings__actions">
        {sortingMenu && <LinkListDropdown {...sortingMenu} />}
        {listOptionHiddenLabel && gridOptionHiddenLabel && (
          <fieldset className="search-result-settings__view-toggle">
            <legend className="search-result-settings__legend">
              {radioGroupHiddenDescription}
            </legend>
            <input
              id={"id-" + listOptionHiddenLabel}
              className="search-result-settings__radio"
              type="radio"
              onChange={handleViewChange}
              value="list"
              checked={isListView}
            />
            <label
              className={cn("search-result-settings__label", {
                "search-result-settings__label--active": isListView
              })}
              htmlFor={"id-" + listOptionHiddenLabel}
            >
              {isListView ? <ListIconActive /> : <ListIconInctive />}
              <span className="search-result-settings__label-text">
                {listOptionHiddenLabel}
              </span>
            </label>
            <input
              id={"id-" + gridOptionHiddenLabel}
              className="search-result-settings__radio"
              type="radio"
              onChange={handleViewChange}
              value="grid"
              checked={!isListView}
            />
            <label
              className={cn("search-result-settings__label", {
                "search-result-settings__label--active": !isListView
              })}
              htmlFor={"id-" + gridOptionHiddenLabel}
            >
              {isListView ? <GridIconInactive /> : <GridIconActive />}
              <span className="search-result-settings__label-text">
                {gridOptionHiddenLabel}
              </span>
            </label>
          </fieldset>
        )}
      </div>
    </div>
  );
};

SearchResultSettings.propTypes = {
  status: PropTypes.string,
  sortingMenu: PropTypes.exact(LinkListDropdown.propTypes),
  radioGroupHiddenDescription: PropTypes.string,
  listOptionHiddenLabel: PropTypes.string,
  gridOptionHiddenLabel: PropTypes.string,
  isListView: PropTypes.bool,
  onViewChange: PropTypes.func
};

SearchResultSettings.propTypesMeta = {
  isListView: "exclude"
};

export default SearchResultSettings;
